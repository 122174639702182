<template>
  <div>
    <br />
    <!-- Informacion General -->
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          color="secondary"
          label="Identificación tributaria según origen del proveedor"
          :value="formulario_uno.tipo_identificacion.nombre"
          readonly
          dense
          class="no-border"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          color="secondary"
          label="Nombre del proveedor"
          :value="formulario_uno.nombre_proveedor"
          readonly
          dense
          class="no-border"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          color="secondary"
          :label="formulario_uno.tipo_identificacion.tipo_documento"
          :value="formulario_uno.numero_documento"
          readonly
          :hint="
            formulario_uno.tipo_identificacion.tipo_documento === 'DUI'
              ? 'El número de DUI es válido'
              : ''
          "
          persistent-hint
          dense
          class="no-border green-hint"
        />
      </v-col>
      <v-col>
        <v-text-field
          color="secondary"
          label="Tipo de contribuyente"
          :value="formulario_uno.tipo_contribuyente"
          readonly
          dense
          class="no-border"
        />
      </v-col>
    </v-row>
    <!-- Formulario -->
    <v-row justify="space-between">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Nombre comercial *"
              outlined
              color="secondary"
              v-model="formulario_dos.nombre_comercial"
              :error-messages="nombreError"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Sitio web "
              outlined
              counter="255"
              maxlength="255"
              color="secondary"
              v-model="formulario_dos.sitio_web"
              :error-messages="sitioWebError"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="10">
            <v-autocomplete
              label="Género *"
              outlined
              color="secondary"
              :items="generos"
              item-text="nombre"
              item-value="id"
              :error-messages="generoError"
              v-model="formulario_dos.genero"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <modalGenero />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="10">
            <v-autocomplete
              label="Clasificación empresarial *"
              outlined
              color="secondary"
              :items="clasificacion_empresarial"
              item-text="clasificacion"
              item-value="id"
              :error-messages="clasificacionEmpresarialError"
              v-model="formulario_dos.id_clasificacion_empresarial"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <modalClasificacion />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="10">
            <v-autocomplete
              :readonly="pais_disable"
              label="País de origen *"
              outlined
              color="secondary"
              :items="paises"
              item-text="nombre"
              item-value="id"
              :error-messages="paisError"
              v-model="formulario_dos.id_pais"
              @change="cambiar_codigo_area($event), obtener_departamentos($event)"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" v-if="formulario_dos.id_pais != 68 && formulario_uno.id_tipo_identificacion == 4" />
      <v-col cols="12" md="6" v-if="es_salvadoreno || formulario_dos.id_pais == 68">
        <v-row>
          <v-col cols="12" sm="10">
            <v-autocomplete
              v-if="es_salvadoreno || formulario_uno?.id_tipo_identificacion == 6"
              :disabled="departamentos_disable"
              label="Departamento *"
              outlined
              color="secondary"
              :items="departamentos"
              item-text="nombre"
              item-value="id"
              :error-messages="departamentoError"
              v-model="formulario_dos.id_departamento"
              @change="obtener_municipios($event)"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" v-if="es_salvadoreno || formulario_uno?.id_tipo_identificacion == 6">
        <v-row>
          <v-col cols="12" sm="10">
            <v-autocomplete
              v-if="es_salvadoreno || formulario_uno?.id_tipo_identificacion == 6"
              :disabled="municipios_disable"
              label="Municipio *"
              outlined
              color="secondary"
              :items="municipios"
              item-text="nombre"
              item-value="id"
              v-model="formulario_dos.id_municipio"
              :error-messages="municipioError"
              
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" v-if="es_salvadoreno || formulario_uno?.id_tipo_identificacion == 6">
        <v-row>
          <v-col cols="12" sm="10">
            <v-autocomplete
              v-if="es_salvadoreno || formulario_uno?.id_tipo_identificacion == 6"
              :disabled="!formulario_dos.id_municipio || distritos_disable"
              label="Distrito *"
              outlined
              color="secondary"
              :items="distritos"
              item-text="nombre"
              item-value="id"
              v-model="formulario_dos.id_distrito"
              :error-messages="distritoError"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="10">
            <v-textarea
              label="Dirección *"
              outlined
              rows="4"
              color="secondary"
              v-model="formulario_dos.direccion"
              :error-messages="direccionError"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="4" sm="3">
            <v-text-field
              label="Área *"
              outlined
              color="secondary"
              v-model="formulario_dos.area"
              readonly
              :error-messages="areaError"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="7">
            <v-text-field
              label="Teléfono *"
              outlined
              :placeholder="mascara_telefono"
              color="secondary"
              type="tel"
              v-mask="mascara_telefono"
              v-model="formulario_dos.telefono"
              :error-messages="telefonoError"
              :maxlength="lng_tel"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <br />
  </div>
</template>

<script>
import modalGenero from "./ModalGeneroComponent.vue";
import modalClasificacion from "./ModalClasificacionComponent.vue";
import {
  required,
  requiredIf,
  helpers,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

// Validación personalizada para filtrar caracteres especiales en dirección
const address = helpers.regex("address", /^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ,.#_\-' ]*$/);
// Validación personalizada para sitios web
const url = helpers.regex(
  "url",
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);

function serial(value) {
  if (value) {
    let regSerial
    if (this.lng_tel == 9) {
      regSerial = /^([0-9]{4})-([0-9]{4})$/
      return regSerial.test(value)
    } else if(this.lng_tel == 12) {
      regSerial = /^[0-9-]*$/
      return regSerial.test(value)
    }
  } else {
    return true
  }
}

function containNumber (value) {
  if (value && this.lng_tel == 9) {
    return !!(value.charAt(0) == '2' ||
      value.charAt(0) == '6' ||
      value.charAt(0) == '7');
  } else {
    return true
  }
}

export default {
  nombre: "StepTwoComponent",
  components: {
    modalGenero,
    modalClasificacion,
  },
  validations: {
    formulario_dos: {
      nombre_comercial: { required, maxLength: maxLength(100) },
      sitio_web: { url },
      genero: { required },
      id_clasificacion_empresarial: { required },
      id_pais: { required },
      id_departamento: {
        requiredIf: requiredIf(function () {
          return this.es_salvadoreno;
        }),
      },
      id_municipio: {
        requiredIf: requiredIf(function () {
          return this.es_salvadoreno;
        }),
      },
      id_distrito: {
        requiredIf: requiredIf(function () {
          return this.es_salvadoreno;
        }),
      },
      direccion: { required, address, maxLength: maxLength(100) },
      area: { required },
      telefono: { required, minLength: minLength(8), serial, containNumber },
    },
  },
  computed: {
    ...mapState("registroProveedor", [
      "formulario_uno",
      "formulario_dos",
      "mascara_telefono",
      "lng_tel",
      "generos",
      "clasificacion_empresarial",
      "pais_disable",
      "paises",
      "departamentos",
      "departamentos_disable",
      "municipios",
      "municipios_disable",
      "distritos",
      "distritos_disable",
      "es_salvadoreno",
    ]),
    // Models para el formulario
    nombre_comercial_input: {
      get() {
        return this.formulario_dos.nombre_comercial;
      },
      set(value) {
        this.formulario_dos.nombre_comercial = value;
      },
    },
    sitio_web_input: {
      get() {
        return this.formulario_dos.sitio_web;
      },
      set(value) {
        this.formulario_dos.sitio_web = value;
      },
    },
    genero_input: {
      get() {
        return this.formulario_dos.genero;
      },
      set(value) {
        this.formulario_dos.genero = value;
      },
    },
    id_clasificacion_empresarial_input: {
      get() {
        return this.formulario_dos.id_clasificacion_empresarial;
      },
      set(value) {
        this.formulario_dos.id_clasificacion_empresarial = value;
      },
    },
    id_pais_input: {
      get() {
        return this.formulario_dos.id_pais;
      },
      set(value) {
        this.formulario_dos.id_pais = value;
      },
    },
    id_departamento_input: {
      get() {
        return this.formulario_dos.id_departamento;
      },
      set(value) {
        this.formulario_dos.id_departamento = value;
      },
    },
    id_municipio_input: {
      get() {
        return this.formulario_dos.id_municipio;
      },
      set(value) {
        this.formulario_dos.id_municipio = value;
      },
    },
    id_distrito_input: {
      get() {
        return this.formulario_dos.id_distrito;
      },
      set(value) {
        this.formulario_dos.id_distrito = value;
      },
    },
    direccion_input: {
      get() {
        return this.formulario_dos.direccion;
      },
      set(value) {
        this.formulario_dos.direccion = value;
      },
    },
    area_input: {
      get() {
        return this.formulario_dos.area;
      },
      set(value) {
        this.formulario_dos.area = value;
      },
    },
    telefono_input: {
      get() {
        return this.formulario_dos.telefono;
      },
      set(value) {
        this.formulario_dos.telefono = value;
      },
    },
    // Mensajes de validacion
    nombreError() {
      const errors = [];
      if (!this.$v.formulario_dos.nombre_comercial.$dirty) return errors;
      !this.$v.formulario_dos.nombre_comercial.required &&
        errors.push("El nombre es requerido");
      !this.$v.formulario_dos.nombre_comercial.maxLength &&
        errors.push("El nombre comercial no debe exceder los 100 caracteres");
      return errors;
    },
    sitioWebError() {
      const errors = [];
      if (!this.$v.formulario_dos.sitio_web.$dirty) return errors;
      !this.$v.formulario_dos.sitio_web.url &&
        errors.push("El sitio web es inválido");
      return errors;
    },
    generoError() {
      const errors = [];
      if (!this.$v.formulario_dos.genero.$dirty) return errors;
      !this.$v.formulario_dos.genero.required &&
        errors.push("El género es requerido");
      return errors;
    },
    clasificacionEmpresarialError() {
      const errors = [];
      if (!this.$v.formulario_dos.id_clasificacion_empresarial.$dirty)
        return errors;
      !this.$v.formulario_dos.id_clasificacion_empresarial.required &&
        errors.push("La clasificación empresarial es requerida");
      return errors;
    },
    paisError() {
      const errors = [];
      if (!this.$v.formulario_dos.id_pais.$dirty) return errors;
      !this.$v.formulario_dos.id_pais.required &&
        errors.push("El país es requerido");
      return errors;
    },
    departamentoError() {
      const errors = [];
      if (!this.$v.formulario_dos.id_departamento.$dirty) return errors;
      !this.$v.formulario_dos.id_departamento.requiredIf &&
        errors.push("El departamento es requerido");
      return errors;
    },
    municipioError() {
      const errors = [];
      if (!this.$v.formulario_dos.id_municipio.$dirty) return errors;
      !this.$v.formulario_dos.id_municipio.requiredIf &&
        errors.push("El municipio es requerido");
      return errors;
    },
    distritoError() {
      const errors = [];
      if (!this.$v.formulario_dos.id_distrito.$dirty) return errors;
      !this.$v.formulario_dos.id_distrito.requiredIf &&
        errors.push("El distrito es requerido");
      return errors;
    },
    direccionError() {
      const errors = [];
      if (!this.$v.formulario_dos.direccion.$dirty) return errors;
      !this.$v.formulario_dos.direccion.required &&
        errors.push("La dirección es requerida");
      !this.$v.formulario_dos.direccion.address &&
        errors.push(
          "La dirección es inválida, caracteres especiales no permitidos"
        );
      !this.$v.formulario_dos.direccion.maxLength &&
        errors.push("La dirección no debe exceder los 100 caracteres");
      return errors;
    },
    areaError() {
      const errors = [];
      if (!this.$v.formulario_dos.area.$dirty) return errors;
      !this.$v.formulario_dos.area.required &&
        errors.push("El área es requerida");
      return errors;
    },
    telefonoError() {
      const errors = [];
      if (!this.$v.formulario_dos.telefono.$dirty) return errors;
      !this.$v.formulario_dos.telefono.required &&
        errors.push("El teléfono es requerido");
      !this.$v.formulario_dos.telefono.minLength &&
        errors.push("Teléfono debe teber al menos 8 dígitos");
      !this.$v.formulario_dos.telefono.serial &&
        errors.push("El número de teléfono es inválido");
      !this.$v.formulario_dos.telefono.containNumber &&
        errors.push("Teléfono debe comenzar con 2, 6 o 7.");
      return errors;
    },
  },
  methods: {
    ...mapActions("registroProveedor", [
      "obtener_generos",
      "obtener_clasificaciones_empresariales",
      "obtener_paises",
      "obtener_municipios",
      "obtener_distritos",
      "obtener_departamentos",
      "cambiar_codigo_area",
    ]),
    verificar() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
  },
  created() {
    this.obtener_generos();
    this.obtener_clasificaciones_empresariales();
  },
  watch: {
    'formulario_dos.id_municipio': {
      handler(value) {
        //this.formulario_dos.id_distrito = null;
        this.obtener_distritos(value);
      },
      immediate: true,
      deep: true,
    }
  }
};
</script>
