<script>
import { NavButton } from '@/components/utils';
import Estadisticas from './components/Estadisticas.vue';
import ChartContainerComponent from './components/ChartContainerComponent.vue';

export default {
  name: "ResumenAreaComunicacionesView",
  components: { NavButton, Estadisticas, ChartContainerComponent },

  data: () => ({
    charts: [],
  }),

  methods: {
    createChartOptions(data, title) {
      return {
        title: title,
        series: data.map(item => parseFloat(item.total)),
        chartOptions: {
          chart: {
            type: "donut",
          },
          labels: data.map(item => item.nombre),
          plotOptions: {
            pie: {
              expandOnClick: true,
              donut: {
                size: '65%'
              }
            }
          },
          dataLabels: {
            enabled: true
          },
          legend: {
            position: 'top'
          },
        }
      };

    },

    async createChart(serviceMethod) {
      try {
        const { data: { data: response }, data: { message: title } } = await serviceMethod();
        return this.createChartOptions(response, title);
      } catch (error) {
        console.error(error);
      }
    },

    async obtenerTotalConvenios() {
      const chartData = await this.createChart(
        this.services.Resumen.obtenerTotalConvenios
      );

      if (chartData) {
        chartData.chartOptions = {
          ...chartData.chartOptions,
          tooltip: {
            y: {
              formatter: function (value) {
                return '$ ' + value
              }
            }
          }
        };

        this.charts.push(chartData);
      }
    },

    async obtenerNumeroOrdenesCompra() {
      const chartData = await this.createChart(
        this.services.Resumen.obtenerNumeroOrdenesCompra
      );
      if (chartData) this.charts.push(chartData);
    },

    async obtenerTipoInicioSesionUsuarios() {
      const chartData = await this.createChart(
        this.services.Resumen.obtenerTipoInicioSesionUsuarios
      );
      if (chartData) this.charts.push(chartData);
    },

    async obtenerCantidadProcesosAnio() {
      const chartData = await this.createChart(
        this.services.Resumen.obtenerCantidadProcesosAnio
      );
      if (chartData) this.charts.push(chartData);
    },

    async obtenerPorcentajePerfilProveedores() {
      const chartData = await this.createChart(
        this.services.Resumen.obtenerPorcentajePerfilProveedores
      );
      if (chartData) this.charts.push(chartData);
    },
  },
  created() {
    this.obtenerTotalConvenios();
    this.obtenerNumeroOrdenesCompra();
    this.obtenerTipoInicioSesionUsuarios();
    this.obtenerCantidadProcesosAnio();
    this.obtenerPorcentajePerfilProveedores();
  },
};
</script>

<template>
  <v-container>
    <v-card>
      <div class="d-flex align-center px-4 py-4" style="gap: 16px;">
        <NavButton />
        <h2 class="text-h5 text-uppercase font-weight-bold">Resumen área de comunicaciones</h2>
      </div>
      <v-divider />
      <div class="px-4 py-4">
        <Estadisticas />
        <v-row align="stretch">
          <ChartContainerComponent v-for="(chart, index) in charts" :key="index" :chartData="chart" />
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>