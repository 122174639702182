<template>
  <v-card>
    <v-card-actions>
      <v-row>
        <v-col>
          <ValidarEdicion
            :id_proceso="+this.id_proceso"
            :id_modificativa="4"
            @validar="validar"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-switch
            v-model="options.borrados"
            label="Mostrar registros eliminados"
            color="success"
            @change="filtrar"
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <v-row>
        <v-col v-if="this.validado" cols="4" md="4">
          <v-btn @click="abrirModalAgregar" class="secondary">
            Agregar Solicitud de Necesidad
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <DataTableComponent
            :getRowClass="getRowClass"
            :items="listado"
            :headers="headers"
            :total_registros="+totalItems"
            v-models:pagina="options.page"
            v-models:select="options.per_page"
            @paginar="paginar"
          >
            <template #[`item.accion`]="{ item }">
              <app-menu-acciones
                :menu="getAcciones(item)"
                titulo="Acciones"
                :elemento="item"
              ></app-menu-acciones>
            </template>
          </DataTableComponent>
        </v-col>
      </v-row>
      <modalDetalleFondo
        ref="modalDetalleFondo"
        :id_proceso="id_proceso"
        :show="showModalDetalle"
        :id_solicitud_var="sn"
        @cerrar="showModalDetalle = false"
      />
      <modalAgregarFondo
        ref="modalAgregarFondo"
        :id_proceso="id_proceso"
        :showModalA="showModalAgregar"
        @cerrar="cerrarModalAgregar"
      />
      <ConfirmationDialog
        :show="show_modal"
        :title="modalTitle"
        message="El cambio es reversible, pero se estará registrando cada acción realizada"
        btnConfirmar="Continuar"
        @close="show_modal = false"
        @confirm="removeSolicitud"
      />
      <CmModalCertificacion
        :id-solicitud-necesidad="sn"
        :isOpen="modalCertificacion"
        :certificacion="certificacion"
        :puede-eliminar="validado"
        @close="modalCertificacion = false"
        @recargar-certificacion="recargarCertificacion"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import ValidarEdicion from "./ValidarEdicion.vue";
import modalDetalleFondo from "./modalDetalleFondo.vue";
import modalCambiarProveedor from "./modalCambiarProveedor.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import modalAgregarFondo from "./modalAgregarFondo.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import CmModalCertificacion from "./CMCertificacion/cmModalCertificacion.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import { mapState } from "vuex";

export default {
  name: "GestionSN",
  components: {
    ValidarEdicion,
    modalDetalleFondo,
    modalCambiarProveedor,
    ConfirmationDialog,
    modalAgregarFondo,
    DataTableComponent,
    CmModalCertificacion,
  },
  data: () => ({
    validado: false,
    firstLoad: true,
    listado: [],
    options: {
      page: 1,
      per_page: 10,
    },
    headers: [
      {
        text: "Correlativo",
        value: "correlativo",
        align: "start",
      },
      {
        text: "OBS",
        value: "obs",
        align: "start",
      },
      {
        text: "Unidad",
        value: "unidad",
        align: "start",
      },
      {
        text: "Solicitante",
        value: "solicitante",
        align: "start",
      },
      {
        text: "Información adicional",
        value: "informacion_adicional",
        align: "start",
      },
      {
        text: "Monto ($)",
        value: "monto_asignado",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado_solicitud",
        align: "start",
      },
      { text: "Acciones", value: "accion", align: "center" },
    ],
    totalItems: 0,
    proveedores: [],
    showModalDetalle: false,
    showModalAgregar: false,
    sn: null,
    showModalCambiarProveedor: false,
    show_modal: false,
    modalTitle: "",
    //Catalogo electronico
    modalCertificacion: false,
    certificacion: createLoadable(null),
    mostrarCertificacionesEliminadas: false,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    id_proceso() {
      return +this?.$route?.params?.idProceso || 0;
    },
  },
  methods: {
    validar(valor) {
      this.validado = valor;
    },
    async abrirModalAgregar() {
      this.showModalAgregar = true;
      await this.$refs.modalAgregarFondo.getSolicitud();
    },
    async cerrarModalAgregar() {
      this.showModalAgregar = false;
      this.obtenerListadoSN();
    },
    filtrar() {
      this.reiniciarPaginacion();
      this.obtenerListadoSN();
    },
    reiniciarPaginacion() {
      this.options.page = 1;
    },
    async obtenerListadoSN() {
      const {
        data,
        headers: { total_rows },
      } = await this.services.SolicitudesNecesidad.listSN(
        this.id_proceso,
        this.options
      );

      this.totalItems = total_rows;
      this.listado = data;
    },
    getRowClass(item) {
      if (item.eliminado) {
        return "highlight-row";
      }
      return "";
    },
    paginar(paginacion) {
      const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;

      this.options.page = pagina;
      this.options.per_page = registrosPorPagina;
      this.obtenerListadoSN();
    },
    async removeSolicitud() {
      const response =
        await this.services.SolicitudesNecesidad.deleteSolicitudNProcesoPAC(
          this.id_proceso,
          this.sn
        );
      this.show_modal = false;
      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data?.message,
        });
      }
      await this.obtenerListadoSN();
    },
    async cargarCertificaciones() {
      toggleLoadable(this.certificacion);
      const { data } = await this.services.CmHerramienta.obtenerCertificacion(
        this.id_proceso,
        this.sn,
        {
          borrados: this.borrados,
        }
      );
      setLoadableResponse(this.certificacion, data);
    },
    async abrirModalCertificacion() {
      this.modalCertificacion = true;
      this.cargarCertificaciones();
    },
    async recargarCertificacion(item) {
      this.borrados = item;
      this.cargarCertificaciones();
    },
    getAcciones(item) {
      let accionItem = item.eliminado ? "Restaurar" : "Eliminar";

      return [
        {
          nombre: "Visualizar",
          icono: "mdi-eye",
          callback2: () => {
            this.sn = item.id;
            this.showModalDetalle = true;
          },
        },
        {
          nombre: `${accionItem} la solicitud de necesidad`,
          icono: "mdi-delete",
          callback2: () => {
            this.sn = item.id;
            this.modalTitle = item.eliminado
              ? "¿Deseas vincular la solicitud de necesidad?"
              : "¿Deseas desvincular la solicitud de necesidad?";
            this.show_modal = true;
          },
          disabled: !this.validado,
        },
        // Solo se muestra cuando es catalogo electronico
        ...(this.procesoData.id_forma_contratacion === 27
          ? [
              {
                nombre: "Certificación catalogo electrónico",
                icono: "mdi-certificate-outline",
                callback2: () => {
                  this.sn = item.id;
                  this.abrirModalCertificacion();
                },
              },
            ]
          : []),
      ];
    },
  },
  mounted() {
    this.obtenerListadoSN();
    this.firstLoad = true;
  },
  watch: {
    sn(newVal) {
      if (newVal && this.showModalDetalle) {
        if (this.$refs && this.$refs.modalDetalleFondo) {
          this.$refs.modalDetalleFondo.FetchDetailSolicitudNecesidadFtn(newVal);
        } else {
          console.warn("El modalDetalleFondo no está disponible en $refs.");
        }
      } else {
        console.warn(
          "No se ha seleccionado una Solicitud de Necesidad válida."
        );
      }
    },
  },
};
</script>

<style>
.highlight-row {
  background-color: #ecb36d !important;
}
</style>
