import { http_client_with_status } from "@/plugins/http_client";

const cargarDocumentosProceso = (idProcesoCompra) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/documentos-proceso/${idProcesoCompra}`,
    null,
    'get',
);

const visualizarDocumentoProceso = (idDocumentoProceso) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/documentos-proceso/visualizar-documento/${idDocumentoProceso}`,
    null,
    'get',
    {},
    { responseType: 'blob' },
);

const guardarDocumentoProceso = (idProcesoCompra, payload) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/documentos-proceso/guardar-documento/${idProcesoCompra}`,
    payload,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const eliminarDocumentoProceso = (idDocumentoProceso) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/documentos-proceso/eliminar-documento/${idDocumentoProceso}`,
    null,
    'delete',
);

const continuarProceso = (idProcesoCompra) => http_client_with_status(
  `/api/v1/herramienta-recurso-revision/continuar-proceso/${idProcesoCompra}`,
  null,
  'post',
);

const cargarRecursos = (idProcesoCompra) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/cargar-recursos-revision/${idProcesoCompra}`,
    null,
    'get',
);

const visualizarPrevencion = (idPrevencion) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/visualizar-prevencion/${idPrevencion}`,
    null,
    'get',
    {},
    { responseType: 'blob' },
);

const visualizarRecursoRevision = (idRecursoRevision) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/visualizar-recurso-revision/${idRecursoRevision}`,
    null,
    'get',
    {},
    { responseType: 'blob' },
);

const obtenerDetallesPrevencionRecursoRevision = (idPrevencion) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/detalles-prevencion/${idPrevencion}`,
    null,
    'get',
);

const visualizarSubsanacion = (idSubsanacion) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/visualizar-subsanacion/${idSubsanacion}`,
    null,
    'get',
    {},
    { responseType: 'blob' },
);

const visualizarActaRecurso = (idActa) => http_client_with_status(
    `/api/v1/herramienta-recurso-revision/visualizar-acta/${idActa}`,
    null,
    'get',
    {},
    { responseType: 'blob' },
);

export default {
    cargarDocumentosProceso,
    visualizarDocumentoProceso,
    guardarDocumentoProceso,
    eliminarDocumentoProceso,
    continuarProceso,
    cargarRecursos,
    visualizarPrevencion,
    visualizarRecursoRevision,
    obtenerDetallesPrevencionRecursoRevision,
    visualizarSubsanacion,
    visualizarActaRecurso,
};
