<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-4 py-4">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="pt-0"
                    @click.stop="cerrarModal"
                    text
                    rounded
                    :loading="prevencionRecursoRevision.isLoading"
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-4 py-4">
                <EmptyComponent 
                    v-if="!prevencionRecursoRevision.isLoading"
                    :empty="!Boolean(prevencion)" 
                    colored 
                    icon="mdi-folder-open" 
                    text="El recurso no tiene una prevención"
                >
                    <template v-if="prevencion">
                        <div class="d-flex align-center" style="gap: 8px;">
                            <v-icon>mdi-folder-information</v-icon>
                            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">Prevención</h6>
                        </div>
                        <v-divider class="mt-2" />
                        <dl class="mt-2">
                            <template v-if="recurso">
                                <dt class="font-weight-bold text-body-2">Proveedor</dt>
                                <dd class="text-body-2">{{ recurso.Usuario?.Proveedor?.nombre_comercial ?? '-' }}</dd>
                            </template>

                            <dt class="font-weight-bold text-body-2 mt-2">Fecha de creación</dt>
                            <dd class="text-body-2">{{ formatDate(prevencion.created_at) }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-2">Descripción</dt>
                            <dd class="text-body-2">{{ prevencion.descripcion }}</dd>
                        </dl>
                    </template>

                    <template v-if="subsanacion">
                        <div class="d-flex align-center mt-8" style="gap: 8px;">
                            <v-icon>mdi-folder-text</v-icon>
                            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">Subsanación</h6>
                        </div>
                        <v-divider class="mt-2" />
                        <dl class="mt-2">
                            <dt class="font-weight-bold text-body-2">Fecha de creación</dt>
                            <dd class="text-body-2">{{ formatDate(subsanacion.created_at) }}</dd>

                            <dt class="font-weight-bold text-body-2 mt-2">Descripción</dt>
                            <dd class="text-body-2">{{ subsanacion.descripcion }}</dd>
                        </dl>
                    </template>
                </EmptyComponent>

                <div 
                    class="d-flex justify-center align-center mt-8" 
                    style="position: sticky; bottom: 16px; background: white; gap: 16px;"
                >
                    <v-btn 
                        class="flex-grow-1 flex-shrink-1" 
                        color="primary" 
                        large
                        :loading="prevencionRecursoRevision.isLoading"
                        @click.stop="cerrarModal"
                    >
                        Cerrar
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'ModalDetallePrevencion',
    components: { EmptyComponent },
    props: {
        isOpen: { type: Boolean },
        idRecursoRevision: { type: [Number, String] },
        service: { type: Function },
    },
    data: () => ({
        prevencionRecursoRevision: createLoadable(null),
    }),
    computed: {
        recurso() {
            return this.prevencionRecursoRevision.data.recurso;
        },
        prevencion() {
            return this.prevencionRecursoRevision.data?.prevencion;
        },
        subsanacion() {
            return this.prevencionRecursoRevision.data?.prevencion?.subsanacion;
        },
    },
    methods: {
        formatDate,
        cerrarModal() {
            this.$emit('update:isOpen', false);
        },
        async cargarPrevencionRecursoRevision() {
            toggleLoadable(this.prevencionRecursoRevision);

            const endpointService = this.service ?? this.services.PrevencionRecursoRevision.obtenerPrevencionRecursoRevision;

            const { data } = await endpointService(this.idRecursoRevision);
            setLoadableResponse(this.prevencionRecursoRevision, data);
        },
    },
    watch: {
        isOpen(value) {
            if (value) {
                this.prevencionRecursoRevision = createLoadable(null);
                
                if (this.idRecursoRevision) this.cargarPrevencionRecursoRevision();
            }
        },
    },
}
</script>
