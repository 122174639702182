<template>
    <v-dialog v-model="isOpen" max-width="1000px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex align-center" style="width: 100%; gap: 4px;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>

                    <h6 class="text-subtitle-1 text-uppercase font-weight-bold mb-0" style="word-break: break-word; transform: translateY(2px);">
                        Procesos relacionados bajo la misma PAC
                    </h6>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>
            <v-card-text class="px-4 py-4">
                <DataTableComponent 
                    :items="procesos.data"
                    :headers="headers"
                    :show_loading="procesos.isLoading"
                    :tiene_paginacion="false"
                    dense
                    no-gutters
                >
                    <template v-slot:item.deleted_at="{ item }">
                        <v-chip :color="item.deleted_at ? 'red' : 'success'" class="white--text">
                            {{ item.deleted_at ? 'No' : 'Si' }}
                        </v-chip>
                    </template>
                </DataTableComponent>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';

export default {
    name: 'ModalProcesosRelacionados',
    components: { DataTableComponent },
    props: {
        isOpen: { type: Boolean },
        idProcesoCompra: { type: Number },
    },
    data: () => ({
        procesos: createLoadable([]),
    }),
    computed: {
        headers() {
            return [
                { text: 'Nombre de proceso', value: 'nombre_proceso', align: 'center', sortable: false },
                { text: 'Código de proceso', value: 'codigo_proceso', align: 'center', sortable: false },
                { text: 'Nombre de proceso', value: 'nombre_proceso', align: 'center', sortable: false },
                { text: 'Institución', value: 'Institucion.nombre', align: 'center', sortable: false },
                { text: 'Modalidad', value: 'FormaContratacion.nombre', align: 'center', sortable: false },
                { text: 'Vigente', value: 'deleted_at', align: 'center', sortable: false },
            ];
        },
    },
    methods: {
        cerrarModal() {
            this.$emit('update:is-open', false);
        },
        async cargarProcesosRelacionados(idProcesoCompra) {
            toggleLoadable(this.procesos);
            const { data } = await this.services.Paac.cargarProcesosRelacionadosConPAC(idProcesoCompra);
            setLoadableResponse(this.procesos, data);
        },
    },
    watch: {
        idProcesoCompra(value) {
            this.procesos = createLoadable([]);
            
            if (!value) return;

            this.cargarProcesosRelacionados(value);
        },
    }
}
</script>