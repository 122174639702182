<template>
  <section class="ml-3">
    <!-- Breadcrumbs / migas de pan -->
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
    </v-row>

    <!-- Encabezado del proceso -->
    <v-row align="center">
      <v-col cols="4" md="1" sm="2">
        <v-img width="100px" src="@/assets/img/logo_512_negro.png"></v-img>
      </v-col>
      <v-col cols="12" md="5" sm="8">
        <span class="secondary--text text-h5">{{
            procesoData?.nombre_proceso
          }}</span>
      </v-col>
    </v-row>

    <!-- Ofertas presentadas -->
    <div v-if="[5, 12, 13].includes(etapaActual?.id_tipo_etapa)">
      <h5 class="text-h5 mt-8">Ofertas presentadas</h5>

      <v-data-table
          :items="offers"
          :headers="headers"
          class="mt-6"
      >
        <template #[`item.id_tipo_oferta`]="{ item }">
          <v-btn @click="downloadDocumentOffer(item.documento_oferta)" block text class="text-start">
            <v-icon color="secondary">mdi-download</v-icon> {{ item.id_tipo_oferta === 1 ? "Técnica" : "Económica" }}
          </v-btn>
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template #[`item.nombre_proveedor`]="{ item }">
          {{ item?.ParticipacionProveedor?.Proveedor?.nombre_comercial ? item.ParticipacionProveedor?.Proveedor?.nombre_comercial : item?.ParticipacionProveedor?.pnr?.nombre_comercial }}
        </template>

        <template #[`item.fecha_recepcion`]="{ item }">
          {{ formatDate(item.fecha_recepcion) }}
        </template>
      </v-data-table>
    </div>

    <v-divider class="my-4"/>

    <!-- Form para cargar el acta -->
    <h5 class="text-h5 mt-8">Evaluación de ofertas</h5>
    <v-form
        ref="form"
        lazy-validation
        class="mt-5"
        v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
    >
      <!-- ACTA DE EVALUACIÓN DE OFERTA TÉCNICA -->
      <v-row v-if="(!actaActual || editActa) && [12].includes(etapaActual?.id_tipo_etapa)">
        <v-col cols="8" md="4" sm="8">
          <v-file-input
              v-model="documentoActa"
              accept="application/pdf"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              label="Acta de panel de evaluación*"
              outlined
              :error-messages="errorArchivo"
              @blur="$v.documentoActa.$touch"
          ></v-file-input>
        </v-col>
        <v-col cols="4" md="8" sm="4" class="d-flex mt-2">
          <v-btn color="secondary" @click="guardarActa">
            <v-icon color="white">mdi-content-save</v-icon> Guardar
          </v-btn>
          <v-btn solid color="secondary" @click="editActa = false" class="ml-4" v-if="editActa">
            <v-icon color="white">mdi-close</v-icon> Cancelar edición
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="[12].includes(etapaActual?.id_tipo_etapa)" class="mb-8">
        <v-col class="d-flex">
          <v-btn color="secondary" @click="descargarActa"> Descargar acta actual </v-btn>
          <v-btn icon color="secondary" @click="editActa = true" class="ml-4">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="(!actaActual || editActa) && [5, 13].includes(etapaActual?.id_tipo_etapa)">
        <v-col cols="8" md="4" sm="8">
          <v-file-input
              v-model="documentoActa"
              accept="application/pdf"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              label="Acta de panel de evaluación*"
              outlined
              :error-messages="errorArchivo"
              @blur="$v.documentoActa.$touch"
          ></v-file-input>
        </v-col>
        <v-col cols="4" md="8" sm="4" class="d-flex mt-2">
          <v-btn color="secondary" @click="guardarActa">
            <v-icon color="white">mdi-content-save</v-icon> Guardar
          </v-btn>
          <v-btn solid color="secondary" @click="editActa = false" class="ml-4" v-if="editActa">
            <v-icon color="white">mdi-close</v-icon> Cancelar edición
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="[5, 13].includes(etapaActual?.id_tipo_etapa)" class="mb-8">
        <v-col class="d-flex">
          <v-btn color="secondary" @click="descargarActa"> Descargar acta actual </v-btn>
          <v-btn icon color="secondary" @click="editActa = true" class="ml-4">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- Botón para añadir ganador -->
    <v-row
        v-if="
        [5, 12, 13].includes(etapaActual?.id_tipo_etapa) &&
        !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')
      "
    >
      <v-col cols="12" md="2" sm="4">
        <v-btn color="secondary" @click="agregarGanador">
          Añadir ganador
        </v-btn>
      </v-col>
    </v-row>

    <!-- Tabla para mostrar proveedores financieros -->
    <v-row
        v-if="etapaActual?.id_tipo_etapa == 5 || etapaActual?.id_tipo_etapa == 13"
    >
      <v-col>
        <p>Listado ganadores oferta económica</p>
        <v-data-table
            hide-default-footer
            :items-per-page="-1"
            :headers="encabezadoTabla"
            :items="datosTabla"
        >

          <template v-slot:item.proveedor="{ item }">
            {{ item.ParticipacionProveedor?.Proveedor?.nombre ? item.ParticipacionProveedor?.Proveedor?.nombre : item?.ParticipacionProveedor?.pnr?.nombre }}
          </template>
          <!-- Template para acciones con un icon button con un ojo -->
          <template v-slot:item.acciones="{ item }">
            <v-btn v-if="!item?.Proveedor?.Usuario?.is_disabled" class="mr-4" outlined @click="verGanador(item, 'financiera')"
            >Ver oferta</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Tabla para mostrar proveedores tecnicos -->
    <v-row
        v-if="
        etapaActual?.id_tipo_etapa == 12 || etapaActual?.id_tipo_etapa == 13
      "
    >
      <v-col>
        <p>Listado ganadores oferta técnica</p>
        <v-data-table
            hide-default-footer
            :items-per-page="-1"
            :headers="encabezadoTabla"
            :items="datosTablaT"
        >
          <template v-slot:item.proveedor="{ item }">
            {{ item?.Proveedor?.nombre ? item?.Proveedor?.nombre : item?.ParticipacionProveedor?.pnr?.nombre }}
          </template>
          <!-- Template para acciones con un icon button con un ojo -->
          <template v-slot:item.acciones="{ item }">
            <v-btn v-if="!item?.Proveedor?.Usuario?.is_disabled" class="mr-4" outlined @click="verGanadorT(item, 'tecnica')"
            >Ver oferta</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row v-if="![5, 12, 13].includes(etapaActual?.id_tipo_etapa)" class="my-16">
      <v-col>
        <p>
          No se puede generar una evaluación en este momento, por favor
          espere a que el proceso se encuentre en la etapa de evaluación de ofertas.
        </p>
      </v-col>
    </v-row>

    <!-- Botón para volver a la vista peo -->
    <v-row class="mt-8">
      <v-btn class="mx-3" outlined @click="volver" color="secondary">
        Volver
      </v-btn>
      <v-btn
          v-if="
          [5, 12].includes(etapaActual?.id_tipo_etapa) &&
          etapaActual?.id_tipo_etapa !== 13 &&
          !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')
        "
          @click="finalizarEvaluacion()"
          color="secondary white--text"
      >
        Finalizar evaluación
      </v-btn>
    </v-row>
  </section>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import DataTableComponent from "../../components/DataTableComponent.vue";
import { mapMutations, mapActions, mapState } from "vuex";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "peoGanadoresView",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      encabezadoTabla: [
        {
          text: "Proveedor",
          align: "start",
          sortable: false,
          value: "proveedor",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "acciones",
        },
      ],
      // datos de muestra para la tabla
      datosTabla: [],
      datosTablaT: [],
      documentoActa: null,
      etapaActual: {},
      actaActual: null,
      editActa: false,
      headers: [
        {
          text: "Proveedor",
          value: "nombre_proveedor",
        },
        {
          text: "Fecha de registro",
          value: "created_at",
        },
        {
          text: "Fecha de recepción",
          value: "fecha_recepcion",
        },
        {
          text: "Tipo de oferta",
          value: "id_tipo_oferta",
        },
      ],
    };
  },
  validations: {
    documentoActa: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["getProcessOffers"]),
    ...mapMutations("procesoCompraDoc", ["setInfoProceso", "setParticipando"]),
    async getProceso() {
      const response = await this.services.PacProcesos.getProceso(
          this.$route.params.id
      );

      if (response.status === 200) {
        this.setInfoProceso(response.data);
      }
    },
    async guardarActa() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        const formData = new FormData();
        formData.append("documento", this.documentoActa);
        formData.append("id_tipo_documento", this.etapaActual?.id_tipo_etapa === 12 ? 5001 : 4);

        const response = await this.services.PeoServices.cargarActaPanel(
            this.$route.params.id,
            formData
        );

        if (response.status === 201 || response.status === 200) {
          this.consultarActaActual();
          this.temporalAlert({
            show: true,
            type: "success",
            message: response.data.message,
          });
          this.editActa = false;
        }
      }
    },
    async consultarActaActual() {

      const response = await this.services.PeoServices.descargarActaPanel(
          this.$route.params.id,
          { id_tipo_documento: this.etapaActual?.id_tipo_etapa === 12 ? 5001 : 4 },
      );

      if (response.status == 200) this.actaActual = response.data;
    },
    async descargarActa() {

      const response = await this.services.PacProcesos.descargarDocumentoProceso({ruta: this.actaActual.ruta_documento});

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], this.actaActual.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    agregarGanador() {
      this.$router.push({
        name: "peo-nuevo-ganador",
        params: {
          id_proceso: this.$route.params.id,
        },
        query: {
          tipo:
              this.etapaActual?.id_tipo_etapa == 5 ? "financiera" : this.etapaActual?.id_tipo_etapa == 12 ? "tecnica" : null,
        },
      });
    },
    verGanador(item, tipo) {
      this.$router.push({
        name: "peo-nuevo-ganador",
        params: {
          id_proceso: this.$route.params.id,
          id_oferta: item.id,
        },
        query: {
          tipo: tipo,
        },
      });
    },
    verGanadorT(item, tipo) {
      this.$router.push({
        name: "peo-nuevo-ganador",
        params: {
          id_proceso: this.$route.params.id,
          id_oferta: item?.ofertas[0]?.id,
        },
        query: {
          tipo: tipo,
        },
      });
    },
    volver() {
      this.$router.push({
        name: "evaluacion-ofertas-peo",
        params: { id_proceso: this.$route.params.id },
      });
    },
    async getGanadoresF() {


      const { status, data } =
          await this.services.PeoServices.getGanadoresProcesos(
              this.$route.params.id,
              "",
              { ganador: true }
          )
      if (status == 200) {
        this.datosTabla = data;
      }

    },
    async getGanadoresT() {


      const { status, data } =
          await this.services.PeoServices.getGanadoresOfertaTecnica(
              this.$route.params.id,
              { es_ganador: true }
          )
      if (status == 200) {
        this.datosTablaT = data;
      }

    },
    async finalizarEvaluacion() {

      let idTipoEtapa = 3;

      if (this.etapaActual?.id_tipo_etapa == 5) {
        idTipoEtapa = 2;
      } else if (this.etapaActual?.id_tipo_etapa == 12) {
        idTipoEtapa = 1;
      }

      const response = await this.services.PeoServices.finalizarEvaluacion(
          this.$route.params.id,
          idTipoEtapa,
      );

      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Evaluación finalizada con éxito",
        });
        this.$router.replace('/peo');
      }
    },
    async getEtapaActual() {
      const { status, data } =
          await this.services.PacProcesos.getProcesoEtapasActivas(
              this.$route.params.id
          );

      if (status == 200) {
        for (let i = 0; i < data.length; i++) {
          if ([5, 12, 13].includes(data[i].id_tipo_etapa)) {
            this.etapaActual = data[i];
            break;
          }
        }
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return "-";
      return this.$moment(timestamp).format("DD-MM-YYYY hh:mm A");
    },
    async downloadDocumentOffer(ruta_documento) {

      const response =
          await this.services.PacProcesos.descargarDocumentoProceso({
            ruta: ruta_documento,
            disk: "procesos",
          }).catch(() => {

          });
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "oferta", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "participando", "offers"]),
    validarFinEvaluacion() {
      return this.datosTabla.length == 0 || this.datosTablaT.length == 0;
    },
    breadcrumbsItems() {
      return [
        {
          text: "Evaluaciones PEO",
          disabled: false,
          href: "#",
        },
        {
          text: this.procesoData?.codigo_proceso,
          disabled: false,
          href: "#",
        },
      ];
    },
    errorArchivo() {
      const errors = [];
      if (!this.$v.documentoActa.$dirty) return errors;
      !this.$v.documentoActa.required && errors.push("El archivo es requerido");
      !this.$v.documentoActa.fileTypeValid &&
      errors.push("El archivo debe ser PDF");
      !this.$v.documentoActa.fileNameValid &&
      errors.push("El archivo debe tener un nombre valido");
      !this.$v.documentoActa.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },
  async mounted() {
    await this.getProceso();
    await this.getEtapaActual();

    let idTipoOferta = null;
    switch(this.etapaActual?.id_tipo_etapa) {
      case 5:
        idTipoOferta = [2];
        break;
      case 12:
        idTipoOferta = [1];
        break;
      case 13:
        idTipoOferta = [1,2];
        break;
    }

    await this.getProcessOffers({ id_proceso_compra: this.procesoData?.id, query: { id_tipo_oferta: JSON.stringify(idTipoOferta) } });

    this.getGanadoresF();
    this.getGanadoresT();

    this.consultarActaActual();
  },
};
</script>
<style lang=""></style>
