<template>
    <ValidarSesionProcesosLibres>
        <ProcesosPublicos />
    </ValidarSesionProcesosLibres>
</template>
<script>

import { ValidarSesionProcesosLibres } from '@/components/utils';
import ProcesosPublicos from './ProcesosPublicos.vue';

export default {
    components: { ProcesosPublicos, ValidarSesionProcesosLibres },
}
</script>