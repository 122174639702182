<template>
  <v-card class="rounded-lg">
    <v-card-title class="bg-dark rounded-top-lg">
      <h4 class="text-center white--text">
        Obtener solicitudes de compra en catálogo electrónico
      </h4>
    </v-card-title>
    <v-card-text class="pa-6">
      <v-row class="mt-2">
        <v-text-field
          label="Codigo de proceso de compra"
          outlined
          rounded
          hide-details
          clearable
          v-model="filters.codigo_proceso"
          prepend-icon="mdi-magnify"
        />
      </v-row>
      <v-row class="mb-4 mt-10 d-flex justify-center">
        <v-btn
          :loading="solicitudCompra.isLoading"
          class="primary"
          :disabled="!puedeRealizarBusqueda"
          @click="obtenerInformacionSolicitudCompra()"
        >
          Filtrar
        </v-btn>
      </v-row>
    </v-card-text>
    <CmModalDetalleSolicitud
      :is-open="modalDetalleSolicitud"
      :solicitud-compra="solicitudCompra"
      @cerrar="modalDetalleSolicitud = false"
    />
  </v-card>
</template>

<script>
import {
  createLoadable,
  isResponseSuccesful,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import CmModalDetalleSolicitud from "./components/CmModalDetalleSolicitud.vue";

export default {
  name: "CmDetalleSolicitudCompra",
  components: { CmModalDetalleSolicitud },
  data: () => ({
    filters: {
      codigo_proceso: null,
    },
    solicitudCompra: createLoadable([]),
    modalDetalleSolicitud: false,
    headers: [
      {
        align: "center",
        sortable: false,
        text: "Solicitud de compra",
        value: "id",
      },
      { align: "center", sortable: false, text: "Acciones", value: "acciones" },
    ],
  }),
  computed: {
    puedeRealizarBusqueda() {
      return this.filters.codigo_proceso;
    },
  },
  methods: {
    async obtenerInformacionSolicitudCompra() {
      toggleLoadable(this.solicitudCompra);
      const { data } =
        await this.services.CmHerramienta.solicitudCompraPorProcesoCompra(
          this.filters
        );
      setLoadableResponse(this.solicitudCompra, data);
      if (isResponseSuccesful(data)) {
        this.modalDetalleSolicitud = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-dark {
  background: #343a40;
}

.color-dark {
  color: #343a40;
}
</style>
