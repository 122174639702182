<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-4 py-4" style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="d-flex align-center" style="gap: 4px;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                    <h6 v-if="tipoDocumento" class="text-subtitle-1 text-uppercase font-weight-bold" style="transform: translateY(1px); word-break: break-word;">
                        {{ tipoDocumento.nombre }}
                    </h6>
                </div>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-4 py-4">
                <v-form 
                    ref="form"
                    style="background-color: #FFFFFF;"
                    @submit.prevent="enviarFormulario()" 
                >
                    <v-file-input
                        v-model="form.documento"
                        label="Documento"
                        placeholder="Seleccione un documento"
                        outlined
                        :error-messages="documentoErrors"
                        @blur="$v.form.documento.$touch()"
                        accept="application/pdf"
                    />
                    <v-text-field
                        v-model="form.nombre"
                        class="mt-4"
                        label="Nombre"
                        placeholder="Nombre de documento"
                        :error-messages="nombreErrors"
                        outlined
                        @change="$v.form.nombre.$touch()"
                        @blur="$v.form.nombre.$touch()"
                    />

                    <div class="mt-4 d-flex flex-wrap flex-sm-nowrap" style="gap: 16px;">
                        <v-btn 
                            class="flex-shrink-1 flex-grow-1" 
                            style="flex-basis: 50%;"
                            color="secondary" 
                            large
                            @click.stop="cerrarModal"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            class="flex-shrink-1 flex-grow-1"
                            style="flex-basis: 50%;"
                            color="primary" 
                            type="submit"
                            large
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
        <ConfirmationModalComponent
            :isOpen="modalConfirmacionAbierta" 
            description="¿Desea continuar con la acción?" 
            @confirm="guardarDocumento" 
            :is-loading="guardadoDocumento.isLoading"
            @cancel="modalConfirmacionAbierta = false" 
        />
    </v-dialog>
</template>
<script>
import { ConfirmationModalComponent } from '@/components/utils';
import { convertToFormData } from '@/utils/data';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { required, maxLength } from 'vuelidate/lib/validators';

const initialFormState = {
    documento: null,
    nombre: null,
};

export default {
    name: 'ModalSubirDocumento',
    emits: ['on-documento-guardado'],
    components: { ConfirmationModalComponent },
    props: {
        isOpen: { type: Boolean },
        idProcesoCompra: { type: [Number, String] },
        tipoDocumento: { type: Object },
    },
    validations: {
        form: {
            documento: { required },
            nombre: { required, maxLength: maxLength(255) },
        },
    },
    data: () => ({
        form: { ...initialFormState },
        formConfirmacion: null,
        modalConfirmacionAbierta: false,
        guardadoDocumento: createLoadable(null),
    }),
    computed: {
        documentoErrors() {
            const errors = [];

            if (!this.$v.form.documento.$dirty) return errors;

            !this.$v.form.documento.required && errors.push("El documento es requerido.");

            return errors;
        },
        nombreErrors() {
            const errors = [];
            if (!this.$v.form.nombre.$dirty) return errors;

            !this.$v.form.nombre.required && errors.push("El nombre del documento es requerido.");
            !this.$v.form.nombre.maxLength && errors.push("El nombre del documento no debe exceder los 255 carácteres.");

            return errors;
        },
    },
    methods: {
        // UI
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
            this.form = { ...initialFormState };
        },
        cerrarModal() {
            this.limpiarFormulario();
            this.$emit('update:is-open', false);
        },
        async enviarFormulario() {
            this.$v.$touch();

            if (this.$v.form.$invalid) return;

            this.confirmarGuardadoDocumento();
        },
        confirmarGuardadoDocumento() {
            this.formConfirmacion = { ...this.form };
            this.cerrarModal();
            this.modalConfirmacionAbierta = true;
        },
        // DATA
        async guardarDocumento() {
            toggleLoadable(this.guardadoDocumento);

            const formData = convertToFormData({ 
                ...this.formConfirmacion,
                id_tipo_documento: this.tipoDocumento.id, 
            });

            const { data } = await this.services.HerramientaRecursoRevision.guardarDocumentoProceso(this.idProcesoCompra, formData);
            this.modalConfirmacionAbierta = false;
            setLoadableResponse(this.guardadoDocumento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.$emit('on-documento-guardado', data.data?.nuevas_etapas_creadas);
            }
        },
    },
}
</script>
<style scoped>
:deep(.v-messages__message) {
    color: #ff5252 !important;
}
</style>