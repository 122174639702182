<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex" style="width: 100%">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        :disabled="cambioPassword.isLoading"
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>
            <v-card-text class="px-4 py-4">
                <v-form 
                    ref="form"
                    style="background-color: #FFFFFF;"
                    @submit.prevent="enviarFormulario()" 
                >
                    <v-text-field
                        v-model="form.password"
                        :type="verPassword ? 'text' : 'password'"
                        label="Contraseña"
                        placeholder="Ingrese la nueva contraseña"
                        :error-messages="passwordErrors"
                        :disabled="cambioPassword.isLoading"
                        outlined
                        @change="$v.form.password.$touch()"
                        @blur="$v.form.password.$touch()"
                    >
                        <template #append>
                            <v-icon @click="verPassword = !verPassword">
                                {{ verPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                            </v-icon>
                        </template>
                    </v-text-field>

                    <div class="mt-4 d-flex flex-wrap flex-sm-nowrap" style="gap: 16px;">
                        <v-btn 
                            class="flex-shrink-1 flex-grow-1" 
                            style="flex-basis: 50%;"
                            color="secondary" 
                            large
                            :disabled="cambioPassword.isLoading"
                            @click.stop="cerrarModal"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            class="flex-shrink-1 flex-grow-1"
                            style="flex-basis: 50%;"
                            color="primary" 
                            type="submit"
                            large
                            :loading="cambioPassword.isLoading"
                        >
                            Restablecer contraseña
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { required, minLength } from 'vuelidate/lib/validators';
import { LocalStorage } from '@/utils/LocalStorage.mjs';

const initialFormState = {
    password: null,
};

export default {
    name: 'ModalCambioPassword',
    validations: {
        form: {
            password: { required, minLength: minLength(8) },
        },
    },
    data: () => ({
        form: { ...initialFormState },
        cambioPassword: createLoadable(null),
        verPassword: false,
    }),
    props: {
        isOpen: { type: Boolean },
    },
    computed: {
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;

            !this.$v.form.password.required && errors.push("La contraseña es requerida.");
            !this.$v.form.password.minLength && errors.push("La contraseña debe contener un mínimo de 8 carácteres.");

            return errors;
        },
    },
    methods: {
        // UI
        limpiarFormulario() {
            this.$v.$reset();
            this.$refs.form.reset();
            this.form = { ...initialFormState };
        },
        cerrarModal() {
            this.limpiarFormulario();
            
            this.$emit('update:is-open', false);
        },
        async enviarFormulario() {
            this.$v.$touch();

            if (this.$v.form.$invalid) return;

            this.cambiarPassword();
        },
        cerrarSesion() {
            LocalStorage.token_procesos_libres = null;
            this.pushAppMessage({ type: 'success', message: 'Sesión cerrada.' })
            this.$router.push('/login-procesos-compra');
        },
        // DATA
        async cambiarPassword() {
            toggleLoadable(this.cambioPassword);
            const { data } = await this.services.RegistroCiudadanos.cambiarPassword({ ...this.form });
            setLoadableResponse(this.cambioPassword, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.cerrarModal();
                this.cerrarSesion();
            }
        },
    },
}
</script>