export const colores = [
    '#92C5F9',
    '#4394E5',
    '#0066CC',
    '#AFDC8F',
    '#87BB62',
    '#63993D',
    '#B6A6E9',
    '#876FD4',
    '#5E40BE',
    '#3D2785',
    '#F8AE54',
    '#f9e79f',
    '#d98880',
    '#ccd1d1',
    '#808b96',
];
