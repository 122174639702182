<template>
  <v-dialog v-model="isOpen" max-width="1200px" persistent>
    <v-card>
      <v-card-title class="justify-space-between align-center">
        <v-btn
          style="
            min-width: unset !important;
            min-height: unset !important;
            width: 32px !important;
            height: 32px !important;
          "
          class="pt-0"
          @click.stop="cerrarModal"
          text
          rounded
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-switch
        class="ml-6"
        v-model="borrados"
        label="Mostrar registros eliminados"
        color="success"
        @change="recargarInformacion"
        hide-details
      />
      <v-alert v-if="certificacion.isLoading" class="text-center">
        <p class="pb-0 mb-4">Cargando certificaciones...</p>
        <v-progress-linear
          :size="40"
          :width="3"
          :indeterminate="true"
          color="primary"
          class="mx-auto mt-4"
        ></v-progress-linear>
      </v-alert>
      <EmptyComponent
        :empty="sinCertificacion"
        colored
        style="min-width: 100%"
        v-else
      >
        <template v-slot:empty-content>
          <v-icon x-large>mdi-clipboard-off-outline</v-icon>
          <p class="mb-2 mt-2 font-weight-bold text-body-1">
            No cuenta con certificaciones para el catálogo electrónico.
          </p>
        </template>
        <div class="px-4 py-4" v-if="!sinCertificacion">
          <v-card class="elevation-2 px-4 py-4">
            <div class="d-flex align-center flex-wrap" style="gap: 8px">
              <v-icon>mdi-cash</v-icon>
              <span
                class="font-weight-medium text-uppercase text-subtitle-1 font-weight-bold"
              >
                Asignacion presupuestaria disponible - ${{
                  certificacion.data.monto
                }}
              </span>
            </div>
          </v-card>
          <div class="px-2 py-2">
            <v-data-table
              :headers="headers"
              :items="certificacion.data.cc"
              :server-items-length="+totalCertificacion"
              :item-class="getRowClass"
            >
              <template v-slot:item.fecha_creacion="{ item }">
                {{ moment(item.created_at).format("DD/MM/YYYY h:mm a") }}
              </template>
              <template v-slot:item.acciones="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :disabled="!puedeEliminar"
                      v-on="on"
                      :color="item.deleted_at ? 'green' : 'red'"
                      @click.stop="
                        item.deleted_at
                          ? restaurarCertificacion(item)
                          : abrirModalConfirmacionEliminar(item)
                      "
                    >
                      {{
                        item.deleted_at
                          ? "mdi-delete-restore"
                          : "mdi-delete-outline"
                      }}
                    </v-icon>
                  </template>
                  {{
                    item.deleted_at
                      ? "Restaurar certificación"
                      : "Eliminar certificación"
                  }}
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </div>
      </EmptyComponent>

      <v-card-text>
        <div
          class="d-flex justify-center align-center"
          style="position: sticky; bottom: 0; background: white; gap: 16px"
        >
          <v-btn
            class="flex-grow-1 flex-shrink-1 mt-4"
            color="secondary"
            large
            @click.stop="cerrarModal"
          >
            Cerrar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <ConfirmationModalComponent
      :isOpen="modalConfirmacionEliminar"
      description="¿Desea continuar con la acción?"
      @confirm="confirmarEliminarCertificacion"
      @cancel="modalConfirmacionEliminar = false"
      :isLoading="eliminadoCertificacion.isLoading"
    />
    <ConfirmationModalComponent
      :isOpen="modalConfirmacionRestablecer"
      description="¿Desea continuar con la acción?"
      @confirm="confirmarRestablecerCertificacion"
      @cancel="modalConfirmacionRestablecer = false"
      :isLoading="restablecerCertificacion.isLoading"
    />
  </v-dialog>
</template>

<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import {
  toggleLoadable,
  setLoadableResponse,
  createLoadable,
} from "@/utils/loadable.js";
import { Fragment } from "vue-fragment";
import ProgresiveLoadAutocomplete from "@/components/utils/ProgresiveLoadAutocomplete.vue";
import { EmptyComponent } from "@/components/utils";

export default {
  name: "cmModalCertificacion",
  components: {
    ConfirmationModalComponent,
    ProgresiveLoadAutocomplete,
    Fragment,
    EmptyComponent,
  },
  props: {
    isOpen: { type: Boolean },
    idSolicitudNecesidad: { type: Number },
    certificacion: { type: Object },
    puedeEliminar: { type: Boolean },
  },
  emits: ["close", "on-save", "recargar-certificacion"],
  data: () => ({
    modalConfirmacionEliminar: false,
    modalConfirmacionRestablecer: false,
    id_certificacion: null,
    eliminadoCertificacion: createLoadable([]),
    restablecerCertificacion: createLoadable([]),
    borrados: null,
    headers: [
      {
        align: "center",
        sortable: false,
        text: "Unidad",
        value: "FinanciamientoUnidad.Unidad.nombre",
      },
      {
        align: "center",
        sortable: false,
        text: "Monto certificado",
        value: "monto_disponible",
      },
      {
        align: "center",
        sortable: false,
        text: "Fecha de creación",
        value: "fecha_creacion",
      },
      { align: "center", sortable: false, text: "Acciones", value: "acciones" },
    ],
  }),
  computed: {
    sinCertificacion() {
      return !this.certificacion.data?.cc.length > 0;
    },
    totalCertificacion() {
      return this.certificacion.data?.cc.length ?? 0;
    },
    idProceso() {
      return this?.$route?.params?.idProceso;
    },
  },
  methods: {
    abrirModalConfirmacionEliminar(certificacion) {
      this.modalConfirmacionEliminar = true;
      this.id_certificacion = certificacion.id;
    },
    restaurarCertificacion(certificacion) {
      this.modalConfirmacionRestablecer = true;
      this.id_certificacion = certificacion.id;
    },
    async confirmarEliminarCertificacion() {
      toggleLoadable(this.eliminadoCertificacion);
      const { data } = await this.services.CmHerramienta.eliminarCertificacion(
        this.id_certificacion,
        { id_proceso_compra: this.idProceso }
      );
      setLoadableResponse(this.eliminadoCertificacion, data);
      this.modalConfirmacionEliminar = false;
      this.recargarInformacion();
    },
    async confirmarRestablecerCertificacion() {
      toggleLoadable(this.restablecerCertificacion);
      const { data } = await this.services.CmHerramienta.restaurarCertificacion(
        this.id_certificacion,
        { id_proceso_compra: this.idProceso }
      );
      setLoadableResponse(this.restablecerCertificacion, data);
      this.modalConfirmacionRestablecer = false;
      this.recargarInformacion();
    },
    cerrarModal() {
      this.$emit("close");
    },
    recargarInformacion() {
      this.$emit("recargar-certificacion", this.borrados);
    },
    getRowClass(item) {
      if (item.deleted_at) {
        return "highlight-row";
      }
      return "";
    },
  },
};
</script>
<style>
.highlight-row {
  background-color: #ecb36d;
}
</style>
