<template>
    <section>
        <p v-if="mostrarEncabezado" class="text-h5 secondary--text">Reprogramación de etapas restantes</p>
        <slot name="header"></slot>
        <PipProcesoComponent 
            :action="refrescarEtapas" 
            :etapas="etapasFiltradas" 
            :hide-actions="soloLectura"
            :permite-edicion="!soloLectura"
            :permitir-cualquier-fecha="permitirCualquierFecha"
            :no-generar-registro="noGenerarRegistro"
            :padding-extra="false"
            @actualizarEtapa="actualizarEtapa"
            :id-tipo-modificativa="idTipoModificativa"
        />
        <div v-if="!esconderBotonContinuar" class="mx-1 mt-8">
            <slot name="accion">
                <v-btn 
                    v-if="!soloLectura" 
                    @click="continuarProceso" 
                    color="secondary" 
                    dark
                >
                <v-icon class="mr-2">mdi-play-circle</v-icon>
                    Continuar proceso
                </v-btn>
            </slot>
        </div>
    </section>
</template>
<script>
import { shouldFetch } from "@/utils/loadable";
import { Listener, Observable } from "@/utils/observable";
import PipProcesoComponent from "@/views/ProcesoCompraDoc/components/PipProcesoComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
    name: 'ContinuarProceso',
    components: { PipProcesoComponent },
    props: {
        soloLectura: { type: Boolean, default: false },
        idProceso: { type: [String, Number] },
        mostrarEncabezado: { type: Boolean, default: true },
        esconderBotonContinuar: { type: Boolean },
        hito: { type: [Number, String] },
        mapper: { type: Function },
        permitirCualquierFecha: { type: Boolean, default: false },
        listenerRecarga: { type: Object },
        noGenerarRegistro: { type: Boolean },
        idTipoModificativa: {  type: Number },
    },
    data: () => ({
        etapas: [],
        cargandoEtapas: false,
        unsubscribe: null,
    }),
    computed: {
        ...mapState("procesoCompraDoc", ["siguientesEtapasImpugnacion"]),
        etapasFiltradas() {
            if (this.mapper) return this.siguientesEtapasImpugnacion.data.filter((etapa) => this.mapper(etapa));

            return this.siguientesEtapasImpugnacion.data;
        },
    },
    methods: {
        ...mapActions("procesoCompraDoc", ["cargarSiguientesEtapasImpugnacionConFiltro"]),
        async continuarProceso() {
            const { status } = await this.services.RecursoRevision.continuarProceso(this.idProceso, 1, { id_seguimiento_proceso: 3 });

            if (status === 204) location.reload();
        },
        actualizarEtapa() {
            this.$emit('actualizarEtapa');
            this.refrescarEtapas();
        },
        refrescarEtapas() {
            const filtros = {};

            if (this.hito) filtros.hito = this.hito;

            this.cargarSiguientesEtapasImpugnacionConFiltro({ idProceso: this.idProceso, filtros });
        },
    },
    created() {
        if (shouldFetch(this.siguientesEtapasImpugnacion)) this.refrescarEtapas();

        const listener = new Listener('ContinuarProceso').setHandler(() => {
            this.refrescarEtapas();
        });

        this.listenerRecarga?.setHandler(() => this.refrescarEtapas());

        this.unsubscribe = Observable.getInstance().subscribe('cierre-etapa-pip-general', listener);
    },
    beforeDestroy() {
        this.unsubscribe?.();
    },
}
</script>