<template>
  <section>
    <!-- Tabla para mostrar proveedores financieros -->
    <v-row>
      <v-col>
        <div class="mb-8">
          <v-btn text color="secondary" class="text-no-style" @click="descargarActa">
            <v-icon color="secondary" size="40">mdi-file-pdf-box</v-icon>
            Descargar acta de recomendación de PEO
          </v-btn>
        </div>

        <v-btn v-if="!haveRoles(['ROLE_COOP','ROLE_FRONT_LECTURA_CALLCENTER'])" color="secondary text-no-style" class="mb-8" @click="agregarGanador">
          <v-icon color="white">mdi-plus</v-icon>
          Agregar nuevo ganador
        </v-btn>

        <v-data-table 
          :items-per-page="-1" 
          :headers="encabezadoTabla" 
          :items="datosTabla"
          >
          <template v-slot:item.proveedor="{ item }">
            {{ item.ParticipacionProveedor?.Proveedor?.nombre ? item.ParticipacionProveedor?.Proveedor?.nombre : item?.ParticipacionProveedor?.pnr?.nombre }}
          </template>
          <template v-slot:item.lotesAdjudicados="{ item }">
            <ul>
              <li v-for="lote in obtenerLotesAdjudicados(item.lotesOferta)">
                {{ lote }}
              </li>
            </ul>
          </template>
          <template v-slot:item.lotesOferta="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              }).format(calcularCantidadAdjudicada(item.lotesOferta))
            }}
          </template>
          <template  v-if="!haveRoles(['ROLE_COOP', 'ROLE_FRONT_LECTURA_CALLCENTER'])" v-slot:item.acciones="{ item }">
            <v-btn v-if="!item.ParticipacionProveedor?.Proveedor?.Usuario?.is_disabled" icon @click="verGanador(item, 'financiera')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-if="!item.ParticipacionProveedor?.Proveedor?.Usuario?.is_disabled" icon class="ml-4" @click="confirmarEliminacionEvaluacion(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-form class="mt-5" v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])">
          <v-row v-if="!actaAdjudicacionActual || editActaAdjudicacion">
            <v-col cols="8" md="4" sm="8">
              <v-file-input v-model="actaAdjudicacion" accept="application/pdf" prepend-icon=""
                            prepend-inner-icon="mdi-paperclip" label="Acta de adjudicación *" outlined
                            :error-messages="errorArchivo"
                            @blur="$v.actaAdjudicacion.$touch"></v-file-input>
            </v-col>
            <v-col cols="4" md="8" sm="4" class="d-flex mt-2">
              <v-btn color="secondary" @click="guardarActaAdjudicacion">
                <v-icon color="white">mdi-content-save</v-icon>
                Guardar
              </v-btn>
              <v-btn solid color="secondary" @click="editActaAdjudicacion = false" class="ml-4"
                    v-if="editActaAdjudicacion">
                <v-icon color="white">mdi-close</v-icon>
                Cancelar edición
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mb-8" v-else-if="actaAdjudicacionActual">
            <v-col class="d-flex">
              <v-btn color="secondary" @click="descargarActaAdjudicacion">
                Descargar acta de adjudicación
              </v-btn>
              <v-btn icon color="secondary" @click="editActaAdjudicacion = true" class="ml-4">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <ConfirmationModalComponent 
      description="¿Desea eliminar este registro?"
      :isOpen="confirmacionAbierta"
      :isLoading="eliminacionLoadable.isLoading"
      @cancel="confirmacionAbierta = false"
      @confirm="eliminarEvaluacion"
    />
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import {required} from "vuelidate/lib/validators";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "AdjudicacionComponent",
  components: {
    DataTableComponent,
    ConfirmationModalComponent,
  },
  props: {
    etapa: {
      type: Object,
      default: () => {
      },
    },
    proceso: {
      type: Object,
      default: () => {
      },
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  validations: {
    actaAdjudicacion: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  data() {
    return {
      encabezadoTabla: [
        {
          text: "Proveedor",
          align: "start",
          sortable: false,
          value: "proveedor",
        },
        {
          text: "Lotes adjudicados",
          align: "start",
          sortable: false,
          value: "lotesAdjudicados",
        },
        {
          text: "Monto adjudicado",
          align: "right",
          sortable: false,
          value: "lotesOferta",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "acciones",
          width: "20%",
        },
      ],
      datosTabla: [],
      actaActual: {},
      actaAdjudicacion: null,
      actaAdjudicacionActual: null,
      editActaAdjudicacion: false,
      confirmacionAbierta: false,
      participacion: null,
      eliminacionLoadable: createLoadable(null),
    };
  },
  computed: {
    errorArchivo() {
      const errors = [];
      if (!this.$v.actaAdjudicacion.$dirty) return errors;
      !this.$v.actaAdjudicacion.required &&
      errors.push("El archivo es requerido");
      !this.$v.actaAdjudicacion.fileTypeValid &&
      errors.push("El archivo debe ser PDF");
      !this.$v.actaAdjudicacion.fileNameValid &&
      errors.push("El archivo debe tener un nombre valido");
      !this.$v.actaAdjudicacion.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);

      return errors;
    },
  },
  methods: {
    verGanador(item, tipo) {

      if ([14,15,16,17,19,20,21].includes(this.proceso.id_forma_contratacion)) {
        this.$router.push({
          name: "peo-nuevo-ganador",
          params: {
            id_proceso: this.$route.params.idProceso,
            id_oferta: item.id,
          },
          query: {
            from: "adjudicacion",
            tipo: "editarOfertaAdjudicado",
          },
        });
      } else {
        this.$router.push({
          name: "peo-nuevo-ganador",
          params: {
            id_proceso: this.$route.params.idProceso,
            id_oferta: item.id,
          },
          query: {
            from: "adjudicacion",
            tipo: tipo,
          },
        });
      }
    },
    async getGanadores() {
      if ([14,15,16,17,19,20,21].includes(this.proceso.id_forma_contratacion)) {
        const {status, data} = await this.services.PeoServices.getAdjudicadosOfertasCombinadas(this.$route.params.idProceso, {ganador: true});
        if (status == 200) {
          console.log(data, '12')
          this.datosTabla = data;
        }
      } else {
        const {status, data} =
            await this.services.PeoServices.getGanadoresProcesos(
                this.$route.params.idProceso,
                "",
                {ganador: true}
            );
        console.log(data, 'data')

        if (status == 200) {
          this.datosTabla = data;
        }
      }
    },
    async addGanadores() {
      if (!this.permisos.leer || this.haveRole("ROLE_PROVEEDOR")) return;

      if (this.esSubasta()) {
        await this.services.PacProcesos.getGanadorSubasta(
            this.$route.params.idProceso
        );
      }

    },
    agregarGanador() {
      let query = {};
      query = {
        from: "adjudicacion",
        tipo: "financiera",
      }

      if (this.proceso.id_forma_contratacion === 23) {
        query.subasta = 'true'
      }

      if ([14,15,16,17,19,20,21].includes(this.proceso.id_forma_contratacion)) {
        query = {
          from: "adjudicacion",
          tipo: "ofertasCombinadas",
        }
      }

      this.$router.push({
        name: "peo-nuevo-ganador",
        params: {
          id_proceso: this.$route.params.idProceso,
        },
        query
      });
    },
    async consultarActaActual() {
      const response = await this.services.PeoServices.descargarActaPanel(
          this.$route.params.idProceso,
          {id_tipo_documento: 4},
      );

      if (response.status == 200) this.actaActual = response.data;
    },
    async descargarActa() {
      try {
        const response =
            await this.services.PacProcesos.descargarDocumentoProceso({
              ruta: this.actaActual?.ruta_documento,
            });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const file = new File([blob], this.actaActual.nombre_documento, {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();
      } catch (e) {
        console.log(e);
      }
    },
    calcularCantidadAdjudicada(lotesOferta) {
      let cantidadAdjudicada = 0;
      lotesOferta.forEach((lote) => {
        lote.LotesProceso.sub_procesos.forEach((subProceso) => {
          subProceso.ggsp?.forEach((ggsp) => {
            cantidadAdjudicada += ggsp.monto;
          });
        });
      });
      return cantidadAdjudicada;
    },
    obtenerLotesAdjudicados(lotes) {
      const lotesAdjudicados = [];
      lotes.forEach((lote) => {
        lote.LotesProceso.sub_procesos.forEach((subProceso) => {
          subProceso.ggsp?.forEach((ggsp) => {
            if (!lotesAdjudicados.includes(lote.LotesProceso.nombre)) {
              lotesAdjudicados.push(lote.LotesProceso.nombre);
            }
          });
        });
      });
      return lotesAdjudicados;
    },
    confirmarEliminacionEvaluacion(participacion) {
      this.participacion = participacion;
      this.confirmacionAbierta = true;
    },
    async eliminarEvaluacion() {
      try {
        toggleLoadable(this.eliminacionLoadable);
        const response = await this.services.PeoServices.eliminarEvaluacion(
            this.$route.params.idProceso,
            this.participacion.id_participacion_proveedor
        );

        if (response.status == 200) {
        this.getGanadores();
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Registro eliminado correctamente.",
        });
      }
      } catch (error) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Error al eliminar el registro.",
        });
      } finally {
        this.confirmacionAbierta = false;
        setLoadableResponse(this.eliminacionLoadable, {},  {
          skipOnSuccess: true,
          dontShowError: true,
        });
      }
    },
    async guardarActaAdjudicacion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("documento", this.actaAdjudicacion);
        formData.append("nombre", "Acta de adjudicación");
        formData.append("id_proceso", this.$route.params.idProceso);
        formData.append("id_tipo_documento", 10000);
        formData.append("folder", "procesos/adjudicacion");
        formData.append("disk", "local");

        const response =
            await this.services.PacProcesos.guardarDocumentosProceso(formData);

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado correctamente",
          });
          this.actaAdjudicacion = null;
          await this.obtenerActasAdjudicacion();
          this.editActaAdjudicacion = false;
          this.$v.$reset();
        }
      }
    },
    async obtenerActasAdjudicacion() {
      const response =
          await this.services.PacProcesos.obtenerDocumentosProceso(
              this.$route.params.idProceso,
              10000
          );

      if (response.status === 200) {
        this.actaAdjudicacionActual = response.data[0];
      }
    },
    async descargarActaAdjudicacion() {
      const response =
          await this.services.PacProcesos.descargarDocumentoProceso({
            ruta: this.actaAdjudicacionActual.ruta_documento,
          });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File(
          [blob],
          this.actaAdjudicacionActual.nombre_documento,
          {
            type: response.headers["content-type"],
          }
      );

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },
    esSubasta() {
      // this.proceso?.EtapaPorProcesos[9]?.id_tipo_etapa === 9
      const etapaSubasta = this.proceso?.EtapaPorProcesos.filter(
          (item) => item.id_tipo_etapa === 9
      );
      return etapaSubasta.length > 0;
    },
  },
  async created() {
    await this.addGanadores();
    await this.getGanadores();
    await this.consultarActaActual();
    await this.obtenerActasAdjudicacion();
  },
};
</script>
