import { http_client_with_status } from "@/plugins/http_client";

const cargarDocumentos = (idAnioFiscal, idInstitucion) =>
  http_client_with_status(
    `/api/v1/reporteria-ucp/documentos/${idAnioFiscal}/${idInstitucion}`,
    "get"
  );

const visualizarDocumento = (idDocumento) =>
  http_client_with_status(
    `/api/v1/reporteria-ucp/documentos/${idDocumento}`,
    null,
    "get",
    {},
    { responseType: "blob" }
  );

export default {
  cargarDocumentos,
  visualizarDocumento,
};
