<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center" style="gap: 16px">
          <NavButton />
          <h2 class="text-h6 text-uppercase font-weight-bold">
            Centros escolares
          </h2>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-tabs v-model="tab" show-arrows>
          <v-tab v-for="t in tabs" :key="t.id">
            <v-icon size="28px !important" class="mr-2">
              {{ t.icon }}
            </v-icon>
            {{ t.title }}
          </v-tab>
          <v-tab-item v-for="t in tabs" :key="t.id">
            <div style="background-color: white" class="px-4 py-4">
              <component :is="t.component" />
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import NavButton from "@/components/utils/NavButton.vue";
import Compras from "./components/centrosEscolares/Compras.vue";
import Fondos from "./components/centrosEscolares/Fondos.vue";
import Registros from "./components/centrosEscolares/Registros.vue";

export default {
  name: "ReporteCentrosEscolares",
  components: {
    NavButton,
  },
  computed: {
    tabs() {
      return [
        {
          id: 1,
          icon: "mdi-shopping",
          title: "Compras",
          component: Compras,
        },
        {
          id: 2,
          icon: "mdi-currency-usd",
          title: "Fondos",
          component: Fondos,
        },
        {
          id: 3,
          icon: "mdi-playlist-check",
          title: "Registros",
          component: Registros,
        },
      ];
    },
  },
  data: () => ({
    tab: 0,
  }),
};
</script>
