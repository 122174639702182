<template>
  <v-dialog v-model="isOpen" max-width="1300px" persistent>
    <v-card>
      <v-card-title class="justify-space-between align-center">
        <v-btn
          style="
            min-width: unset !important;
            min-height: unset !important;
            width: 32px !important;
            height: 32px !important;
          "
          class="pt-0"
          @click.stop="cerrarModal"
          text
          rounded
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-col>
        <v-card class="px-2 py-2">
          <v-card-title class="d-flex align-center">
            <v-icon class="mr-2">mdi-information-outline</v-icon>
            <span class="font-weight-bold mr-2"
              >Información de solicitudes de compra
            </span>
          </v-card-title>
        </v-card>
        <v-data-table
          class="mt-2"
          :headers="headers"
          :items="solicitudCompra.data"
          :server-items-length="+totalSolicitudCompra"
        >
          <template v-slot:item.solicitud_compra="{ item }">
            {{ item.id }}</template
          >
          <template v-slot:item.institucion="{ item }">
            {{
              item.ProcesoCompra.Institucion
                ? item.ProcesoCompra.Institucion.nombre
                : "-"
            }}</template
          >
          <template v-slot:item.fecha_creacion="{ item }">
            {{
              item.created_at
                ? moment(item.created_at).format("DD/MM/YYYY h:mm a")
                : "-"
            }}
          </template>
          <template v-slot:item.fecha_autorizacion="{ item }">
            {{
              item.fecha_autorizacion
                ? moment(item.fecha_autorizacion).format("DD/MM/YYYY h:mm a")
                : "-"
            }}
          </template>
          <template v-slot:item.expiracion_carrito="{ item }">
            {{
              item.expiracion_carrito
                ? moment(item.expiracion_carrito).format("DD/MM/YYYY h:mm a")
                : "-"
            }}
          </template>
          <template v-slot:item.enviada_proveedor="{ item }">
            <v-chip
              label
              small
              class="text-uppercase font-weight-bold"
              :color="item.autorizada ? 'green' : 'red'"
              text-color="white"
            >
              {{ item.autorizada ? "Si" : "No" }}
            </v-chip>
          </template>
          <template v-slot:item.descartada="{ item }">
            <v-chip
              label
              small
              class="text-uppercase font-weight-bold"
              :color="item.descartada ? 'green' : 'red'"
              text-color="white"
            >
              {{ item.descartada ? "Si" : "No" }}
            </v-chip>
          </template>
          <template v-slot:item.expirada="{ item }">
            <v-chip
              label
              small
              class="text-uppercase font-weight-bold"
              :color="item.expirada ? 'green' : 'red'"
              text-color="white"
            >
              {{ item.expirada ? "Si" : "No" }}
            </v-chip>
          </template>
          <template v-slot:item.vencida="{ item }">
            <v-chip
              label
              small
              class="text-uppercase font-weight-bold"
              :color="item.vencida ? 'green' : 'red'"
              text-color="white"
            >
              {{ item.vencida ? "Si" : "No" }}
            </v-chip>
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-btn
              color="secondary"
              icon
              @click.stop="direccionarAlDetalleSolicitudCompra(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-card-text>
        <div
          class="d-flex justify-center align-center"
          style="position: sticky; bottom: 0; background: white; gap: 16px"
        >
          <v-btn
            class="flex-grow-1 flex-shrink-1 mt-4"
            color="secondary"
            large
            @click.stop="cerrarModal"
          >
            Cerrar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import { Fragment } from "vue-fragment";
import ProgresiveLoadAutocomplete from "@/components/utils/ProgresiveLoadAutocomplete.vue";
import { EmptyComponent } from "@/components/utils";

export default {
  name: "CmModalDetalleSolicitud",
  components: {
    ConfirmationModalComponent,
    ProgresiveLoadAutocomplete,
    Fragment,
    EmptyComponent,
  },
  props: {
    isOpen: { type: Boolean },
    solicitudCompra: { type: Object },
  },
  emits: ["cerrar"],
  data: () => ({
    headers: [
      {
        align: "center",
        sortable: false,
        text: "Número de solicitud",
        value: "solicitud_compra",
      },
      {
        align: "center",
        sortable: false,
        text: "Institucion",
        value: "institucion",
      },
      {
        align: "center",
        sortable: false,
        text: "Fecha autorización",
        value: "fecha_autorizacion",
      },
      {
        align: "center",
        sortable: false,
        text: "Fecha creacion",
        value: "fecha_creacion",
      },
      {
        align: "center",
        sortable: false,
        text: "Expiración carrito",
        value: "expiracion_carrito",
      },
      {
        align: "center",
        sortable: false,
        text: "Enviada a el proveedor",
        value: "enviada_proveedor",
      },
      {
        align: "center",
        sortable: false,
        text: "Descartada",
        value: "descartada",
      },
      {
        align: "center",
        sortable: false,
        text: "Expirada",
        value: "expirada",
      },
      {
        align: "center",
        sortable: false,
        text: "Vencida",
        value: "vencida",
      },
      { align: "center", sortable: false, text: "Acciones", value: "acciones" },
    ],
  }),
  computed: {
    sinInformacionSolicitudCompra() {
      return !this.solicitudCompra.data?.length > 0;
    },
    totalSolicitudCompra() {
      return this.solicitudCompra.data.length ?? 0;
    },
  },
  methods: {
    direccionarAlDetalleSolicitudCompra(item) {
      const route = this.$router.resolve({
        name: "herramientas-catalogo-electronico",
        query: {
          id_solicitud_compra: item.id,
        },
      });

      window.open(route.href, "_blank");
    },
    cerrarModal() {
      this.$emit("cerrar");
    },
  },
};
</script>
