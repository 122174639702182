<script>
import DataTableComponent from '@/components/DataTableComponent.vue';

export default {
  components: {
    DataTableComponent
  },
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    series: [],
    options: {},
    headers: [
      { text: 'Nombre', value: 'nombre', align: 'center', sortable: false },
      { text: 'Cantidad', value: 'total', align: 'center', sortable: false },
    ],
    showModal: false,
    tableData: []
  }),
  methods: {
    async showTable() {
      this.tableData = this.chartData.chartOptions.labels.map((label, index) => ({
        nombre: label,
        total: this.chartData.series[index]
      }));
      this.showModal = true;
    }
  }
};
</script>

<template>
  <v-col class="col-md-6 col-12">
    <v-card class="chart-card" style="height: 100%">
      <v-card-title class="d-flex justify-space-between">
        <span class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
          {{ chartData?.title }}
        </span>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click.stop="showTable" v-bind="attrs" v-on="on">mdi-table-eye</v-icon>
          </template>
          <span>Ver Tabla de Datos</span>
        </v-tooltip>
      </v-card-title>
      <v-divider />
      <v-card-text class="chart-card-scroller">
        <div class="chart-card-content">
          <apexchart v-if="chartData" :options="chartData?.chartOptions" :series="chartData?.series" />
        </div>
      </v-card-text>
    </v-card>

    <!-- Modal con la DataTable -->
    <v-dialog v-model="showModal" max-width="700px">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          {{ chartData?.title }}
          <v-btn icon @click="showModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DataTableComponent :headers="headers" :items="tableData" :tiene_paginacion="false" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>