<template>
  <ProgresiveLoadAutocomplete
    v-bind="autocompleteProps"
    v-model="institucion"
    :pageable="instituciones"
    :loading="instituciones.isLoading"
    class="flex-grow-1 flex-shrink-1"
    style="min-width: 200px"
    label="Institución"
    placeholder="Seleccione una institución"
    itemText="nombre"
    itemValue="id"
    hide-details
    @search="buscarInstitucion"
    @load-more="cargarMasInstituciones"
    clearable
  />
</template>
<script>
import {
  createPageable,
  togglePageable,
  setPageableResponse,
  isResponseSuccesful,
} from "@/utils/loadable";
import ProgresiveLoadAutocomplete from "../utils/ProgresiveLoadAutocomplete.vue";

export default {
  components: { ProgresiveLoadAutocomplete },
  props: {
    value: { type: [String, Number, Object] },
    listener: { type: Object, required: true },
    eventoReinicio: { type: String, required: true },
    autocompleteProps: { type: Object },
    soloInstitucionesPadre: { type: Boolean },
    excluirCentrosEscolares: { type: Boolean, default: true },
  },
  data: () => ({
    institucion: null,
    instituciones: createPageable([], 30),
    paginacion: {
      pagina: 1,
      registrosPorPagina: 30,
    },
    busqueda: null,
  }),
  methods: {
    async buscarInstitucion(termino) {
      if (this.busqueda === termino) return;

      this.busqueda = termino;
      this.instituciones.data = [];
      this.paginacion.pagina = 1;

      this.cargarInstituciones();
    },
    cargarMasInstituciones() {
      const { page, per_page, total_rows } = this.instituciones.pagination;
      const currentlyLoaded = page * per_page;

      if (!(currentlyLoaded < total_rows)) return;

      this.paginacion.pagina = page + 1;
      this.paginacion.registrosPorPagina = per_page;

      this.cargarInstituciones();
    },
    async cargarInstituciones() {
      const filtros = {
        pagination: true,
        per_page: this.paginacion.registrosPorPagina,
        page: this.paginacion.pagina,
        busqueda: this.busqueda,
        excluir_centros_escolares: this.excluirCentrosEscolares,
        ...(this.soloInstitucionesPadre && { solo_instituciones_padre: true }),
      };

      togglePageable(this.instituciones);
      const { data, headers } =
        await this.services.BajaCuantia.cargarInstituciones(filtros);

      const copiaInstituciones = this.instituciones.data;
      setPageableResponse(this.instituciones, data, headers, {
        skipOnSuccess: true,
        persistDataOnError: true,
      });

      if (!isResponseSuccesful(data)) {
        const { pagina } = this.paginacion;
        this.paginacion.pagina = pagina > 1 ? pagina - 1 : 1;
        return;
      }

      this.instituciones.data = copiaInstituciones.concat(data.data);
    },
    manejarEventos(evento) {
      if (evento !== this.eventoReinicio) return;

      this.busqueda = null;
      this.paginacion.pagina = 1;
      this.instituciones = createPageable([], 30);
      this.cargarInstituciones();
    },
  },
  watch: {
    institucion(value) {
      this.$emit("update:value", value);
    },
    value: {
      handler(value) {
        this.institucion = value ?? null;
      },
      immediate: true,
    },
  },
  created() {
    this.cargarInstituciones();
    this.listener.setHandler(this.manejarEventos);
  },
};
</script>
