<template>
  <v-dialog v-model="abrirModalDetalle" persistent max-width="1200">
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center mb-4">
        <v-icon class="mr-2">mdi-information-slab-circle</v-icon>
          Detalle del contrato y sus etapas
      </v-card-title>
      <v-divider class="mb-5" />
      <v-card-text>
        <div >
          <v-card-title>
            <v-icon class="mr-2"> mdi-newspaper-variant</v-icon>
            Información relacionada al contrato
          </v-card-title>
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Correlativo de contrato</p>
              <p> {{ dataContratoDetalle?.numero }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Número interno</p>
              <p> {{ dataContratoDetalle?.numero_interno }}</p>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Nombre</p>
              <p>{{ dataContratoDetalle?.nombre_contrato }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Fecha y hora de contratación</p>
              <p>
                {{ moment(dataContratoDetalle?.fecha_hora_contratacion).format("DD/MM/YYYY hh:mm a") }}
              </p>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="3">
              <p class="mb-0 font-weight-bold">Monto adjudicado</p>
              <div class="d-flex justify-center" v-if="isEditing">
                <money-text-field-component
                  v-model="editedMonto"
                  dense
                  type="number"
                  :error-messages="cantidadErrors"
                  @blur="$v.editedMonto.$touch()"
                ></money-text-field-component>
                <v-btn
                  icon
                  color="primary"
                  @click="saveMonto"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <p>$
                  {{
                    Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(dataContratoDetalle?.monto_adjudicado)
                  }}
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="isEditing"
                label="Editar adjudicación"
                class="ml-5"
                :disabled="!archivoSubido"
              ></v-switch>
            </v-col>

            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Plazo contractual</p>
              <p>{{ dataContratoDetalle?.plazo_contractual }}</p>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Tipo de contrato</p>
              <p>{{ dataContratoDetalle?.tipo_contrato }} </p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Estado de contrato</p>
              <p>{{ dataContratoDetalle?.estado_contrado }}</p>
            </v-col>
          </v-row>

        </div>
      </v-card-text>
      <v-divider class="mb-5" />
      <v-card-title class="mb-3 ml-3">
        <v-icon class="mr-2"> mdi-format-list-bulleted-type</v-icon>
          Etapas del contrato 
      </v-card-title>
      <div v-if="hasDataEtapa">
        <data-table-component
        :headers="headers"
        :items="dataEtapa"
        :inhabilitar_paginacion="true"
        :tiene_paginacion="false"
        >
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip label :color="getColor(item.completado)">
              {{ item.completado === true ? "Entregada" : "Pendiente" }}
            </v-chip>
          </template>
          <template v-slot:[`item.fecha_inicio`]="{ item }">
              {{ item.fecha_fin ? moment(item.fecha_inicio).format("DD/MM/YYYY hh:mm a") : 'Fecha no registrada.' }}
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
              {{ item.fecha_fin ? moment(item.fecha_fin).format("DD/MM/YYYY hh:mm a") : 'Fecha no registrada.' }}
          </template>
          <template v-slot:[`item.cantidad_pagar`]="{ item }">
            $
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.cantidad_pagar)
            }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="abrirModalComplementoEtapaById(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2" 
                  >
                  mdi-information-slab-circle
                </v-icon>
              </template>
              <span> Ver detalle de etapa</span>
            </v-tooltip>
  
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="abrirModalEliminarEtapa(item)"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!archivoSubido"
                  >
                  mdi-delete-circle
                </v-icon>
              </template>
              <span> Eliminar etapa </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </div>
      <div class="d-flex justify-center" v-else>
        <p class="mb-0 font-weight-bold">El contrato aún no posee etapas registradas</p>
      </div>
      <v-card-actions class="d-flex justify-center mt-6">
        <v-btn class="primary mr-4" @click.stop="limpiarYCerrar"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
    
    <modalComplementoEtapa
    :abrirModal="modal_complemento_etapa"
    :dataDetalle="dataDetalleEtapaComplemento"
    :archivo-subido="archivoSubido"
    @cerrar="CerrarModal"
    />

    <ConfirmationDialog
      :show="modal_eliminar"
      title="Desea eliminar la etapa selecionada?"
      message="El cambio es irreversible, eliminará toda la información relacionada a la etapa"
      btnConfirmar="Continuar"
      @close="modal_eliminar = false"
      @confirm="eliminarEtapaById"
    />
  </v-dialog>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import moment from "moment";
import modalComplementoEtapa from "./modalComplementoEtapa.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import {required} from "vuelidate/lib/validators";

export default {
  name: "modalDetalleEtapa",
  components: {
    DataTableComponent,
    modalComplementoEtapa,
    ConfirmationDialog,
    MoneyTextFieldComponent,
  },

  validations: {
    editedMonto: {
      required,
      },
  },

  props: {
    abrirModalDetalle: {
      type: Boolean,
      default: false,
    },
    dataEtapa: {
      type: Array,
      default: () => [],
    },
    dataContratoDetalle: {
      type: Object,
      default: () => ({}),
    },
    archivoSubido: {
      type: Boolean,
      default: false,
    },
    idProceso: {
      type: Number,
    },
  },

  data: () => ({
    modal_complemento_etapa: false,
    dataDetalleEtapaComplemento: [],
    modal_eliminar: false,
    id_etapa_selected: null,
    // Editar monto contratado
    editedMonto: null,
    isEditing: false,
    headers: [
      {
        text: "Nombre etapa",
        value: "etapa",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
      },
      {
        text: "Cantidad a pagar",
        value: "cantidad_pagar",
        align: "center",
      },
      {
        text: "Posible fecha recepción",
        value: "fecha_inicio",
        align: "center",
      },
      {
        text: "Fecha recepción",
        value: "fecha_fin",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
  }),
  computed: {
    hasDataEtapa() {
      return this.dataEtapa && this.dataEtapa.length > 0;
    },
    cantidadErrors() {
      const errors = [];
      if (!this.$v.editedMonto.$dirty) return errors;
      !this.$v.editedMonto.required &&
      errors.push("Campo requerido");
      !this.$v.editedMonto.$model>0 &&
      errors.push("Campo debe ser mayor a 0");
      return errors;
    },
  },
  methods: {
    limpiarYCerrar() {
      this.clearInputs(); // Limpia los inputs
      this.$emit('cerrar'); // Emite el evento
    },
    getColor(status_name) {
      const status_color = status_name === true ? 'Entregada' : 'Pendiente'
      switch (status_color) {
        case "Pendiente":
          return "pendiente";
        case "Entregada":
          return "vigente";
      }
    },
    async abrirModalComplementoEtapaById(item) {
      this.dataDetalleEtapaComplemento = item;
      this.modal_complemento_etapa = true;
    },
    CerrarModal() {
      this.modal_complemento_etapa = false;
    },

    async abrirModalEliminarEtapa(item) {
      this.id_etapa_selected = item;
      this.modal_eliminar = true;
    },

    async eliminarEtapaById() {
      const id_etapa = this.id_etapa_selected.id;
      const id_contrato = this.dataContratoDetalle.id;
      const { data } = await this.services.EliminarEtapasContratoServices.eliminarEtapaContratoById(this.idProceso, id_etapa, id_contrato);
      if (data) {
        this.temporalAlert({
          show: true,
          message: data.message,
          type: "success",
        });
        this.modal_eliminar = false;
        this.$emit('cerrarModalActualizar')
      }
    },
    async saveMonto() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.temporalAlert({
          message: "Debe modificar el monto adjudicado.",
          show: true,
          type: "warning",
        });
        return false;
      }

      let body = {
        monto_original: this.dataContratoDetalle.monto_adjudicado,
        monto_editado: this.editedMonto,
      };

      const id_contrato = this.dataContratoDetalle.id;
      const { data, status } = await this.services.EliminarEtapasContratoServices.modificarMontoContractual(
        this.idProceso,
        id_contrato,
        body,
      );

      if (status === 200) {
        this.temporalAlert({
          message: data.message,
          show: true,
          type: "success",
        });

        this.clearInputs();
        this.$emit('actualizarModalAdjudicado');
      }
    },
    clearInputs() {
      this.isEditing = false;
      this.editedMonto = null;
    },
  },
};
</script>
