export class LocalStorage {

    static get token_procesos_libres() {
        return localStorage.getItem("token_procesos_libres");
    }

    static set token_procesos_libres(valor) {
        if (!valor) {
            localStorage.removeItem("token_procesos_libres");
        } else {
            localStorage.setItem("token_procesos_libres", valor);
        }
    }

    static get token() {
        return localStorage.getItem("token");
    }

    static set token(valor) {
        if (!valor) {
            localStorage.removeItem("token");
        } else {
            localStorage.setItem("token", valor);
        }
    }

    static get fecha_ultima_accion() {
        return localStorage.getItem("fecha_ultima_accion");
    }

    static set fecha_ultima_accion(valor) {
        if (!valor) {
            localStorage.removeItem("fecha_ultima_accion");
        } else {
            localStorage.setItem("fecha_ultima_accion", valor);
        }
    }
}