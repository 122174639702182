var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Fragment',[(
      _vm.moment().isAfter(_vm.item.fecha_hora_final) &&
      _vm.moment().isAfter(_vm.item.fecha_hora_inicio) &&
      _vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO']) &&
      _vm.hayGanador
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.item.acciones_pendientes[0]?.adjudicado === false)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.adjudicar(_vm.item.id)}}},[_c('v-icon',[_vm._v("mdi-info")]),_c('span',{staticClass:"mx-2"},[_vm._v("Generar adjudicación")])],1):(
          _vm.item.acciones_pendientes[0]?.adjudicado === true &&
          _vm.item.acciones_pendientes[0].notificado === false
        )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.enviarCorreos(_vm.item.id)}}},[_c('v-icon',[_vm._v("mdi-info")]),_c('span',{staticClass:"mx-2"},[_vm._v("Enviar notificaciones de correo")])],1):_c('v-alert',{staticClass:"success"},[_vm._v(" Ya se ha realizado la adjudicación y notificación por correo con respecto a esta subasta ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('detalleSubasta',{attrs:{"item":_vm.item}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('subastaGanador',{attrs:{"id":_vm.item.id,"finalizada":_vm.moment().isAfter(_vm.item.fecha_hora_final)},on:{"detalleGanador":_vm.cambiarSiHayGanador}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('detalleLote',{attrs:{"id_lote":_vm.item.id_lote}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('historialPujasComponent',{attrs:{"id":_vm.item.id,"porcentual":[
            'resta_porcentaje_publicidad',
            'resta_porcentaje',
            'suma_porcentaje',
          ].includes(_vm.item?.tipo)}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }