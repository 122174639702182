<template>
  <v-row justify="center">
    <v-dialog
        v-model="value"
        content-class="elevation-0"
        max-width="1000"
        persistent
    >
      <section>
        <v-card height="auto">
          <v-card-title class="text-h5 primary--text text-center">
            <v-card-title>
              Inhabilitar proveedor
            </v-card-title>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <vc-date-picker
                    locale="es"
                    mode="date"
                    :model-config="modelConfig"
                    :popover="config_calendar"
                    :value="form.fecha_inicio"
                    @input="updateFechaInicio"
                >
                  <template v-slot="{ inputEvents }">
                    <v-text-field
                        :value="form.fecha_inicio"
                        class="border roundend focus:outline-none focus:border-blue-300"
                        label="Fecha de inicio *"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="inputEvents"
                        :error-messages="fechaInicioErrors"
                        @change="$v.form.fecha_inicio.$touch()"
                        @blur="$v.form.fecha_inicio.$touch()"
                    />
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col cols="6">
                <vc-date-picker
                    locale="es"
                    mode="date"
                    :model-config="modelConfig"
                    :popover="config_calendar"
                    :value="form.fecha_fin"
                    @input="updateFechaFin"
                >
                  <template v-slot="{ inputEvents }">
                    <v-text-field
                        :value="form.fecha_fin"
                        class="border roundend focus:outline-none focus:border-blue-300"
                        label="Fecha de fin *"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="inputEvents"
                        :error-messages="fechaFinErros"
                        @change="$v.form.fecha_fin.$touch()"
                        @blur="$v.form.fecha_fin.$touch()"
                    />
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    label="Detalle de sanción *"
                    :error-messages="detalleErros"
                    @change="$v.form.detalle.$touch()"
                    @blur="$v.form.detalle.$touch()"
                    v-model="form.detalle"
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                    outlined
                    v-model="form.id_institucion"
                    label="Institución *"
                    :items="selects.institucion"
                    item-text="nombre"
                    item-value="id"
                    clearable
                    :disabled="!tieneRolActualizar"
                    :error-messages="idInstitucionErros"
                    @change="$v.form.id_institucion.$touch()"
                    @blur="$v.form.id_institucion.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                    outlined
                    label="Tipo de sanción *"
                    :items="selects.tipo_sancion"
                    item-text="nombre"
                    item-value="id"
                    v-model="form.id_tipo_sancion"
                    clearable
                    :error-messages="idInstitucionErros"
                    @change="$v.form.id_tipo_sancion.$touch()"
                    @blur="$v.form.id_tipo_sancion.$touch()"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex gap-1 mx-2 pb-5">
            <v-btn color="secondary" @click="cerrarModal">Cancelar</v-btn>
            <v-btn color="primary" @click="guardar">Inhabilitar</v-btn>
          </v-card-actions>
        </v-card>
      </section>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { Validator } from '@/utils/form-validation.js';


export default {
  name: "AppDialog",
  props: {
    title: String,
    showClose: {
      type: Boolean,
      default: true,
    },
    value: Boolean,
    maxWidth: {
      type: Number,
      default: 600,
    },
    classButtons: {
      type: String,
      default: "d-flex justify-start",
    },
    id_temp_proveedor: {
      type: Number || null,
      default: null,
    },
  },
  validations() {
    return {
      form: {
        fecha_inicio: { required },
        fecha_fin: { required },
        id_institucion: { required },
        id_tipo_sancion: { required },
        detalle: { required },
      },
    };
  },
  data() {
    return {
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      config_calendar: {
        visibility: "click",
      },
      form: {
        fecha_inicio: null,
        fecha_fin: null,
        id_institucion: null,
        id_tipo_sancion: null,
        detalle: null,
      },
      selects: {
        institucion: [],
        tipo_sancion: [],
      },
      tieneRolActualizar: false,
    }
  },
  computed:{
    fechaInicioErrors() {
      return new Validator(this.$v.form.fecha_inicio).detect().getResult();
    },
    fechaFinErros() {
      return new Validator(this.$v.form.fecha_fin).detect().getResult();
    },
    idInstitucionErros() {
      return new Validator(this.$v.form.id_institucion).detect().getResult();
    },
    idTipoSancionErros() {
      return new Validator(this.$v.form.id_tipo_sancion).detect().getResult();
    },
    detalleErros() {
      return new Validator(this.$v.form.detalle).detect().getResult();
    }
  },
  methods: {
    async guardar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.temporalAlert({
          show: true,
          message: "Debe completar los campos para poder continuar",
          type: "error",
        });
        return;
      }
      this.form.id_proveedor = this.id_temp_proveedor;
      let formulario = {
        fecha_inicio: this.form.fecha_inicio,
        fecha_fin: this.form.fecha_fin,
        id_institucion: this.form.id_institucion.id,
        id_tipo_sancion: this.form.id_tipo_sancion,
        id_proveedor : this.id_temp_proveedor,
        detalle_sancion: this.form.detalle,
      }
      await this.services.Sanciones.postInhabilitarProveedor(formulario);
      this.$emit('EventoInhabilitarProveedor');
      this.cerrarModal();
    },
    updateFechaInicio(value) {
      this.form.fecha_inicio = value;
    },
    updateFechaFin(value) {
      this.form.fecha_fin = value;
    },
    async cargarSelects () {
      this.verificarRol()
      const { data } =
          await this.services.Sanciones.getInstitucionUsuarioCatalogo();
      this.selects.institucion = data.instituciones
      this.form.id_institucion = data.instituciones[0]
      const { data: sanciones } = await this.services.Sanciones.obtenerTiposSanciones();
      this.selects.tipo_sancion = sanciones
    },
    verificarRol() {
      this.tieneRolActualizar = this.haveRole(
          "ROLE_RUPES_ACTUALIZAR_SANCION_PROVEEDORES_REGISTRADOS"
      );
    },
    limpiarModal() {
      this.$v.$reset();
      this.form = {
        fecha_inicio: null,
        fecha_fin: null,
        id_institucion: null,
        id_tipo_sancion: null,
        detalle: null,
      };
    },
    cerrarModal() {
      this.limpiarModal();
      this.$emit('input', false)
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.description {
  padding: 0 50px 0 50px;
  font-size: 18px;
}

.actions-buttons button {
  margin-right: 30px;
}

.btn-close-custom {
  position: absolute;
  top: 10px;
  left: 15px;
}
</style>
