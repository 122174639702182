<template>
  <!-- Técnica:   10,12 -->
  <!-- Económica: 4,5 -->
  <!-- Combinada: 11,13,20 -->
  <div class="d-flex">
    <v-btn v-if="[4, 5].includes(tipoEtapa) || [11, 13, 20].includes(tipoEtapa)"
           @click="(idTipoOferta = 2), (showModalAgregar = true)" dark class="mr-4" color="secondary">
      Agregar oferta económica
    </v-btn>
    <v-btn v-if="[10, 12].includes(tipoEtapa) || [11, 13, 20].includes(tipoEtapa)"
           @click="(idTipoOferta = 1), (showModalAgregar = true)" dark color="secondary">
      Agregar oferta técnica
    </v-btn>
    <app-dialog :max-width="1200" v-model="showModalAgregar" title="Agregar oferta de proveedor" @close="closeModal">
      <template #content>
        <div class="d-flex justify-space-between align-center">
          <p v-if="proveedor_no_registrado">
            Digite el número de documento del proveedor no registrado a invitar
          </p>
          <p v-else>
            Digite el nombre comercial de la empresa a la que se desea invitar
          </p>
          <v-switch
              v-if="validarSwitch.includes(procesoData?.id_forma_contratacion ?? null)"
              v-model="proveedor_no_registrado"
              inset
              :label="`Proveedor ${proveedor_no_registrado ? 'no registrado' : 'registrado'}`"
              :color="proveedor_no_registrado ? 'warning' : 'tableAccent'"
              @change="vaciar"
          ></v-switch>
        </div>
        <!-- Autocomplete para buscar proveedores -->
        <div v-if="!proveedor_no_registrado">
          <v-row>
            <v-col cols="12">
              <v-autocomplete clearable :items="lista_proveedores_OTRO" outlined item-text="nombre_completo"
                              return-object
                              :search-input.sync="nombre_comercial_model" label="Nombre comercial"
                              @keyup="getListadoProveedores"
                              v-model="proveedor_model"/>
            </v-col>
          </v-row>
          <v-row v-if="proveedor_model != null">
            <v-col cols="12">
              <InformacionProvedor :providerData="proveedor_model"/>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-text-field v-model="busqueda" outlined label="Buscar Proveedor..." @keyup="debounceSearch"/>
          <div>
            <v-row v-if="mostrarProveedor">
              <v-col cols="12" sm="6">
                <v-text-field outlined label="Correlativo" readonly v-model="proveedor.correlativo"/>
                <v-text-field outlined label="Nombre comercial" readonly v-model="proveedor.nombre_comercial"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field outlined label="Número de documento" readonly v-model="proveedor.numero_documento"/>
                <v-text-field outlined label="Tipo Documento" readonly v-model="proveedor.tipo_documento"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="crearProveedor && !mostrarFormulario">
                <v-btn color="secondary" @click="mostrarForm" dark>
                  Crear Proveedor
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="mostrarFormulario">
              <v-col cols="12" sm="4">
                <v-text-field outlined label="Nombre comercial" v-model="form.nombre_comercial"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field outlined label="Número de documento" v-model="form.numero_documento"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete :items="selects.tipoDocumento"
                                item-text="nombre" item-value="id"
                                outlined label="Tipo Documento" no-data-text="Sin datos..."
                                v-model="form.tipo_documento"/>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>

      <template #actions>
        <div class="mt-3 ml-3">
          <v-btn color="secondary" @click="closeModal" class="mr-4" outlined>
            Cerrar
          </v-btn>
          <v-btn v-if="proveedor_model != null" color="secondary" dark @click="agregarProveedor"
                 :loading="loadingProveedor">
            Agregar
          </v-btn>
          <v-btn v-if="mostrarFormulario || mostrarProveedor" @click="guardarProveedor" color="secondary" dark>
            {{ mostrarFormulario ? 'Crear Proveedor' : 'Seleccionar Proveedor' }}
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";
import {debounce} from 'lodash';

export default {
  name: "OfertaUCPComponent",
  components: {
    InformacionProvedor,
  },
  props: {
    tipoEtapa: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    form: {
      nombre_comercial: null,
      numero_documento: null,
      tipo_documento: null,
    },
    selects: {
      tipoDocumento: [],
    },
    validarSwitch: [13, 22, 24],
    crearProveedor: false,
    mostrarProveedor: false,
    mostrarFormulario: false,
    showModalAgregar: false,
    loadingProveedor: false,
    idTipoOferta: null,
    proveedor_no_registrado: false,
    proveedor: {
      id: null,
      nombre_comercial: null,
      numero_documento: null,
      correlativo: null,
      tipo_documento: null,
    },
    busqueda: null,
  }),
  computed: {
    ...mapState("procesoCompra", [
      "lista_proveedores",
      "nombre_comercial",
      "proveedor_invitar",
    ]),
    ...mapState("procesoCompraDoc", ["procesoData"]),

    proveedor_model: {
      get() {
        return this.proveedor_invitar;
      },
      set(value) {
        this.setProveedor(value);
      },
    },
    nombre_comercial_model: {
      get() {
        return this.nombre_comercial;
      },
      set(value) {
        this.setNombreComercial(value);
      },
    },
    lista_proveedores_OTRO: function () {
      return this.lista_proveedores.map((proveedor) => ({
        ...proveedor,
        nombre_completo: `${proveedor.nombre_comercial} - ${proveedor.nombre}`,
      }));
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getListadoProveedores",
      "setNombreComercial",
      "setProveedor",
      "invitarProveedor",
    ]),

    async agregarProveedor() {
      try {
        this.loadingProveedor = true;
        const params = {
          correo: this.proveedor_invitar.email,
        };
        const response = await this.services.Paac.postInvitarProveedor(
            this.$route.params.idProceso,
            params
        );

        if (response.status == 200) {
          this.proveedor_model = null;

          this.showModalInvitar = false;
          //redirect to provider's offer page

          this.$router.push({
            name: "pac-procesos-ofertas",
            params: {
              idProceso: this.$route.params.idProceso,
              idTipoOferta: this.idTipoOferta,
              idParticipacionProveedor:
              response.data?.participacion_proveedor?.id,
            },
          });
        }
      } finally {
        this.loadingProveedor = false;
      }
    },
    closeModal() {
      this.showModalAgregar = false;
      this.resetModal();
    },
    resetModal() {
      this.nombre_comercial_model = '';
      this.proveedor_model = null;
      this.lista_proveedores_OTRO = [];
      this.proveedor = {
        id: null,
        nombre_comercial: null,
        numero_documento: null,
        correlativo: null,
        tipo_documento: null,
      };
      this.busqueda = '';
      this.crearProveedor = false;
      this.mostrarProveedor = false;
      this.mostrarFormulario = false;
      this.proveedor_no_registrado = false;

    },
    async buscarProveedor() {
      const {data} = await this.services.ProveedorNoRegistradoServices.getProveedorNoRegistrado({busqueda: this.busqueda});
      if (!data) {
        this.crearProveedor = true;
        this.mostrarProveedor = false;
        this.temporalAlert({
          show: true,
          message: "No se encontraron resultados",
          type: "warning",
        });
        return
      } else {
        this.crearProveedor = false;
      }
      data !== null ? this.mostrarProveedor = true : this.mostrarProveedor = false;
      if (data !== null) {
        this.proveedor.id = data.id;
        this.proveedor.nombre_comercial = data.nombre_comercial;
        this.proveedor.numero_documento = data.numero_documento;
        this.proveedor.correlativo = data.correlativo;
        this.proveedor.tipo_documento = data.td.nombre;
      }
    },
    vaciar() {
      this.proveedor_model = null;
      this.nombre_comercial_model = '';
      this.mostrarProveedor = false;
      this.busqueda = '';
      this.proveedor = {
        id: null,
        nombre_comercial: null,
        numero_documento: null,
        correlativo: null,
        tipo_documento: null,
      };
      this.crearProveedor = false;
      this.mostrarFormulario = false;
    },
    async mostrarForm() {
      const {data} = await this.services.Documentos.getDocumentTypeList({
        id_categoria_documento: 1,
      });
      this.selects.tipoDocumento = data;
      this.mostrarFormulario = true;
    },
    async guardarProveedor(){
      let id_proveedor = null;

      if(this.mostrarFormulario) {
        const { data: { id_proveedor_no_registrado } } = await this.services.ProveedorNoRegistradoServices.postProveedorNoRegistrado(this.form);
        id_proveedor = id_proveedor_no_registrado;
      }else if (this.mostrarProveedor) {
        id_proveedor = this.proveedor.id;
      }
      const { data: { id_participacion } } = await this.services.ProveedorNoRegistradoServices.particparProveedorNoRegistrado({
        id_proveedor_no_registrado: id_proveedor,
        id_proceso: this.$route.params.idProceso,
      })
      this.closeModal();
      this.$router.push({
        name: "pac-procesos-ofertas",
        params: {
          idProceso: this.$route.params.idProceso,
          idTipoOferta: this.idTipoOferta,
          idParticipacionProveedor: id_participacion
        },
      });
    }
  },
  created() {
    this.debounceSearch = debounce(this.buscarProveedor, 1000);
  }
};
</script>
