<template>
    <v-row align="stretch">
      <v-col :cols="sizes.cols" :md="sizes.md" :lg="sizes.lg">
        <v-card style="height: 100%">
          <v-card-title>
            <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
              <v-icon class="mr-1" style="vertical-align: middle;">mdi-check-decagram</v-icon>
              Monto certificado {{ por_institucion}}
            </p>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <div>
              <span class="text-caption text-capitalize">monto</span>
              <br/>
              <span class="text-h5 font-weight-bold">{{ toMoneyFormat(totales?.monto_proyectado_necesidades) }}</span>
            </div>
            <div class="mt-4">
              <span class="text-caption text-capitalize">ahorro total</span>
              <br/>
              <span class="text-h5 font-weight-bold">
                {{ toMoneyFormat(totales?.monto_proyectado_necesidades - totales?.monto_ganado) }}
              </span>
            </div>
            <div class="mt-4">
              <span class="text-caption text-capitalize">Porcentaje</span>
              <br/>
              <span class="text-h5 font-weight-bold">
                {{ calcularPorcentaje(totales?.monto_proyectado_necesidades - totales?.monto_ganado, totales?.monto_proyectado_necesidades) }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
  
      <v-col :cols="sizes.cols" :md="sizes.md" :lg="sizes.lg">
        <v-card style="height: 100%">
          <v-card-title>
            <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
              <v-icon class="mr-1" style="vertical-align: middle;">mdi-format-list-numbered</v-icon>
              Monto proyectado por lotes {{ por_institucion}}
            </p>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <div>
              <span class="text-caption text-capitalize">monto proyectado</span>
              <br/>
              <span class="text-h5 font-weight-bold">
                {{ toMoneyFormat(totales?.monto_proyectado_especificas) }}
              </span>
            </div>
            <div class="mt-4">
              <span class="text-caption text-capitalize">ahorro total</span>
              <br/>
              <span class="text-h5 font-weight-bold">
                {{ toMoneyFormat(totales?.monto_proyectado_especificas - totales?.monto_ganado) }}
              </span>
            </div>
            <div class="mt-4">
              <span class="text-caption text-capitalize">Porcentaje</span>
              <br/>
              <span class="text-h5 font-weight-bold">
                {{ calcularPorcentaje(totales?.monto_proyectado_especificas - totales?.monto_ganado, totales?.monto_proyectado_especificas) }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
  
      <v-col :cols="sizes.cols" :md="sizes.md" :lg="sizes.lg">
        <v-card style="height: 100%">
          <v-card-title>
            <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
              <v-icon class="mr-1" style="vertical-align: middle;">mdi-cash</v-icon>
              total ejecutado {{ por_institucion}}
            </p>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <div>
              <span class="text-caption text-capitalize">monto total</span>
              <br/>
              <span class="text-h5 font-weight-bold">
                {{ toMoneyFormat(totales?.monto_ganado) }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
  
      <v-col v-if="tieneFiltroProveedor" :cols="sizes.cols" :md="sizes.md" :lg="sizes.lg">
        <v-card style="height: 100%">
          <v-card-title>
            <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
              <v-icon class="mr-1" style="vertical-align: middle;">mdi-account</v-icon>
              Proveedor seleccionado:
            </p>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <div>
              <span class="text-caption text-capitalize">total adjudicado</span>
              <br/>
              <span class="text-h5 font-weight-bold">
                {{ toMoneyFormat(listado.reduce((acc, item) => acc + item.monto, 0)) }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
  
    </v-row>
  </template>
  <script>
  import {mapActions, mapState} from 'vuex';
  
  export default {
    name: 'Estadisticas',
    data: () => ({
      totales: null
    }),
    computed: {
      ...mapState('subastaInversaReporteria', ['filtros', 'listado', 'cargando', 'get_global_subasta', 'cargandoTotales']),
      tieneFiltroProveedor() {
        return !!this.filtros.proveedor;
      },
      sizes() {
        return {
          cols: 12,
          md: 6,
          lg: this.tieneFiltroProveedor ? 3 : 4,
        };
      },
      por_institucion() {
        return this.filtros.id_institucion ? ' Por Institución' : '';
      }
    },
    methods: {
      ...mapActions('subastaInversaReporteria', ['obtenerTotales']),
      toMoneyFormat(value) {
        if (this.cargandoTotales) return 'Cargando...';
  
        if (!value || Number.isNaN(value)) {
          return '$0.00';
        }
  
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      },
      calcularPorcentaje(porcion, total) {
        if (this.cargandoTotales) return 'Cargando...';
  
        const porcentaje = 100 * porcion / total;
  
        if (Number.isNaN(porcentaje)) {
          return '0%';
        }
  
        return `${porcentaje.toFixed(2)} %`;
      },
    },
    watch: {
      get_global_subasta: {
        handler() {
          this.totales = this.get_global_subasta
        },
        deep: true,
      },
    },
    async created() {
      await this.obtenerTotales();
    },
  }
  </script>
  