import {
  http_client_with_status,
  http_client_slim,
} from "@/plugins/http_client";

const client = http_client_slim("api/v1/reporteria-autorizacion-pac");

const cargarEstadisticas = (filtros) =>
  http_client_with_status(
    `/api/v1/reporteria-autorizacion-pac/estadisticas`,
    filtros,
    "get"
  );

const cargarDocumentos = (filtros) =>
  http_client_with_status(
    `/api/v1/reporteria-autorizacion-pac/documentos`,
    filtros,
    "get"
  );

const visualizarDocumento = (idDocumento) =>
  http_client_with_status(
    `/api/v1/reporteria-autorizacion-pac/documentos/${idDocumento}`,
    null,
    "get",
    {},
    { responseType: "blob" }
  );

const descargarExcel = (filtros) =>
  client("/documentos/excel", { data: filtros, responseType: "blob" });

export default {
  cargarEstadisticas,
  cargarDocumentos,
  visualizarDocumento,
  descargarExcel,
};
