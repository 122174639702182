<template>
  <section>
    <span class="text-h6 primary--text"> PAC </span>
    <p class="my-0">Ejercicio {{ anioFiscal }}</p>

    <div class="mt-10 mb-4 d-flex flex-wrap flex-md-nowrap" style="gap: 16px">
      <v-btn
        color="secondary"
        dark
        :to="{ name: 'creacion-procesos-pac' }"
        style="flex-basis: 225px"
        class="flex-grow-1 flex-shrink-1 flex-md-grow-0"
      >
        Agregar proceso
      </v-btn>
      <v-btn
        v-if="mostrarBotonCargarDocPac"
        @click.stop="modalCargarDocsAnioFiscalPacAbierta = true"
        color="primary"
        style="flex-basis: 225px"
        class="flex-grow-1 flex-shrink-1 flex-md-grow-0"
      >
        Subir documento
      </v-btn>
      <div class="d-flex align-center">
        <v-progress-circular
          v-if="aprobacionDocAnioFiscal.isLoading"
          indeterminate
          color="primary"
          :size="18"
          :width="3"
        />
        <template v-if="aprobacionDocAnioFiscal.data?.aprobado">
          <v-icon color="green">mdi-check-circle</v-icon>
          <span class="font-weight-bold ml-1 text-uppercase"
            >Documento aprobado</span
          >
        </template>
      </div>
    </div>

    <v-row align="center">
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          v-model="filters.nombre"
          label="Código / Nombre de proceso"
          outlined
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-autocomplete
          v-model="filters.id_anio_fiscal"
          :items="listadoanios"
          item-text="anio"
          item-value="anio"
          label="año fiscal"
          outlined
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-autocomplete
          v-model="filters.id_modalidad"
          :items="listadoModalidades"
          item-text="nombre"
          item-value="id"
          label="Forma de contratación"
          outlined
          hide-details
        />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col cols="7" class="mx-0 pr-0">
            <v-select
              :items="columnasList"
              hide-details
              item-value="value"
              item-text="label"
              label="Columna"
              outlined
              v-model="col_filter"
            />
          </v-col>
          <v-col cols="5" class="mx-0 pl-0">
            <v-select
              :items="orderByList"
              hide-details
              label="Ordenar por"
              outlined
              :disabled="!col_filter"
              v-model="order_filter"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <div
          class="d-flex align-center flex-wrap flex-md-nowrap"
          style="gap: 16px"
        >
          <v-btn
            outlined
            color="secondary"
            class="flex-shrink-1 flex-grow-1 flex-md-grow-0"
            style="width: 100%"
            @click="clearFiltros()"
            x-large
          >
            Limpiar
          </v-btn>
          <v-btn
            dark
            color="secondary"
            class="flex-shrink-1 flex-grow-1 flex-md-grow-0"
            style="width: 100%"
            @click="(page = 1), filterProcesos()"
            x-large
          >
            Buscar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <p class="mt-10">Listado de solicitudes</p>
    <data-table-component
      :headers="headers"
      :items="listadoProcesos"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      :total_registros="filters.total_rows"
      @paginar="paginar"
    >
      <template #[`item.fecha_inicio`]="{ item }">
        {{ moment(item.fecha_inicio).format("MMMM / YYYY") }}
      </template>
      <template #[`item.fecha_solicitado`]="{ item }">
        {{ moment(item.fecha_solicitado).format("DD/MM/YYYY") }}
      </template>
      <template #[`item.monto_programado`]="{ item }">
        <span>
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto_programado)
          }}
        </span>
      </template>
      <template v-slot:[`item.estado_proceso`]="{ item }">
        <v-chip
          v-if="item.id_proceso_compra"
          style="display: block"
          label
          class="bgWhite--text"
          :color="colorProceso(item.id_seguimiento_proceso)"
        >
          {{ item?.nombre_seguimiento_proceso }}
        </v-chip>
        <v-chip
          v-else
          style="display: block"
          label
          class="bgWhite--text"
          color="error"
        >
          Proceso eliminado
        </v-chip>
      </template>
      <template #[`item.acciones`]="{ item }">
        <!-- TODO: Agregar show y edit de procesos -->

        <v-tooltip top v-if="validarEdicion(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="secondary"
              @click="
                $router.push({
                  name: 'creacion-procesos-pac',
                  params: { idProceso: item.id },
                })
              "
            >
              mdi-pencil
            </v-icon>
          </template>
          <span> Editar </span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.id_anio_fiscal_pac == null"
              v-bind="attrs"
              v-on="on"
              color="secondary"
              @click="(showDialog = true), (solicitud_seleccionada = item)"
            >
              mdi-file-document-plus
            </v-icon>
          </template>
          <span> Agregar a PAC </span>
        </v-tooltip>

        <v-tooltip top v-if="validarEliminar(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="secondary"
              @click="eliminarProcesoPAC(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </data-table-component>
    <dialogoConfirmacion
      :show="showDialog"
      title="¿Deseas agregar a PAC esta solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="showDialog = false"
      @confirm="enviarPAC"
    />
    <ModalCargaDocsAnioFiscalPac
      v-if="mostrarBotonCargarDocPac"
      :is-open="modalCargarDocsAnioFiscalPacAbierta"
      :aniosFiscales="aniosFiscales"
      @on-visibility-change="manejarVisibilidadModalCargarDocsAnioFiscalPac"
      @on-action="revalidarEstadoAprobacionDocAnioFiscal"
    />
  </section>
</template>

<script>
import ModalCargaDocsAnioFiscalPac from "./components/ModalCargaDocsAnioFiscalPac.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue";
import dialogoConfirmacion from "../../components/ConfirmationDialogComponent.vue";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from "@/utils/loadable";

export default {
  name: "listadoProcesos",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
    ModalCargaDocsAnioFiscalPac,
  },
  data() {
    return {
      showDialog: false,
      listadoanios: [],
      listadoModalidades: [],
      solicitud_seleccionada: null,
      headers: [
        {
          text: "Código",
          align: "start",
          class: "secondary bgWhite--text",
          value: "correlativo",
          sortable: false,
        },
        {
          text: "Proceso",
          align: "start",
          class: "secondary bgWhite--text",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Modalidad de compra",
          align: "start",
          class: "secondary bgWhite--text",
          value: "forma_contratacion",
          sortable: false,
        },
        {
          text: "Mes programado",
          align: "start",
          class: "secondary bgWhite--text",
          value: "fecha_inicio",
          sortable: false,
        },
        {
          text: "Fecha solicitado",
          value: "fecha_solicitado",
          class: "secondary bgWhite--text",
          align: "start",
          sortable: false,
        },
        {
          text: "Monto planificado",
          value: "monto_programado",
          class: "secondary bgWhite--text",
          align: "center",
          sortable: false,
        },
        {
          text: "Solicitudes pendientes de ajuste",
          value: "total_pendientes",
          class: "secondary bgWhite--text",
          align: "center",
          sortable: false,
        },
        {
          text: "Solicitudes ajustadas",
          value: "total_aprobado",
          class: "secondary bgWhite--text",
          align: "center",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado_proceso",
          class: "secondary bgWhite--text",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          class: "secondary bgWhite--text",
          align: "center",
          sortable: false,
        },
      ],
      columnasList: [
        {
          value: "mes_programado",
          label: "Mes programado",
        },
        {
          value: "fecha_solicitado",
          label: "Fecha solicitado",
        },
        {
          value: "monto_programado",
          label: "Monto programado",
        },
      ],
      orderByList: ["ASC", "DESC"],
      col_filter: null,
      order_filter: null,
      // Subida de docs
      modalCargarDocsAnioFiscalPacAbierta: false,
      aniosFiscales: [],
      aniosFiscalesCargando: false,
      // Verificacion docs
      aprobacionDocAnioFiscal: createLoadable(null),
    };
  },
  computed: {
    ...mapState(["anioFiscal"]),
    ...mapState("pac", ["listadoProcesos", "filters"]),
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    mostrarBotonCargarDocPac() {
      return this.haveRoles(["ROLE_CARGAR_DOC_PAC_ANIO_FISCAL_VIEW"]);
    },
  },
  methods: {
    ...mapActions("pac", ["getPacProcesos"]),
    ...mapMutations("pac", ["setFiltros"]),
    ...mapMutations("procesoCompra", ["setCorrelativoProceso"]),
    validarEdicion(item) {
      if ([1, 11].includes(item.id_seguimiento_proceso)) return true;

      if (item.id_seguimiento_proceso === 3) return true;

      return false;
    },
    validarEliminar(item) {
      return item.id_seguimiento_proceso === 1;
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getPacProcesos();
    },
    async filterProcesos() {
      if (this.col_filter && this.order_filter)
        this.filters.ordenar_por = [this.col_filter, this.order_filter];
      await this.getPacProcesos();
    },
    clearFiltros() {
      this.filters.nombre = null;
      this.col_filter = null;
      this.order_filter = null;
      this.filters.id_modalidad = null;
      this.filters.id_anio_fiscal = null;
      this.ordenar_por = [];
      this.getPacProcesos();
    },
    colorProceso(id_estado_proceso) {
      switch (id_estado_proceso) {
        case 1:
          return "#8EBCE1";
        case 2:
          return "#4578D5";
        case 14:
          return "info";
        default:
          return "secondary";
      }
    },
    showConfigOptions(id_estado_proceso) {
      switch (id_estado_proceso) {
        case 1:
          return true;
        case 2:
          return true;
        case 3:
          return true;
        default:
          return false;
      }
    },
    mostrar(item) {
      switch (item?.id_estado_proceso) {
        case 2:
          this.$router.push({
            name: "resumen-procesos",
            params: { id_proceso: item.id },
          });
          break;
        case 6:
          this.setCorrelativoProceso(item.correlativo);
          this.$router.push({
            name: "seguimiento-contratos",
            params: { id_proceso: item.id },
          });
          break;
      }
    },
    config(item) {
      this.$router.push(
        `/configuracion-proceso-paac/${item.id_proceso_compra}`
      );
    },
    async enviarPAC() {
      const { status } = await this.services.PacServices.enviarProcesoPAC(
        this.solicitud_seleccionada.id,
        { anio: this.anioFiscal }
      );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: `Proceso enviado a PAC con éxito`,
        });
        this.getPacProcesos();
        this.showDialog = false;
      }
    },
    manejarVisibilidadModalCargarDocsAnioFiscalPac(visible) {
      this.modalCargarDocsAnioFiscalPacAbierta = visible;
    },
    async cargarAniosFiscales() {
      this.aniosFiscalesCargando = true;
      try {
        const { data } = await this.services.PacProcesos.cargarAniosFiscales();
        this.aniosFiscales = data;
      } catch (error) {
      } finally {
        this.aniosFiscalesCargando = false;
      }
    },
    async cargarDocumentosAnioFiscalPac() {
      togglePageable(this.documentosAnioFiscal);
      const { data, headers } =
        await this.services.DocumentosAniosFiscalServices.cargarDocumentos();
      setPageableResponse(this.documentosAnioFiscal, data, headers);
    },
    async obtenerAniosFiscales() {
      const { data } = await this.services.PacServices.listadoAniosFiscales({
        editar_pac: true,
      });
      this.listadoanios = data;
    },
    async obtenerModalidades() {
      const { data } = await this.services.Paac.getModalidades();
      this.listadoModalidades = data?.forma_contratacion;
    },
    async verificarAprobacionDocAnioFiscal(anio) {
      toggleLoadable(this.aprobacionDocAnioFiscal);
      const { data } =
        await this.services.DocumentosAniosFiscalServices.verificarAprobacionDocAnioFiscal(
          { id_anio_fiscal: anio }
        );
      setLoadableResponse(this.aprobacionDocAnioFiscal, data);
    },
    revalidarEstadoAprobacionDocAnioFiscal() {
      if (!this.anioFiscal) return;

      this.verificarAprobacionDocAnioFiscal(this.anioFiscal);
    },
    async eliminarProcesoPAC(item) {
      await this.services.PacServices.eliminarProcesoPAC(item.id);
      await this.getPacProcesos();
    },
  },
  watch: {
    anioFiscal: {
      handler(value) {
        if (!value) return;
        this.verificarAprobacionDocAnioFiscal(value);
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.setFiltros({
      page: 1,
      per_page: 10,
      total_rows: null,
      paginacion: "true",
    });
    await this.cargarAniosFiscales();
    await this.getPacProcesos();
    await this.obtenerAniosFiscales();
    await this.obtenerModalidades();
  },
};
</script>

<style></style>
