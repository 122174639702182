<template>
  <section>
    <div class="d-flex align-center pr-2 justify-end mb-2" style="gap: 16px" v-if="esUCP && !soloLectura">
        <v-btn
            v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])"
            color="primary" 
            class="mr-4"
            :disabled="contratoGenerado"
            @click.stop="modalGarantias = true"
          >
          <v-icon class="white--text mr-2">mdi mdi-file-document-plus-outline</v-icon>
          Garantías
        </v-btn>

        <v-btn 
          color="primary" 
          class="mr-4"
          @click="abrirModalDocumentos"
        >
        <v-icon class="white--text mr-2">mdi mdi-file-document-plus-outline</v-icon>
        Cargar nuevo documento
      </v-btn>
    </div>
    <EmptyComponent :empty="processWithNoDocuments">
      <p v-if="documentsLoading" class="pb-0 mb-4 text-center">Cargando documentos...</p>
        <ArbolDocumentosProceso :arbol="list_docs_var">
          <template v-slot:documentos="{ documentos, tipo }">
            <DataTableComponent
              class="my-4"
              :headers="HEADERS_CONST"
              :items="documentos"
              item-key="id"
              :tiene_paginacion="false"
              dense
              no-gutters
            >
              <!-- Slot de acciones -->
              <template v-slot:[`item.user`]="{ item }">
                <template v-if="item['Usuario?.Empleado.Persona.nombre'] && item['Usuario?.Empleado.Persona.apellido']">
                  {{ item['Usuario?.Empleado.Persona.nombre'] }} {{ item['Usuario?.Empleado.Persona.apellido'] }}
                </template>
                  <span v-else>-</span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <div class="d-flex justify-center align-center" style="gap: 8px;">
                  <v-btn icon @click="DownloadFileFtn(item)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn v-if="showDeleteButton" icon @click="confirmFileDeletion(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            <!-- Template de columna de visibilidad a proveedor -->
            <template v-slot:[`item.visibilidad_proveedor`]="{ item }">
              <template v-if="!item.hasOwnProperty('visibilidad_proveedor')">
                <span>-</span>
              </template>
              <template v-else-if="esAuditoriaProceso">
                <span>{{ item.visibilidad_proveedor ? 'Visible' : 'No visible' }}</span>
              </template>
              <template v-else>
                <template v-if="tipo === 'Entrega de solicitud de oferta' || tipo === 'Entrega de solicitud de propuesta'">
                  <span>-</span>
                </template>
                <template v-else>
                  <v-switch
                    :disabled="soloLectura"
                    color="success"
                    :ripple="false"
                    v-model="item.visibilidad_proveedor"
                    @change="cambiarVisibilidad($event, item.id)"
                    @click.stop
                  >
                    <template #prepend>
                      <v-label class="pl-6">{{ item.visibilidad_proveedor ? 'Visible' : 'No visible' }}</v-label>
                    </template>
                  </v-switch>
                </template>
              </template>
            </template>

            <template v-slot:[`item.publico`]="{ item }">
              <span v-if="item.update_publico">{{ moment(item.update_publico).format("DD/MM/YYYY hh:mm A") }} </span>
              <span v-else>-</span>
            </template>

            <template v-slot:[`item.privado`]="{ item }">
              <span v-if="item.update_privado">{{ moment(item.update_privado).format("DD/MM/YYYY hh:mm A") }} </span>
              <span v-else>-</span>
            </template>

            <template v-slot:[`item.enviar_correo`]="{ item }">
              <template v-if="!item.hasOwnProperty('visibilidad_proveedor')">
                <span>-</span>
              </template>
              <template v-else>
                <v-btn icon @click="confirmarEnvioDocumento(item)">
                  <v-icon>mdi mdi-file-send</v-icon>
                </v-btn>
              </template>
            </template>
            
            <template v-slot:[`item.proceso_publico`]="{ item }">
              <v-switch
                color="success"
                :ripple="false"
                v-model="item.proceso_publico"
                @change="cambiarEstado($event, item.id)"
                @click.stop
                >
                <template #prepend>
                  <v-label class="pl-6">{{ item.proceso_publico ? 'Público' : 'Privado' }}</v-label>
                </template>
              </v-switch>
            </template>
            </DataTableComponent>
          </template>
        </ArbolDocumentosProceso>
    </EmptyComponent>
    <ConfirmationModalComponent 
      :is-open="confirmationOpen"
      :is-loading="deleteLoading"
      description="¿Desea eliminar este documento?"
      @confirm="handleFileDelete" 
      @cancel="confirmationOpen = false"
    />
    <ConfirmationModalComponent 
      :is-open="confirmarEnvio"
      :is-loading="enviarDocumento.isLoading"
      :description="`¿Desea enviar el documento ${nombreDocumento} para notificarlo de su publicación por correo electrónico?`"
      @confirm="confirmarEnvioCorreo" 
      @cancel="confirmarEnvio = false"
    />
    <ModalCargarDocumentos
      :is-open="modalDocumento"
      @close="modalDocumento = false"
    />

    <ModalGarantias
      :is-open="modalGarantias"
      @close="modalGarantias = false"
      :ganadores="ganadores"
    />

    <ConfirmationModalComponent
      :isOpen="modalEstadoPublico" 
      description="¿Desea continuar con la acción?"
      @confirm="cambiarEstado" 
      @cancel="cerrarModalCambioEstado"
      :isLoading="cargandoEstado.isLoading" 
    />
  </section>
</template>

<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent, EmptyComponent, SpinnerComponent } from '@/components/utils';
import ArbolDocumentosProceso from '@/views/ProcesoCompraDoc/components/ArbolDocumentosProceso.vue';
import ModalCargarDocumentos from '@/views/ProcesoCompraDoc/components/ModalCargarDocumentos.vue'
import DataTableComponent from '@/components/DataTableComponent.vue';
import { Listener, Observable } from "@/utils/observable";
import ModalGarantias from "./ModalGarantias.vue";
import { mapState } from "vuex";
import moment from 'moment';

export default {
  components: { ConfirmationModalComponent, EmptyComponent, SpinnerComponent, ModalCargarDocumentos, ModalGarantias, DataTableComponent, ArbolDocumentosProceso },
  props: {
    procesoData: { type: Object },
    proceso: { type: Object },
    soloLectura: { type: Boolean }
  },
  data: () => ({
    // constantes
    HEADERS_CONST: [],
    GarantiasHeaders: [],

    modalEstadoPublico: false,
    documentsLoading: false,
    proceso_publico: false,
    tecnico_asignado: false,
    rolJefeUcp: false,
    visibilidad_proveedor: false,
    cargandoVisibilidad: createLoadable([]),
    enviarDocumento: createLoadable([]),
    nombreDocumento: null,
    cargandoEstado: createLoadable([]),
    documentos: createLoadable([]),
    list_docs: [],
    list_docs_var: [],
    list_docs_ofer: [],
    idsExcluidos: [5001, 59, 73, 83, 210, 235, 236, 260, 53, 67, 205, 222, 60, 74, 84, 211, 227, 15007, 
    240, 243, 246, 58, 72, 82, 209, 226, 5001, 197, 104, 132, 180, 60, 74, 84, 211, 227, 168, 217, 158, 
    233, 181, 135, 154, 194, 119, 101],
    // Eliminacion de documentos
    confirmationOpen: false,
    confirmarEnvio: false,
    activeDocument: null,
    deleteLoading: false,
    modalDocumento: false,
    modalGarantias: false,
    ganadores: [],
  }),
  computed: {
    // ...mapState("procesoCompraDoc", ["ganadores"]),
    showDeleteButton() {
      return this.haveRole("ROLE_ELIMINACION_DOCUMENTOS_PROCESO_VIEW");
    },
    processWithNoDocuments() {
      return !this.documentsLoading && this.list_docs_var.length === 0;
    },
    esUCP() {
      return this.haveRoles(["ROLE_UACI","ROLE_UACI_TECNICO"]);
    },
    esAuditoriaProceso() {
      return this.haveRole("ROLE_AUDITORIA_PROCESO");
    },
    propiedadUnificada(){
      return this.proceso ?? this.procesoData;
    },
    esGarantia(){
      return this.list_docs_var['Garantías'] ? this.list_docs_var['Garantías'].length > 0 : false
    },
    contratoGenerado(){
      return !this.ganadores?.some(ganador => ganador);
    }
  },
  methods: {
    abrirModalDocumentos(){
      this.modalDocumento = true;
    },
    async DownloadFileFtn(item) {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento, 
          disk: item.disk,

        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", item.nombre_documento);
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },

    async FetchListDocs() {
      try {
        this.documentsLoading = true;
        const RESP_SC_CONST = await this.services.PacProcesos.getListDocuments(
          this.$route.params.idProceso
        );
        if (RESP_SC_CONST) {
          const { data } = RESP_SC_CONST;
          this.list_docs_var = data?.documentos ?? [];
          this.tecnico_asignado = data.empleado_asignado;
          this.setHeadersBasedOnRole();
        }

        if(this.haveRole('ROLE_AUDITOR_INTERNO_AUIN')){
          const titulosDocumentos = Object.keys(this.list_docs_var);
          const titulosMostrar = titulosDocumentos.filter((titulo)=> {
            const documentos = this.list_docs_var[titulo];
            return !documentos.some((documento) => this.idsExcluidos.includes(documento.id_tipo_documento));
          });

          const resultadoFinal = {};
          titulosMostrar.forEach((titulo) => resultadoFinal[titulo] = this.list_docs_var[titulo]);
          this.list_docs_ofer = resultadoFinal;

          this.list_docs = this.propiedadUnificada?.fecha_adjudicacion ? this.list_docs_var : this.list_docs_ofer;
        } else {
          this.list_docs = this.list_docs_var;
        }

      } catch (error) {
        this.pushAppMessage({ message: 'Error al cargar los documentos.', type: 'error' });
      } finally {
        this.documentsLoading = false;
      }
    },
    confirmFileDeletion(document) {
      this.activeDocument = document;
      this.confirmationOpen = true;
    },
    async handleFileDelete() {
      this.confirmationOpen = false;
      try {
        this.deleteLoading = true;
        const response = await this.services.PacProcesos.deleteDocumentoProceso(this.activeDocument.id);

        if (response.status === 200) {
          this.pushAppMessage({ message: 'Documento eliminado correctamente.', type: 'success' });
          this.FetchListDocs();
        }

      } catch (error) {
        this.pushAppMessage({ message: 'Error al eliminar el documento', type: 'error' });
      } finally {
        this.deleteLoading = false;
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm A");
    },
    //Cambio de estado para visibilidad del proveedor en los documentos
    async cambiarVisibilidad(visibilidad_proveedor, documentoId) {
      toggleLoadable(this.cargandoVisibilidad);
      const { data } = await this.services.PacProcesos.estadoDocumentoProveedor(documentoId, { visibilidad_proveedor });
      
      setLoadableResponse(this.cargandoVisibilidad, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.modalEstadoPublico = false;
      
      if (!isResponseSuccesful(data)) return;
    },

    async enviarCorreoDocumento(documentoId) {
      toggleLoadable(this.enviarDocumento);
      const idProceso =  this.$route.params.idProceso
      const { data } = await this.services.PacProcesos.enviarDocumentoProveedor(documentoId, idProceso);
      
      setLoadableResponse(this.enviarDocumento, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.modalEstadoPublico = false;
      
      if (!isResponseSuccesful(data)) return;
    },

    confirmarEnvioDocumento(documento){
      this.documentoId = documento.id;
      this.nombreDocumento = documento.nombre_documento;
      // Permitir que los documentos que sean Entrega de solicitud de oferta puedan ser enviados sin que tenga habilitado el swtich de dar visibilidad al proveedor
      if(!documento.visibilidad_proveedor &&  ![53,67,205,222].includes(documento.id_tipo_documento) ) return this.temporalAlert({
          show: true,
          message: "Se debe habilitar la visibilidad al proveedor para enviar la notificación",
          type: "error",
      });
      this.confirmarEnvio = true;
    },

    async confirmarEnvioCorreo() {
      await this.enviarCorreoDocumento(this.documentoId);
      this.confirmarEnvio = false;
    },

    validarRol() {
      if (this.haveRole('ROLE_UACI')) {
        this.rolJefeUcp = true
      }
    },
    //Headers
    setHeadersBasedOnRole() {
      if (this.tecnico_asignado || this.rolJefeUcp || this.esAuditoriaProceso) {
        this.HEADERS_CONST = [
          {
            align: 'center',
            text: "Documento",
            value: "nombre_documento",
          },
          {
            align: 'center',
            text: "Subido por",
            value: "user",
          },
          {
            align: 'center',
            text: "Fecha de subida",
            value: "created_at",
          },
          {
            align: 'center',
            text: "Acciones",
            value: "acciones",
            sortable: false,
          },
          {
            align: 'center',
            text: "Visibilidad de proveedor",
            value: "visibilidad_proveedor",
          },
          // {
          //   align: 'center',
          //   text: "Proceso publico",
          //   value: "proceso_publico",
          // },
        ];
        if (this.esAuditoriaProceso) {
          this.HEADERS_CONST.push({
            align: 'center',
            text: "Última vez publicado",
            value: "publico",
          });
          this.HEADERS_CONST.push({
            align: 'center',
            text: "Última vez en privado",
            value: "privado",
          });
        }
        if (!this.esAuditoriaProceso && !this.soloLectura) {
          this.HEADERS_CONST.push({
            align: 'center',
            text: "Enviar documento al proveedor",
            value: "enviar_correo",
          });
        }
      } else {
        this.HEADERS_CONST = [
          {
            align: 'center',
            text: "Documento",
            value: "nombre_documento",
          },
          {
            align: 'center',
            text: "Fecha de subida",
            value: "created_at",
          },
          {
            align: 'center',
            text: "Acciones",
            value: "acciones",
            sortable: false,
          },
        ];
      }
    },
    cambiarEstadoPublico(){
      this.modalEstadoPublico = true;
    },

    async cambiarEstado(proceso_publico, documentoId) {
      toggleLoadable(this.cargandoEstado);
      const { data } = await this.services.ProcesosPublicos.actualizarEstadoDocumentoPublico(documentoId, { proceso_publico });
      setLoadableResponse(this.cargandoEstado, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.modalEstadoPublico = false;
      
      if (!isResponseSuccesful(data)) return;
    },

    async listarGanadores(){
      const { data } = await this.services.PacProcesos.obtenerGanadores(this.$route.params.idProceso);
      this.ganadores = data;
    },
    cerrarModalCambioEstado(){
      this.modalEstadoPublico = false;
    },
  },
  created() {
    this.listarGanadores();
    this.FetchListDocs();
    this.validarRol();
  },
  mounted() {
    const listener = new Listener('documentos').setHandler(() => this.FetchListDocs());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-documentos', listener);
  }
};
</script>

<style>
</style>