<template>
    <v-card v-if="isLoading" style="height: 100%;">
        <span class="d-block px-4 py-4">
            <v-skeleton-loader type="text" width="33%" />
        </span>
        <v-divider />
        <span class="d-block px-4 py-4">
            <v-skeleton-loader type="heading" />

            <v-row no-gutters dense align="center">
                <v-col cols="12" md="7">
                    <div 
                        class="mt-4 mx-auto text-center" 
                        style="width: 50%; aspect-ratio: 1 / 1; border-radius: 50%; overflow: hidden; position: relative;"
                    >
                        <v-skeleton-loader 
                            class="mt-4"
                            type="image"
                            style="transform: scale(10.5);"
                        />
                    </div>
                </v-col>
                <v-col cols="12" md="5">
                    <v-skeleton-loader v-for="index in Array.from({ length: 5 })" :key="index" type="text" class="mb-2" />
                </v-col>
            </v-row>
        </span>
    </v-card>

    <v-card v-else style="height: 100%;">
        <v-card-title class="px-4 py-4">
            <span class="text-body-2 font-weight-bold text-uppercase primary--text">
                {{ titulo }}
            </span>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 py-4">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="text-h5 font-weight-bold secondary--text">
                        {{ toMoneyFormat(sumatoria, false) }}
                    </span>
                </template>
                <span>{{ toMoneyFormat(sumatoria, false) }}</span>
            </v-tooltip>

            <div v-if="sumatoria > 0" class="mt-4">
                <apexchart type="pie" height="350" :options="options" :series="series" />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { toMoneyFormat } from '@/utils/data';
import { colores } from '@/utils/charts';

export default {
    name: 'PlantillaEstadisticaModalidad',
    props: {
        isLoading: { type: Boolean },
        titulo: { type: String },
        sumatoria: { type: [Number, String] },
        modalidades: { type: Array }
    },
    computed: {
        labels() {
            return this.modalidades.map((modalidad) => modalidad.codigo);
        },
        series() {
            return this.modalidades.map((modalidad) => Number(modalidad.monto));
        },
        options() {
            const labels = this.labels;
            const modalidades = this.modalidades;
            const sumatoria = Number(this.sumatoria);

            return ({
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels,
                colors: colores,
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                legend: {
                    enabled: true,
                    formatter: function (label) {
                        const modalidad = modalidades.find((modalidad) => modalidad.codigo === label);

                        return modalidad 
                            ? `${modalidad.codigo} (${modalidad.porcentaje.toFixed(4)}%)`
                            : label;
                    },
                },
                tooltip: {
                    enabled: true,
                    custom: function ({ series, seriesIndex, w }) {
                        const porcentaje = (series[seriesIndex] * 100) / sumatoria;
                        const labels = w.config.labels;
                        const codigoModalidad = labels[seriesIndex];

                        const modalidad = modalidades.find((modalidad) => modalidad.codigo === codigoModalidad);

                        return `
                            <div>
                                <div style="padding: 8px;">
                                    ${modalidad.nombre} (${porcentaje.toFixed(4)} %)
                                </div>
                                <hr />
                                <div style="padding: 8px;">
                                    <b>${toMoneyFormat(modalidad.monto)}</b>
                                </div>
                            </div>
                        `;
                    },
                },
            });
        },
    },
    methods: {
        toMoneyFormat,
    },
}
</script>