<template>
    <v-card>
        <template>
            <v-card-title class="px-4 py-4">
                <v-skeleton-loader v-if="isLoading" type="text" width="33%" />
                <slot v-else name="card-title"></slot>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-4 py-4">
                <v-skeleton-loader v-if="isLoading" type="heading" width="33%" />
                <slot v-else name="card-text"></slot>
            </v-card-text>
        </template>
    </v-card>
</template>
<script>
export default {
    name: 'CardEstadistica',
    props: {
        isLoading: { type: Boolean },
    },
}
</script>