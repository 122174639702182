<template>
  <div style="width: 100%; margin: 0">
    <v-col cols="12" md="6" class="mb-2">
      <v-card style="height: 100%">
        <v-card-title>
          <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
            <v-icon class="mr-1" style="vertical-align: middle;">mdi-cash</v-icon>
            Total compras
          </p>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <div>
            <span class="text-caption text-capitalize">monto total</span>
            <br/>
            <span class="text-h5 font-weight-bold">
              {{ toMoneyFormat(this.totalCompras) }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <filtros-sticky icono="mdi-filter-settings" :modo-sticky="false">
      <v-row>
        <v-col cols="12" md="4">
          <instituciones-autoComplete
            :value.sync="filtros.id_institucion"
            :evento-reinicio="eventoRestablecerFiltros"
            :listener="institucionListener"
            :autocomplete-props="{ disabled: false }"
            :excluir-centros-escolares="false"
            solo-instituciones-padre
          />
        </v-col>
        <v-col cols="12" lg="4">
          <v-menu
            ref="rangoFechas"
            v-model="rangoFechas"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="rangoFechasTexto"
                label="Desde - Hasta"
                prepend-inner-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <vc-date-picker
              title="es"
              is-range
              v-model="fechas"
              mode="dateTime"
              locale="es"
              @input="setFiltrosFechas"
            >
            </vc-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <div
            class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap"
            style="gap: 16px"
          >
            <v-btn
              color="primary"
              class="flex-grow-1 flex-shrink-1"
              x-large
              @click.stop="hacerBusquedaConFiltros"
            >
              Buscar
            </v-btn>

            <v-btn
              color="primary"
              class="flex-grow-1 flex-shrink-1"
              outlined
              x-large
              @click.stop="restablecerBusqueda"
            >
              Limpiar
            </v-btn>

            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  outlined
                  x-large
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="descargarExcel"
                >
                  <v-icon>mdi mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar a excel</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </filtros-sticky>

    <data-table-component
      class="mt-4"
      :items="registros.data"
      :show_loading="registros.isLoading"
      :headers="headers"
      :total_registros="registrosTotales"
      @paginar="manejarPaginacion"
      v-models:pagina="paginacion.pagina"
      v-models:select="paginacion.registrosPorPagina"
      dense
      no-gutters
    >
      <template v-slot:item.monto="{ item }">
        {{ toMoneyFormat(item.monto) }}
      </template>
    </data-table-component>
  </div>
</template>

<script>
import InstitucionesAutoComplete from "@/components/compartidos/InstitucionesAutoComplete.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import FiltrosSticky from "../FiltrosSticky.vue";
import { Listener, Observable } from "@/utils/observable";
import {
  createPageable,
  setPageableResponse,
  togglePageable,
} from "@/utils/loadable";
import moment from "moment";
import { toMoneyFormat } from "@/utils/data";

const filtrosIniciales = {
  id_institucion: null,
  fecha_inicio: null,
  fecha_fin: null,
};

export default {
  name: "RegistrosCentrosEscolares",
  components: {
    InstitucionesAutoComplete,
    DataTableComponent,
    FiltrosSticky,
  },
  data: () => ({
    filtros: { ...filtrosIniciales },
    rangoFechas: false,
    fechas: { start: null, end: null },
    // Listeners
    institucionListener: new Listener(),
    unsubscribes: [],
    // Eventos listeners
    eventoRestablecerFiltros:
      "RESTABLECER_INFORMACION_REPORTERIA_CENTROS_ESCOLARES",
    eventoBusquedaFiltros: "BUSCAR_INFORMACION_REPORTERIA_CENTROS_ESCOLARES",
    // Data
    paginacion: {
      pagina: 1,
      registrosPorPagina: 10,
    },
    registros: createPageable([], 10),
    totalCompras: 0,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false,
        },
        {
          text: "Centro escolar",
          value: "centro_escolar",
          align: "center",
          sortable: false,
        },
        {
          text: "Fondo utilizado",
          value: "fondo_utilizado",
          align: "center",
          sortable: false,
        },
        {
          text: "Observación",
          value: "observacion",
          align: "center",
          sortable: false,
        },
        {
          text: "Proveedor",
          value: "proveedor",
          align: "center",
          sortable: false,
        },
        {
          text: "Fecha de compra",
          value: "fecha_compra",
          align: "center",
          sortable: false,
        },
        {
          text: "Monto",
          value: "monto",
          align: "center",
          sortable: false,
        },
        {
          text: "Fecha de creación del registro",
          value: "fecha_creacion_del_registro",
          align: "center",
          sortable: false,
        },
      ];
    },
    registrosTotales() {
      return this.registros.pagination.total_rows;
    },
    rangoFechasTexto() {
      return this.fechas && this.fechas.start && this.fechas.end
        ? moment(this.fechas.start).format("D/M/Y H:mm") +
            " - " +
            moment(this.fechas.end).format("D/M/Y H:mm")
        : "Selecciona un rango de fechas";
    },
  },
  methods: {
    toMoneyFormat,
    async cargarRegistros() {
      const filtros = {
        pagination: true,
        per_page: this.paginacion.registrosPorPagina,
        page: this.paginacion.pagina,
        ...this.filtros,
      };

      togglePageable(this.registros);

      const { data, headers } =
        await this.services.reporteriaCentrosEscolares.obtenerRegistros(
          filtros
        );

      setPageableResponse(this.registros, data, headers);
    },
    async cargarTotalCompras() {
      const filtros = {
        ...this.filtros,
      };

      const { data: response } =
        await this.services.reporteriaCentrosEscolares.obtenerTotalCompras(filtros);

      this.totalCompras = response?.data?.monto_compras || 0;
    },
    async manejarPaginacion(paginacion) {
      const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
      this.paginacion = { pagina, registrosPorPagina };
      await this.cargarRegistros();
    },
    async initData() {
      await this.cargarRegistros();
      await this.cargarTotalCompras();
      Observable.getInstance().subscribe(
        this.eventoRestablecerFiltros,
        this.institucionListener
      );
    },
    async hacerBusquedaConFiltros() {
      this.paginacion.pagina = 1;
      Observable.emit(this.eventoBusquedaFiltros, { ...this.filtros });
      await this.cargarRegistros();
      await this.cargarTotalCompras();
    },
    async restablecerBusqueda() {
      this.fechas = ["", ""];
      this.filtros = { ...filtrosIniciales };
      Observable.emit(this.eventoRestablecerFiltros);
      Observable.emit(this.eventoBusquedaFiltros, { ...this.filtros });
      this.paginacion = { pagina: 1, registrosPorPagina: 10 };
      await this.cargarRegistros();
      await this.cargarTotalCompras();
    },
    setFiltrosFechas() {
      const { start, end } = this.fechas || {};
      this.filtros.fecha_inicio = start || null;
      this.filtros.fecha_fin = end || null;
    },
    async descargarExcel() {
      const filtros = {
        ...this.filtros,
        pagination: false,
      };

      const { data } =
        await this.services.reporteriaCentrosEscolares.descargarExcelRegistros(filtros);

      const blob = new Blob([data], { type: "application/vnd.ms-excel" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "registros_centros_escolares.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  async created() {
    await this.initData();
  },
};
</script>
