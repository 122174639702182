<template>
    <div>
        <v-dialog v-model="show" persistent max-width="500" min-height="500">
            <v-card>
                <v-card-title>
                    <h3>Solicitud de Registro</h3>
                    <v-spacer></v-spacer>
                    <v-btn 
                        icon
                        @click="closeModal()"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container class="p-3">
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="font-weight-bold">Fecha de Creación de Solicitud:</div>
                                <div>{{ formatDate(solicitudes.data?.fechaSolicitud) }}</div>
                            </v-col>
                            <v-col cols="12" md="6">
                                <div class="font-weight-bold">Fecha de Aprobación:</div>
                                <div>{{ formatDate(solicitudes.data?.fechaProveedor) }}</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { formatDate } from '@/utils/datetime';
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";

export default {
    name: 'SolicitudRegistroComponent',
    data() {
        return {
            solicitudes: createLoadable([]),
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        formatDate,
        async getSolicitudesAprobadas() {
            try {
                toggleLoadable(this.solicitudes);

                const filtros = {
                    pagination: false
                }

                const idUsuario = Number(this.$route.params.id_proveedor);

                const { data } = await this.services.Proveedores.getSolicitudesRegistro(idUsuario, filtros);
                setLoadableResponse(this.solicitudes, data);
            } catch (error) {
                console.log(error);
            }
        },
        closeModal() {
            this.$emit('update-show', false);
        }
    },
    created() {
        this.getSolicitudesAprobadas();
    }
}
</script>