<template>
  <v-dialog v-model="isOpen" max-width="1200px" persistent>
    <v-card>
      <v-card-title class="justify-space-between align-center">
        <v-btn
          style="
            min-width: unset !important;
            min-height: unset !important;
            width: 32px !important;
            height: 32px !important;
          "
          class="pt-0"
          @click.stop="cerrarModal"
          text
          rounded
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-col cols="12">
        <v-card-title class="d-flex align-center mb-2">
          <v-icon class="mr-2">mdi-swap-horizontal-circle-outline</v-icon>
          <span class="font-weight-bold mr-2"
            >Cambio de administrador de contrato</span
          >
        </v-card-title>
        <v-autocomplete
          class="mr-2"
          :items="administradorContrato.data"
          :loading="administradorContrato.isLoading"
          :item-text="administradorContratoCatalogo"
          item-value="empleado.usuario.id"
          v-model="form.id_usuario_contrato"
          label="Administrador de contrato"
          outlined
          light
          clearable
          :error-messages="unidadesUsuariosErrors"
          @change="$v.form.id_usuario_contrato.$touch()"
          @blur="$v.form.id_usuario_contrato.$touch()"
        >
          <template v-slot:selection="{ item }">
            <v-list-item class="px-0">
              <v-list-item-content class="py-0">
                <v-list-item-title>
                  {{ item.empleado.correo }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-card-text>
          <div
            class="d-flex justify-center align-center"
            style="position: sticky; bottom: 0; background: white; gap: 16px"
          >
            <v-btn
              class="flex-grow-1 flex-shrink-1"
              color="secondary"
              large
              @click.stop="cerrarModal"
            >
              Cerrar
            </v-btn>
            <v-btn
              class="flex-grow-1 flex-shrink-1"
              color="primary"
              large
              @click.stop="confirmarActualizarAdministrador"
            >
              Guardar
            </v-btn>
          </div>
        </v-card-text>
      </v-col>
    </v-card>
    <v-divider />
    <ConfirmationModalComponent
      :is-open="modalConfirmacionAbierta"
      description="¿Desea actualizar el administrador de contrato?"
      :is-loading="administrador.isLoading"
      @confirm="confirmarCambioAdministrador"
      @cancel="modalConfirmacionAbierta = false"
    />
  </v-dialog>
</template>

<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import { Fragment } from "vue-fragment";
import ProgresiveLoadAutocomplete from "@/components/utils/ProgresiveLoadAutocomplete.vue";
import { EmptyComponent } from "@/components/utils";
import { Validator } from "@/utils/form-validation.js";
import { required } from "vuelidate/lib/validators";
import {
  createLoadable,
  isResponseSuccesful,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";

export default {
  name: "cmModalCertificacion",
  components: {
    ConfirmationModalComponent,
    ProgresiveLoadAutocomplete,
    Fragment,
    EmptyComponent,
  },
  props: {
    isOpen: { type: Boolean },
    administradorContrato: { type: Object },
    solicitudCompra: { type: Object },
  },
  emits: ["close", "recargar-administradores"],
  validations() {
    return {
      form: {
        id_usuario_contrato: { required },
      },
    };
  },
  data: () => ({
    administrador: createLoadable(),
    modalConfirmacionAbierta: false,
    form: {
      id_usuario_contrato: null,
    },
  }),
  computed: {
    administradorContratoCatalogo() {
      return (usuariosContrato) => usuariosContrato.empleado.usuario.email;
    },
    unidadesUsuariosErrors() {
      return new Validator(this.$v.form.id_usuario_contrato)
        .detect()
        .getResult();
    },
  },
  methods: {
    confirmarActualizarAdministrador() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.modalConfirmacionAbierta = true;
    },
    async confirmarCambioAdministrador() {
      toggleLoadable(this.administrador);
      const { data } =
        await this.services.CmHerramienta.actualizarAdministradorContratoCM({
          id_administrador: this.form.id_usuario_contrato,
          id_solicitud_compra: this.solicitudCompra.id,
        });
      setLoadableResponse(this.administrador, data);
      if (isResponseSuccesful(data)) {
        this.modalConfirmacionAbierta = false;
        this.$emit("recargar-administradores", true);
        this.cerrarModal();
        this.limpiarFormulario();
      }
    },
    limpiarFormulario() {
      this.form = {};
    },
    cerrarModal() {
      this.$emit("close");
    },
  },
};
</script>
