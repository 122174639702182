import { http_client } from "../plugins/http_client"


const getProveedorNoRegistrado = async (parmas = {})=>{
    return await http_client("/api/v1/proveedor-no-registrado", parmas,"get")
}

const postProveedorNoRegistrado = async (parmas = {})=>
    await http_client("/api/v1/proveedor-no-registrado", parmas,"post")

const particparProveedorNoRegistrado = async (parmas = {})=>
    await http_client("/api/v1/proveedor-no-registrado/registrar-participacion", parmas,"post")

export default {
    getProveedorNoRegistrado,
    postProveedorNoRegistrado,
    particparProveedorNoRegistrado,
}





//Departamentos