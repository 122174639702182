const { http_client_with_status } = require("@/plugins/http_client");


const informacionCatalogo = (params = {}) => http_client_with_status(`api/v1/cm-herramienta/informacion`, params, 'get');
const ordenesCompraPorProveedor = (params = {}) => http_client_with_status(`api/v1/cm-herramienta/ordenes-compra-proveedor`, params, 'get');
const bitacoraProducto = (idProductoSolicitud, params = {}) => http_client_with_status(`api/v1/cm-herramienta/bitacora-producto/${idProductoSolicitud}`, params, 'get');
const bitacoraSolicitud = (idSolicitud, params = {}) => http_client_with_status(`api/v1/cm-herramienta/bitacora-solicitud/${idSolicitud}`, params, 'get');
const obtenerCertificacion = (idProcesoCompra, idSolicitudNecesidad, params = {}) =>
    http_client_with_status(`api/v1/cm-herramienta/certificacion/${idProcesoCompra}/${idSolicitudNecesidad}`, params, 'get');

const eliminarCertificacion = (idCertificacion, params = {}) =>
    http_client_with_status(`api/v1/cm-herramienta/eliminar-certificacion/${idCertificacion}`, params, 'delete');

const restaurarCertificacion = (idCertificacion, params = {}) =>
    http_client_with_status(`api/v1/cm-herramienta/restaurar-certificacion/${idCertificacion}`, params, 'patch');

const solicitudCompraPorProcesoCompra = (params = {}) =>
    http_client_with_status(`api/v1/cm-herramienta/informacion/solicitudes-compra`, params, 'get');

const obtenerAdministradorContratoCM = (params = {}) =>
    http_client_with_status(`api/v1/cm-herramienta/obtener-informacion/administrador-contrato`, params, 'get');

const actualizarAdministradorContratoCM = (params = {}) =>
    http_client_with_status(`api/v1/cm-herramienta/actualizar/administrador-contrato`, params, 'put');

export default {
    informacionCatalogo,
    ordenesCompraPorProveedor,
    bitacoraProducto,
    bitacoraSolicitud,
    obtenerCertificacion,
    eliminarCertificacion,
    restaurarCertificacion,
    solicitudCompraPorProcesoCompra,
    obtenerAdministradorContratoCM,
    actualizarAdministradorContratoCM
};
