import { http_client } from "../plugins/http_client"


const getMunicipiosList = async(parametros = {})=>{
    return await http_client("/api/v1/municipios", parametros,"get")
}

const getDistritosList = async(parametros = {})=>{
    return await http_client("/api/v1/municipios/distritos", parametros,"get")
}

export default{
    getMunicipiosList,
    getDistritosList
}


//Municipios