<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col 
        v-if="!soloLectura && esAlMenosUCP && !soloLecturaPorParticipacionEnComision && resoluciones.data.length > 0" 
        cols="12"
      >
        <v-card>
          <v-card-title>
            <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Reprogramación de etapas restantes</h6>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <ContinuarProceso :mostrar-encabezado="false" :id-proceso="idProceso">
              <template v-slot:header>
                <v-alert type="info" dense>Debe reprogramar todas las etapas restantes antes de continuar con el proceso.</v-alert>
              </template>
              <template v-slot:accion>
                <v-btn
                  @click="continuarProceso" 
                  color="primary" 
                  :loading="continuacionProceso.isLoading"
                  dark
                >
                  Continuar proceso
                </v-btn>
              </template>
            </ContinuarProceso>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" :lg="esAlMenosUCP ? 6 : 12">
        <div class="d-flex flex-column" style="gap: 32px">
          <!--Comisión de Alto Nivel -->
          <v-card v-if="esAlMenosUCP">
            <v-card-title>
              <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Comisión especial de alto nivel</h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-btn 
                v-if="!soloLectura && !esProveedor && !esTACOP && !soloLecturaPorParticipacionEnComision" 
                color="primary"
                class="mb-4" 
                @click="modalAgregarParticipante = true"
              >
                Agregar participante
              </v-btn>
              <DataTable 
                :headers="headersAltoNivel" 
                :items="itemsAltoN" 
                :tiene_paginacion="false" 
                dense
                no-gutters
              />
            </v-card-text>

          </v-card>
          <!--Comisión de Alto Nivel -->

          <!-- Documentos de nombramiento -->
          <v-card v-if="esAlMenosUCP">
            <v-card-title>
              <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Documentos de nombramiento</h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <DocumentoNombramiento :id-proceso-compra="idProceso" :solo-lectura="soloLectura || soloLecturaPorParticipacionEnComision" />
            </v-card-text>
          </v-card>
          <!-- Documentos de nombramiento -->
  
          <!-- Recomendación de la Comisión de Alto Nivel -->
          <v-card v-if="esAlMenosUCP || tieneParticipacionEnComision">
            <v-card-title>
              <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Recomendación de la comisión especial de alto nivel</h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <RecomendacionesComisionAltoNivel :id-proceso-compra="idProceso" :solo-lectura="soloLectura || !(esAlMenosUCP || tieneParticipacionEnComision)" />
            </v-card-text>
          </v-card>
          <!-- Recomendación de la Comisión de Alto Nivel -->
        </div>
      </v-col>

      <v-col cols="12" :lg="esAlMenosUCP ? 6 : 12">
        <div class="d-flex flex-column" style="gap: 32px">
          <!-- Recursos admitidos -->
          <v-card>
            <v-card-title>
              <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Recursos de revisión admitidos</h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <DataTable 
                dense
                no-gutters
                :headers="headersRecursosAdmitidos" 
                :tiene_paginacion="false" 
                :items="itemsRecursosAdmitidos"
              >
                <template v-slot:[`item.proveedor`]="{ item }">
                  {{ item?.Usuario?.Proveedor?.nombre_comercial }}
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ item.created_at | moment("DD/MM/YYYY") }}
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-icon color="secondary" @click="showDocument(item)">
                    mdi-eye
                  </v-icon>
                </template>
              </DataTable>
            </v-card-text>
          </v-card>
          <!-- Recursos admitidos -->

          <!-- Pronunciamiento del adjudicado -->
          <v-card>
            <v-card-title>
              <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Pronunciamientos</h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <PronunciamientosAdjudicados :id-proceso-compra="idProceso" :solo-lectura="soloLectura || soloLecturaPorParticipacionEnComision" />
            </v-card-text>
          </v-card>
          <!-- Pronunciamiento del adjudicado -->
  
          <!-- Resolución Final de la Máxima Autoridad -->
          <v-card v-if="esAlMenosUCP">
            <v-card-title>
              <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Resolución Final de la Máxima Autoridad</h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <ResolucionesFinalesMaximaAutoridad 
                :resoluciones="resoluciones" 
                :id-proceso-compra="idProceso"
                :solo-lectura="soloLecturaPorParticipacionEnComision"
                @on-recargar-resoluciones="getResoluciones" 
              />
            </v-card-text>
          </v-card>
          <!-- Resolución Final de la Máxima Autoridad -->
        </div>
      </v-col>
    </v-row>

    <!-- Modal agregar participante -->
    <ModalAgregarComisionAltoNivel 
      :is-open.sync="modalAgregarParticipante"
      :id-proceso="idProceso"
      :id-etapa-proceso="procesoData?.id_etapa_pausa"
      @on-save="getDocumentosAlto"
    />

    <!-- Modal visualizar nombramiento -->
    <PdfModal
      :isOpen.sync="modalVisualizarNombramiento" 
      :source-loadable="visualizacionDocumentoNombramiento"
      filename="documento-nombramiento"
    />

    <!-- Modal Recursos de revision doc-->
    <v-dialog v-model="showDialog" max-width="1200px">
      <v-card class="pb-2 pa-4">
        <v-row align="center" class="mx-4">
          <v-col cols="4">
            <p class="text-h6 secondary--text font-weight-bold">
              {{ proveedorSeleccionado?.Usuario?.Proveedor?.nombre_comercial }}
            </p>
            <v-chip :color="proveedorSeleccionado?.EstadoImpugnacion?.id == 2 ? 'green' : 'red'" label
              class="white--text">
              {{ proveedorSeleccionado?.EstadoImpugnacion?.nombre }}
            </v-chip>
          </v-col>
          <v-col cols="6">
            <v-card class="mx-4 my-6">
              <v-card-text>
                <div class="d-flex flex-wrap">
                  <p class="text-h6 secondary--text">Recurso de revisión</p>
                  <v-btn @click="
                    descargarArchivoProceso({
                      ruta_documento: proveedorSeleccionado.doc_impugnacion,
                    })
                    " icon>
                    <v-icon color="secondary" size="40">mdi-download</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <embed width="100%" height="700" :src="doc + '#page=1%zoom=100'" v-if="doc" />
        <v-card-actions>
          <v-btn @click.stop="showDialog = false" color="secondary" outlined>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import ResolucionesFinalesMaximaAutoridad from "../compartidos/ResolucionesFinalesMaximaAutoridad.vue";
import RecomendacionesComisionAltoNivel from "../compartidos/RecomendacionesComisionAltoNivel.vue";
import ModalAgregarComisionAltoNivel from "../compartidos/ModalAgregarComisionAltoNivel.vue";
import PronunciamientosAdjudicados from "../compartidos/PronunciamientosAdjudicados.vue";
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from "@/utils/loadable";
import DocumentoNombramiento from "./DocumentoNombramiento.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import DataTable from "@/components/DataTableComponent.vue";
import ContinuarProceso from "./ContinuarProceso.vue";
import { Observable } from "@/utils/observable";
import { formatDate } from '@/utils/datetime';
import { mapActions, mapState } from "vuex";
import moment from "moment";
import PdfModal from "@/components/PdfModal.vue";
import { ValidacionAccesos } from "@/utils/role-utils";

export default {
  name: "ResolucionRecursosComponent",
  components: { 
    DataTable, 
    ConfirmationModalComponent, 
    ContinuarProceso, 
    PronunciamientosAdjudicados, 
    ResolucionesFinalesMaximaAutoridad, 
    RecomendacionesComisionAltoNivel,
    ModalAgregarComisionAltoNivel,
    PdfModal,
    DocumentoNombramiento,
  },
  props: {
    etapa: { type: Object, default: () => { } },
    soloLectura: { type: Boolean, default: false },
    getProceso: { type: Function },
    cargarEtapaActual: { type: Function },
  },
  data: () => ({
    idProceso: null,
    headersAltoNivel: [
      { text: "No de documento", align: "center", value: "numero_documento", width: "30%", sortable: false },
      { text: "Tipo", align: "center", value: "tipo_documento", width: "20%", sortable: false },
      { text: "Nombres", value: "nombre_completo", width: "20%", align: "center", sortable: false },
    ],
    headersActoTramite: [
      { text: "Documento", align: "center", value: "nombre_documento", width: "30%" },
      { text: "Fecha de carga", align: "center", value: "fecha_carga", width: "20%" },
      { text: "Acciones", value: "acciones", width: "20%", sortable: false, align: "center" },
    ],
    headersRecursosAdmitidos: [
      { text: "Código", align: "center", value: "correlativo", width: "30%" },
      { text: "Proveedor", align: "center", value: "proveedor", width: "20%" },
      { text: "Fecha de presentación", value: "created_at", width: "20%", align: "center" },
      { text: "Fecha de admisión", value: "created_at", width: "20%", align: "center" },
      { text: "Acciones", value: "acciones", width: "20%", align: "center", sortable: false },
    ],
    itemsAltoN: [],
    itemsActoTramite: [],
    itemsPronunciamiento: [],
    itemsRecursosAdmitidos: [],
    verificarDoc: false,
    actoTramiteFile: null,
    nombreActoTramite: null,
    editActaComision: false,
    doc: null,
    proveedorSeleccionado: {},
    showDialog: false,
    pronunciamientoProveedor: null,
    // Resoluciones
    resoluciones: createLoadable([]),
    // Continuar proceso
    continuacionProceso: createLoadable(null),
    // Agregar participantes
    modalAgregarParticipante: false,
    // Visualizar nombramiento
    modalVisualizarNombramiento: false,
    visualizacionDocumentoNombramiento: createLoadable(null),
    nombramientoActivo: null,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "verificacionParticipacionComision"]),
    resolucionesCargadas() {
      return this.resoluciones.data;
    },
    esProveedor() {
      return ValidacionAccesos.ES_PROVEEDOR;
    },
    esTACOP() {
      return ValidacionAccesos.ES_TACOP;
    },
    esUCP() {
      return ValidacionAccesos.ES_UCP;
    },
    esAlMenosUCP() {
      return ValidacionAccesos.ES_AL_MENOS_UCP;
    },
    tieneParticipacionEnComision() {
      const participacionComision = this.verificacionParticipacionComision.data;

      return participacionComision?.participa_en_comision;
    },
    soloLecturaPorParticipacionEnComision() {
      const participacionComision = this.verificacionParticipacionComision.data;

      return (participacionComision?.participa_en_comision && !participacionComision?.pertenece_a_ucp);
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", [
      "getDocumentosProcesoPorTipo",
      "descargarBufferDocumento",
      "guardarArchivoProceso",
      "descargarArchivoProceso",
      "cargarSiguientesEtapasImpugnacion",
    ]),
    formatDate,
    getNombre(item = null) {
      let nombre;
      if (item !== null) {
        nombre = item.primer_nombre + " ";
        if (item.segundo_nombre !== null) {
          nombre += item.segundo_nombre + " ";
        }
        if (item.tercer_nombre !== null) {
          nombre += item.tercer_nombre + " ";
        }
        if (item.primer_apellido !== null) {
          nombre += item.primer_apellido + " ";
        }
        if (item.segundo_apellido !== null) {
          nombre += item.segundo_apellido + " ";
        }
        if (item.apellido_casada !== null) {
          nombre += item.apellido_casada;
        }
      }
      return nombre;
    },
    async getDocumentosAlto() {
      const { status, data } =
        await this.services.RecursoRevision.getComisionAltoNivel(
          this.idProceso,
          {
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          },
        );

      if (status == 200) {
        this.itemsAltoN = data;
      }
    },

    async saveArchivo(tipo, nombre, doc) {
      let body = {
        documento: doc,
        nombre: nombre,
        id_proceso_compra: this.idProceso,
        id_tipo_documento: tipo,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      };
      const response = await this.guardarArchivoProceso(body);

      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          message: "Documento agregado con éxito",
          type: "success",
        });
        this.reloadDocTables(tipo);
      }
    },
    reloadDocTables(type) {
      switch (type) {
        case 15000:
          this.getDocumentosActoTramite();
          break;
        case 15002:
          break;
        case 15003:
          this.getResoluciones();
          break;
      }
    },
    async getDocumentosActoTramite() {
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.idProceso,
        id_tipo_documento: 15000,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,

      });
      this.itemsActoTramite = response;
    },
    async getResoluciones() {
      toggleLoadable(this.resoluciones);
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: this.idProceso,
        id_tipo_documento: 15003,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });

      setLoadableResponse(this.resoluciones, { data: response ?? [] });
    },
    async getRecursosRevision() {
      const { status, data } =
        await this.services.RecursoRevision.getRecursosRevisionUcpAdmitidos(
          this.idProceso,
          {
            id_tipo: 1,
            id_estado: 2,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        )

      if (status == 200) {
        this.itemsRecursosAdmitidos = data;
      }
    },

    async getPronunciamiento(id) {
      const { status, data } =
        await this.services.RecursoRevision.getPronunciamiento(id);

      if (status == 200) {
        this.pronunciamientoProveedor = data;
      }
    },

    async showDocument(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.getDoc(response);
      this.getPronunciamiento(item.id);
      this.proveedorSeleccionado = item;
      this.showDialog = true;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
    // Visualizacion resolucion final
    cerrarModalVisualizacionDocumento(visible) {
      this.modalVisualizacionDocumento = visible;
    },
    async continuarProceso() {
      toggleLoadable(this.continuacionProceso);
      const { data } = await this.services.PacProcesos.finalizarEtapaImpugnacion(this.idProceso, this.etapa.id);
      setLoadableResponse(this.continuacionProceso, data, { showAlertOnSuccess: true, skipOnSuccess: true });

      if (isResponseSuccesful(data)) {
        Observable.emit('recargar-proceso-actual');
      }
    },
    // Nombramientos comision alto nivel
    async visualizarNombramientoComision(nombramiento) {
      this.nombramientoActivo = { ...nombramiento };
      this.modalVisualizarNombramiento = true;
      toggleLoadable(this.visualizacionDocumentoNombramiento);
      const { data } = await this.services.RecursoRevision.visualizarNombramientoComision(this.idProceso, nombramiento.id);
      setLoadableResponse(this.visualizacionDocumentoNombramiento, data, { isFile: true });
    },
  },
  created() {
    this.idProceso = this.$route.params.idProceso;

    this.getDocumentosAlto();
    
    this.getDocumentosActoTramite();
    this.getRecursosRevision();
    this.getResoluciones();
  },
  watch: {
    resolucionesCargadas: {
      handler(nuevas, previo) {
        if (previo.length === 0 &&  nuevas.length > 0) {
          this.cargarSiguientesEtapasImpugnacion(this.idProceso);
        }
      },
      deep: true,
    },
  },
};
</script>
