import { http_client } from "@/plugins/http_client";

const getCifradosOBS = async (id_obs, params = {}) => {
  return await http_client(`/api/v1/pac/${id_obs}`, params, "get");
};

const getOBSSegmentos = async (params = {}) => {
  return await http_client(`/api/v1/pac/obs`, params, "get");
};

const putEstadoSolicitud = async (id_solicitud, body = {}) =>
  await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud}/estado`,
    body,
    "put"
  );

const getSolicitudesPAC = async (params = {}) => {
  return await http_client(`/api/v1/pac/solicitudes`, params, "get");
};

const deleteSolicitudesPAC = async (id_solicitud_necesidad, params = {}) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}`,
    params,
    "delete"
  );
};

const getSolicitudPAC = async (id_solicitud_necesidad, params = {}) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}`,
    params,
    "get"
  );
};

const postSolicitudNecesidad = async (params = {}) => {
  return await http_client(`/api/v1/pac/solicitudes`, params, "post");
};

const putSolicitudNecesidad = async (id_solicitud_necesidad, params) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}`,
    params,
    "put"
  );
};

const postSolicitudNecesidadFondo = async (
  id_solicitud_necesidad,
  params = {}
) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}/fondos`,
    params,
    "post"
  );
};

const deleteSolicitudesFondo = async (id_fondo_solicitud, params = {}) => {
  return await http_client(
    `/api/v1/pac/solicitudes/fondos/${id_fondo_solicitud}`,
    params,
    "delete"
  );
};

const getSolicitudAjuste = async (id_solicitud_necesidad, params = {}) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}/ajuste`,
    params,
    "get"
  );
};

const postAjusteSolicitud = async (
  id_solicitud_necesidad,
  id_fondo_sn,
  params = {}
) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}/financiamiento/${id_fondo_sn}/ajuste`,
    params,
    "put"
  );
};

const postSolicitudNecesidadFondoAjuste = async (
  id_solicitud_necesidad,
  params = {}
) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}/fondos/votado`,
    params,
    "post"
  );
};

const actualizarEstadoAjuste = async (id_solicitud_necesidad, params = {}) => {
  return await http_client(
    `/api/v1/pac/solicitudes/${id_solicitud_necesidad}/ajuste`,
    params,
    "put"
  );
};

const getProcesoPAC = async (id_proceso_pac, params = {}) => {
  return await http_client(
    `/api/v1/pac/procesos/${id_proceso_pac}`,
    params,
    "get"
  );
};

const postSolicitudProcesoPAC = async (id_proceso_pac, params = {}) => {
  return await http_client(
    `/api/v1/pac/procesos/${id_proceso_pac}/solicitudes`,
    params,
    "post"
  );
};

const deleteSolicitudProcesoPAC = async (
  id_proceso,
  id_solicitud_necesidad_pac,
  params = {}
) => {
  return await http_client(
    `/api/v1/pac/procesos/${id_proceso}/solicitudes/${id_solicitud_necesidad_pac}`,
    params,
    "delete"
  );
};

const updateProcesoPAC = async (id_proceso_pac, params = {}) => {
  return await http_client(
    `/api/v1/pac/procesos/${id_proceso_pac}`,
    params,
    "put"
  );
};

const enviarProcesoPAC = async (id_proceso_pac, params = {}) => {
  return await http_client(
    `/api/v1/pac/procesos/${id_proceso_pac}/pac`,
    params,
    "put"
  );
};

const getFondosProyecto = async (params = {}) => await http_client(`/api/v1/pac/fondo/proyecto`, params,);

const descargarReportePac = async (id_institucion, esPublico, params = {}) => {
  return await http_client(
    `/api/v1/procesos/pac/institucion/${id_institucion}/${esPublico ? "externo" : "interno"}`,
    params,
    "get",
    {},
    true,
    "blob"
  );
};

const listadoAniosFiscales = async (params) => {
  return await http_client(
    `/api/v1/pac/procesos/anios/fiscales`,
    params,
    "get",
    {},
    true,
  );
}

const eliminarProcesoPAC = async (id_proceso_pac, params = {}) => {
  return await http_client(`/api/v1/pac/procesos/eliminar/${id_proceso_pac}`, params, "delete");
};

export default {
  getCifradosOBS,
  getOBSSegmentos,
  putEstadoSolicitud,
  getSolicitudesPAC,
  deleteSolicitudesPAC,
  getSolicitudPAC,
  postSolicitudNecesidad,
  putSolicitudNecesidad,
  postSolicitudNecesidadFondo,
  deleteSolicitudesFondo,
  getSolicitudAjuste,
  postAjusteSolicitud,
  postSolicitudNecesidadFondoAjuste,
  actualizarEstadoAjuste,
  getProcesoPAC,
  postSolicitudProcesoPAC,
  deleteSolicitudProcesoPAC,
  updateProcesoPAC,
  enviarProcesoPAC,
  getFondosProyecto,
  descargarReportePac,
  listadoAniosFiscales,
  eliminarProcesoPAC
};
