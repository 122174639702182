<template>
  <v-container>
    <EmptyComponent :empty="!details.isLoading && !showDetails">
      <template v-slot>
        <v-container class="px-0 py-0">
          <v-row>
            <v-col cols="12" lg="6">
              <RequestItemApproval :itemId="itemId" :details="details" :buttons="buttonsList"  @on-status-change="fetchAllDetails" :permisoLectura="permisoLectura"/>
            </v-col>
            <v-col cols="12" lg="6" class="mt-8 mt-lg-0 d-flex flex-column flex-wrap" style="gap: 16px">
              <v-card class="px-4 py-4" style="width: 100%">
                <v-card-title class="d-flex align-center px-0 py-0 pt-4" style="gap: 16px">
                  <v-icon color="primary" large>mdi mdi-comment-text-multiple-outline</v-icon>
                  <h4 class="text-uppercase flex-grow-1">Nuevo anexo</h4>
                  <v-btn
                      class="primary"
                      @click.stop="abrirModalCreacionAdjunto"
                      large
                      v-if="permisoLectura()"
                  >
                    Agregar
                  </v-btn>
                </v-card-title>
              </v-card>
              <app-time-line :items="timeline" :showActions="false" :loading="history.isLoading" style="width: 100%">
                <template v-slot:title>
                  <v-card-title class="d-block text-center font-weight-bold mb-2">
                    <h2 style="font-size: 24px" class="text-uppercase">Historial</h2>
                  </v-card-title>
                </template>
                <template v-slot:suffix="{item}">
                  <v-btn v-if="item.adjunto" small class="ml-1" color="primary" @click.stop="loadAttachment(item.adjunto)">
                    <v-icon class="mr-1" color="#FFFFFF !important">mdi mdi-attachment</v-icon>
                    Ver
                  </v-btn>
                </template>
              </app-time-line>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </EmptyComponent>
    <PdfModal
      :isOpen="isPdfModalOpen" 
      :source-loadable="pdfLoadable" 
      @on-visibility-change="handlePdfModalVisibility"
      :filename="filename"
    />
    <ModalAdjuntos
      :isOpen="modalCreacionAdjunto"
      :detalles="details"
      @on-visibility-change="manejarVisibilidadModalAdjunto"
    />

  </v-container>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable';
import AdjuntosProcesoAutorizacionVue from '../CMProcesoAprobacionCompraElectronica/AdjuntosProcesoAutorizacion.vue';
import { EmptyComponent } from '@/components/utils';
import RequestItemApproval from './components/RequestItemApproval.vue';
import PdfModal from '@/components/PdfModal.vue';
import { isEmpty } from 'lodash';
import { formatDate } from '@/utils/datetime';
import { getFilenameFromPath } from '@/utils/data';
import {mapMutations, mapActions, mapState} from 'vuex';
import ModalAdjuntos from './components/ModalAdjuntos.vue';
import { Observable, Listener } from '@/utils/observable.js';
import { Countdown } from '@/utils/countdown';

export default {
  name: 'RequestItemDetailsView',
  components: { RequestItemApproval, EmptyComponent, PdfModal, AdjuntosProcesoAutorizacionVue, ModalAdjuntos },
  data: () => ({
    history: createLoadable([]),
    details: createLoadable([]),
    buttons: createLoadable([]),
    adjuntos: createLoadable(null),
    itemId: null,
    // PDF
    isPdfModalOpen: false,
    pdfLoadable: createLoadable(null),
    filename: '',
    activePath: '',
    modalCreacionAdjunto: false,
  }),
  methods: {
    // ...mapActions('cmAprobacionCompraElectronica', ['cargarAdjuntosUCP']),
    ...mapMutations('cmAprobacionCompraElectronica', ['resetearProductosProveedor']),
    async fetchHistory() {
      toggleLoadable(this.history);
      const {data} = await this.services.cmSupplierApproval.getItemHistory(this.itemId);

      setLoadableResponse(this.history, data);
    },
    async fetchDetails() {
      toggleLoadable(this.details);
      const {data} = await this.services.cmSupplierApproval.getItemDetails(this.itemId);

      setLoadableResponse(this.details, data);
    },
    async fetchButtons() {
      toggleLoadable(this.buttons);
      const {data} = await this.services.cmSupplierApproval.getButtons(this.itemId);
      setLoadableResponse(this.buttons, data, { dontShowError: true });
    },
    async fetchAllDetails() {
      await this.fetchDetails();
      await this.fetchHistory();
      await this.fetchButtons();
    },
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
    async loadAttachment(path) {
      this.isPdfModalOpen = true;
      if (this.activePath === path) return;

      this.filename = getFilenameFromPath(path);
      this.activePath = path;
      toggleLoadable(this.pdfLoadable);
      const { data } = await this.services.cmAprobacionSolicitudCompra.loadAttachment({
        nombre: path,
      });

      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
    manejarVisibilidadModalAdjunto(visible) {
      this.modalCreacionAdjunto = visible;
    },
    abrirModalCreacionAdjunto() {
      this.modalCreacionAdjunto = true;
    },
    permisoLectura(){

      const esProveedor = this.haveRole('ROLE_PROVEEDOR');

      const idUsuario = this.userInfo.user.id
      const idAdministrador = this.details.data[0]?.CMSolicitudCompra.id_usuario_contrato;

      return idUsuario === idAdministrador || esProveedor
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    timeline() {
      const bitacoras = this.history.data?.bitacoras ?? [];

      return bitacoras.map(({descripcion, fecha, comentario, id, sec_bit, adjunto }) => ({
        id,
        descripcion,
        fecha: formatDate(fecha),
        comentario,
        color: sec_bit?.retroceso_en_flujo ? 'red' : 'success',
        adjunto,
      }));
    },
    buttonsList() {
      const botones = this.buttons.data ?? [];
      return botones.map(({descripcion, accion_requerida, formulario, nombre, paso}) => ({
        descripcion,
        accion_requerida,
        formulario,
        nombre,
        paso,
      }));
    },
    showDetails() {
      return this.details.data && !isEmpty(this.details.data)
    },
    idProveedor(){
      return this.details.data[0]?.id_proveedor
    },
    idSolicitud(){
      return this.details.data[0]?.CMSolicitudCompra.id
    },
  },
  watch:{
    idProveedor(value){
      if(!value) return;
      // this.cargarAdjuntosUCP({ idProveedor: this.idProveedor, idSolicitud: this.idSolicitud });
    }
  },
  async created() {
    const requestId = this.$route.params.id;
    this.itemId = Number.isFinite(Number(requestId)) ? Number(requestId) : null;
    await this.fetchAllDetails();
  },
  mounted(){
    const listener = new Listener('history').setHandler(() => this.fetchHistory());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-history', listener);
  },
  beforeDestroy(){
    Countdown.getInstance().clear();
    this.unsubscribe?.();
    this.resetearProductosProveedor();
  }
}
</script>