<template>
  <div style="width: 100%; margin: 0">
    <filtros-sticky icono="mdi-filter-settings" :modo-sticky="false">
      <v-row>
        <v-col cols="12" md="4">
          <instituciones-autoComplete
            :value.sync="filtros.id_institucion"
            :evento-reinicio="eventoRestablecerFiltros"
            :listener="institucionListener"
            :autocomplete-props="{ disabled: false }"
            :excluir-centros-escolares="false"
            solo-instituciones-padre
          />
        </v-col>
        <v-col cols="12" md="4">
          <div
            class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap"
            style="gap: 16px"
          >
            <v-btn
              color="primary"
              class="flex-grow-1 flex-shrink-1"
              x-large
              @click.stop="hacerBusquedaConFiltros"
            >
              Buscar
            </v-btn>

            <v-btn
              color="primary"
              class="flex-grow-1 flex-shrink-1"
              outlined
              x-large
              @click.stop="restablecerBusqueda"
            >
              Limpiar
            </v-btn>

            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  outlined
                  x-large
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="descargarExcel"
                >
                  <v-icon>mdi mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar a excel</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </filtros-sticky>

    <data-table-component
      class="mt-4"
      :items="fondos.data"
      :show_loading="fondos.isLoading"
      :headers="headers"
      :total_registros="registrosTotales"
      @paginar="manejarPaginacion"
      v-models:pagina="paginacion.pagina"
      v-models:select="paginacion.registrosPorPagina"
      dense
      no-gutters
    >
      <template v-slot:item.monto_establecido="{ item }">
        {{ toMoneyFormat(item.monto_establecido) }}
      </template>
      <template v-slot:item.monto_disponible="{ item }">
        {{ toMoneyFormat(item.monto_disponible) }}
      </template>
    </data-table-component>
  </div>
</template>

<script>
import InstitucionesAutoComplete from "@/components/compartidos/InstitucionesAutoComplete.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import FiltrosSticky from "../FiltrosSticky.vue";
import { Listener, Observable } from "@/utils/observable";
import {
  createPageable,
  setPageableResponse,
  togglePageable,
} from "@/utils/loadable";
import { toMoneyFormat } from "@/utils/data";

const filtrosIniciales = {
  id_institucion: null,
};

export default {
  name: "FondosCentrosEscolares",
  components: {
    InstitucionesAutoComplete,
    DataTableComponent,
    FiltrosSticky,
  },
  data: () => ({
    filtros: { ...filtrosIniciales },
    // Listeners
    institucionListener: new Listener(),
    unsubscribes: [],
    // Eventos listeners
    eventoRestablecerFiltros:
      "RESTABLECER_INFORMACION_REPORTERIA_CENTROS_ESCOLARES",
    eventoBusquedaFiltros: "BUSCAR_INFORMACION_REPORTERIA_CENTROS_ESCOLARES",
    // Data
    paginacion: {
      pagina: 1,
      registrosPorPagina: 10,
    },
    fondos: createPageable([], 10),
  }),
  computed: {
    headers() {
      return [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false,
        },
        {
          text: "Centro escolar",
          value: "nombre",
          align: "center",
          sortable: false,
        },
        {
          text: "Fondo utilizado",
          value: "nombre_fondo",
          align: "center",
          sortable: false,
        },
        {
          text: "Motivo",
          value: "motivo",
          align: "center",
          sortable: false,
        },
        {
          text: "Monto establecido",
          value: "monto_establecido",
          align: "center",
          sortable: false,
        },
        {
          text: "Monto disponible",
          value: "monto_disponible",
          align: "center",
          sortable: false,
        },
        {
          text: "Conteo",
          value: "conteo",
          align: "center",
          sortable: false,
        },
      ];
    },
    registrosTotales() {
      return this.fondos.pagination.total_rows;
    },
  },
  methods: {
    toMoneyFormat,
    async cargarFondos() {
      const filtros = {
        pagination: true,
        per_page: this.paginacion.registrosPorPagina,
        page: this.paginacion.pagina,
        ...this.filtros,
      };

      togglePageable(this.fondos);

      const { data, headers } =
        await this.services.reporteriaCentrosEscolares.obtenerFondos(filtros);

      setPageableResponse(this.fondos, data, headers);
    },
    async manejarPaginacion(paginacion) {
      const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
      this.paginacion = { pagina, registrosPorPagina };
      await this.cargarFondos();
    },
    async initData() {
      await this.cargarFondos();
      Observable.getInstance().subscribe(
        this.eventoRestablecerFiltros,
        this.institucionListener
      );
    },
    async hacerBusquedaConFiltros() {
      this.paginacion.pagina = 1;
      Observable.emit(this.eventoBusquedaFiltros, { ...this.filtros });
      await this.cargarFondos();
    },
    async restablecerBusqueda() {
      this.filtros = { ...filtrosIniciales };
      Observable.emit(this.eventoRestablecerFiltros);
      Observable.emit(this.eventoBusquedaFiltros, { ...this.filtros });
      this.paginacion = { pagina: 1, registrosPorPagina: 10 };
      await this.cargarFondos();
    },
    async descargarExcel() {
      const filtros = {
        ...this.filtros,
        pagination: false,
      };

      const { data } =
        await this.services.reporteriaCentrosEscolares.descargarExcelFondos(filtros);

      const blob = new Blob([data], { type: "application/vnd.ms-excel" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "fondos_centros_escolares.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  async created() {
    await this.initData();
  },
};
</script>
