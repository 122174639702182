<script>
import { mostrarPorcentaje } from '@/utils/data';
import { formatDate } from '@/utils/datetime';
import { createLoadable, createPageable, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import { mapState, mapMutations } from "vuex";

export default {
    data: () => ({
        isPdfModalOpen: false,
        anios: [],
        instituciones: [],
        documentos: createPageable([], 10),
        pacFilters: {
            year: null,
            institucion: null,
        },
        isLoading: false,
        pdfLoadable: createLoadable(null),
    }),
    computed: {
        ...mapState(["selectedUnidad"]),
        registrosTotales() {
            return this.documentos.pagination.total_rows;
        },
        clearData() {
            return this.documentos.data && this.documentos.data.length > 0;
        },
    },
    methods: {
        formatDate,
        mostrarPorcentaje,
        obtenerEstadoDocumento(institucion) {
            const estados = {
                'pendiente': { texto: 'Documento pendiente', icono: 'mdi-file-document-alert', color: "info" },
                'cargado': { texto: 'Documento cargado', icono: 'mdi-file-upload', color: "primary" },
                'aprobado': { texto: 'Documento aprobado', icono: 'mdi-file-document-check', color: "success" },
            }

            const documento = institucion.documento;

            if (!documento) return estados.pendiente;

            return documento.aprobado ? estados.aprobado : estados.cargado;
        },
        limpiarDatos() {
            this.documentos = createPageable([], 10);
            this.pacFilters.year = null;
            this.pacFilters.institucion = null;

            this.$emit('limpiarData')
        },
        async ObtenerInstitituciones() {
            const res = await this.services.Instituciones.getCatalogoInstituciones({
                pagination: false,
            });
            this.instituciones = res.data;
        },
        async ObtenerAniosFiscales() {
            const { data } = await this.services.PacProcesos.cargarAniosFiscales();
            this.anios = data;
        },
        async cargarDocumentos() {
            togglePageable(this.documentos);
            const { data, headers } = await this.services.ReporteriaUCP.cargarDocumentos(
                this.pacFilters.year,
                this.selectedUnidad.instituciones.id
                //this.pacFilters.institucion.id
            );
            setPageableResponse(this.documentos, data, headers);
            this.$emit('showTable', this.documentos);
        },
    },
    created() {
        this.ObtenerAniosFiscales();
        this.ObtenerInstitituciones();
    },
    watch: {
        'pacFilters.year': {
            handler(val, oldValue) {
                if (val !== oldValue) {
                    this.documentos = createPageable([], 10);
                    this.pacFilters.institucion = null;
                    this.$emit('limpiarData');
                }
            },
        }
    }
};
</script>

<template>
    <v-card class="rounded-lg">
        <v-card-title class="bgCardHeader rounded-top-lg">
            <h3 class="text-center white--text">Reporte de UCP</h3>
        </v-card-title>
        <v-card-text>
            <v-autocomplete
                class="mt-4"
                v-model="pacFilters.year" 
                :items="anios" 
                label="Año" 
                outlined dense item-value="id"
                item-text="anio" 
                hide-details clearable></v-autocomplete>
            <v-btn :disabled="!pacFilters.year" :color="clearData ? 'primary' : 'primary'"
                class="mt-4 mb-2" @click="clearData ? limpiarDatos() : cargarDocumentos()">
                {{ clearData ? 'Limpiar' : 'Consultar' }}
            </v-btn>
        </v-card-text>
    </v-card>

</template>
