<template>
    <v-dialog v-model="isOpen" persistent max-width="650">
        <v-card class="pa-4">
            <v-card-title>
                Cambiar estado de contrato
                <v-spacer></v-spacer>
                <v-btn icon small @click.stop="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="mx-auto">
                <v-autocomplete
                    filled
                    rounded
                    outlined
                    label="Estado"
                    placeholder="Seleccione el estado"
                    :items="estados.data"
                    item-text="nombre"
                    item-value="nombre"
                    v-model="estado"
                    return-object
                ></v-autocomplete>
            </v-card-text>
            <v-card-actions
                class="d-flex justify-center"
            >
                <v-btn
                    class="primary mr-4" 
                    @click.stop="$emit('close')"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    class="secondary mr-4" 
                    @click="setEstado()"
                    :disabled="typeof estado === 'string' || this.estado?.nombre === estadoSeleccionado"
                >
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import {
    createLoadable,
    toggleLoadable,
    setLoadableResponse,
    } from '@/utils/loadable.js';
    import { convertToFormData } from "@/utils/data";

    export default {
        name: 'modalCambiarEstado',
        props: {
            isOpen: {
                type: Boolean,
                default: false,
            },
            estadoSeleccionado: {
                type: String,
                default: null,
            },
            contratoSeleccionado: {
                type: Number,
                default: null,
            }
        },
        data() {
            return {
                estados: createLoadable([]),
                estado: null,
            }
        },
        methods: {
            async getEstados() {
                toggleLoadable(this.estados);
                const { data } = await this.services.EliminarEtapasContratoServices.obtenerEstadosContrato();
                setLoadableResponse(this.estados, data);
            },
            async setEstado() {
                if (typeof this.estado === "string") return; // No a cambiado el estado seleccionado.
                const { status } = await this.services.EliminarEtapasContratoServices.guardarEstado(this.contratoSeleccionado, convertToFormData({
                    id_estado: this.estado?.id,
                    id_proceso_compra: this.$route?.params?.idProceso || 0
                }))
                
                if (status === 200) { 
                    this.$emit('close');
                    this.$emit('estado-cambiado');
                }
            }
        },
        created() {
            this.getEstados();
            this.estado = this.estadoSeleccionado;
        },
        watch: {
            estadoSeleccionado(newVal) {
                this.estado = newVal;
            },
        },
    }
</script>