<template>
  <v-container background-color="secondary" class="px-8">
    <div class="d-flex justify-center">
      <v-img contain class="mt-4" src="@/assets/img/comprasal_login.png" max-width="350" />
    </div>

    <v-divider class="mt-4 mb-4" />

    <h1 class="text-center mb-4 secondary--text">Registro de titulares</h1>

    <v-form v-if="!mostrarMensajeExito" ref="registerForm" @submit.prevent="sendForm">
      <v-row class="mt-0" no-gutters>
        <v-col cols="9" sm="6">
          <v-text-field outlined v-model="form.numero_documento" label="Número de documento *" v-mask="'########-#'"
            required :disabled="!!form.id_persona" :rules="[rules.required]"></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row class="mt-0" no-gutters v-if="isDui(form.numero_documento)">
        <v-col cols="3" sm="3">
          <VueSimpleRecaptcha
              :sitekey="key"
              v-if="mostrar"
              ref="recaptcha"
              @callback="callback"
          />
        </v-col>
      </v-row> -->
      <v-row class="mt-0" no-gutters v-if="isDui(form.numero_documento)">
        <v-col cols="3" sm="3">
          <v-btn block large color="success" class="mt-2" :loading="loading" @click="verificarPersona"
            :disabled="!form.numero_documento" v-if="$vuetify.breakpoint.width < 600 && !form.id_persona">
            <v-icon color="white">mdi-check-decagram</v-icon>
          </v-btn>
          <v-btn block large color="success" :loading="loading" class="mt-2 white--text" @click="verificarPersona"
            :disabled="!form.numero_documento" v-if="$vuetify.breakpoint.width > 600 && !form.id_persona">Verificar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-text-field outlined v-model="form.primer_nombre" label="Primer nombre *" required readonly
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field outlined readonly v-model="form.segundo_nombre" label="Segundo nombre"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-text-field outlined v-model="form.primer_apellido" label="Primer apellido *" required readonly
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field outlined readonly v-model="form.segundo_apellido" label="Segundo apellido"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-text-field outlined readonly v-model="form.apellido_casada" label="Apellido de casada"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-text-field outlined v-model="form.correo_electronico" label="Correo electrónico *" required
            :rules="[rules.required, rules.correo]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field outlined v-model="form.telefono" label="Teléfono *" required v-mask="'####-####'"
            :rules="[rules.required, rules.telefono]"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-autocomplete outlined v-model="form.profesion" label="Profesión *" :items="profesiones" item-text="nombre"
            item-value="id" required :rules="[rules.required]" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-file-input outlined v-model="documento" accept="application/pdf" prepend-icon=""
            prepend-inner-icon="mdi-paperclip" label="Comprobante de nombramiento *" required
            :rules="[rules.required, rules.documento]"></v-file-input>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-autocomplete
              outlined
              v-model="form.institucion"
              label="Institución *"
              :items="instituciones"
              item-text="nombre_completo"
              item-value="id"
              clearable
              required
              :rules="[rules.required]"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field outlined v-model="form.cargo" label="Cargo funcional *" required
            :rules="[rules.required]"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12">
          <v-textarea outlined v-model="form.informacion" label="Información adicional" />
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mb-16">
        <v-col cols="12" sm="4">
          <v-btn block type="submit" color="success" class="white--text" :loading="loading">Enviar formulario
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <div class="text-center" v-else>
      <v-icon size="100" class="mb-4" color="success">mdi-check-decagram</v-icon>
      <h4 class="text-h4">Su solicitud ha sido enviada con éxito</h4>
      <h6 class="text-h6">Se le notificará cuando su usuario esté activo</h6>
      <p class="text--secondary mt-4">
        Será redirigido al login en {{ contador }} segundos
      </p>
    </div>
  </v-container>
</template>

<script>
import VueSimpleRecaptcha from "vue-simple-recaptcha";

function validarNumero(value) {
  if (value) {
    if (value.charAt(0) == "2" || value.charAt(0) == "6" || value.charAt(0) == "7") {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

function isEmail(value) {
  if (value === null) return false;
  return /^([a-zA-Z0-9./^S+$/<*>!#$%&'+/=?^_`{|}~-]+([s]{0}))+?@[a-zA-Z]+([.]{1})[a-zA-Z]+[s]{0}[.]?[a-zA-Z](([.]{0,1})([a-zA-Z]{2})+)*$/.test(
    value
  );
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "registroFuncionarioView",
  data: () => ({
    key: process.env.VUE_APP_CAPTCHA,
    mostrarMensajeExito: false,
    contador: 5,
    token: null,
    nacionalidades: [],
    profesiones: [],
    instituciones: [],
    mostrar: true,
    form: {
      numero_documento: null,
      id_persona: null,
      primer_nombre: null,
      segundo_nombre: null,
      primer_apellido: null,
      segundo_apellido: null,
      apellido_casada: null,
      correo_electronico: null,
      telefono: null,
      profesion: null,
      institucion: null,
      cargo: null,
      informacion: null,
    },
    loading: true,
    documento: null,
    rules: {
      required: (value) => !!value || "Este es un campo requerido",
      telefono: (value) =>
        (validarNumero(value) && value?.length === 9) ||
        "Ingrese un número de teléfono válido",
      correo: (value) => isEmail(value) || "Ingrese un correo electrónico válido",
      documento: (value) =>
        fileNameValid(value) ||
        "Ingrese un documento válido, se aceptan las siguientes extensiones: PDF",
    },
  }),
  components: {
    VueSimpleRecaptcha,
  },
  methods: {
    async callback(token) {
      this.form.captcha = token;
      this.mostrar = false;
      this.token = token;
    },
    async sendForm() {
      if (
        this.$refs.registerForm.validate() &&
        this.isDui(this.form.numero_documento) &&
        !!this.form.id_persona
      ) {
        this.loading = true;

        const form = new FormData();
        Object.entries(this.form).forEach(([key, value]) => form.append(key, value));
        form.append("documento", this.documento);

        const response = await this.services.RegistroPublico.postSolicitudTitular(
          form
        ).catch(() => {
          this.loading = false;
        });

        if (response.status === 201) {
          this.mostrarMensajeExito = true;
          setTimeout(() => this.cambiarContador(), 1000);
          setTimeout(() => {
            this.$router.replace({ name: "login" });
          }, 5000);
        }

        this.loading = false;
      } else {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Por favor ingrese todos los datos requeridos",
        });
      }
    },
    async getInstituciones() {
      this.loading = true;
      const {status, data} = await this.services.RegistroPublico.getInstituciones()
          .catch(() => {
            this.$route.replace({name: 'login'});
          });

      if (status == 200) this.instituciones = data.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }));

      this.loading = false;
    },
    async getProfesiones() {
      this.loading = true;
      const { status, data } = await this.services.RegistroPublico.getProfesiones().catch(
        () => {
          this.$route.replace({ name: "login" });
        }
      );
      if (status == 200) this.profesiones = data;
      this.loading = false;
    },
    async verificarPersona() {
      if (!this.isDui(this.form.numero_documento)) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Por favor ingrese un DUI válido",
        });
      }

      this.loading = true;
      const response = await this.services.VerificarDocumento.verificarDocumento(
        this.form.numero_documento,
        { captcha: this.form.captcha }
      ).catch(() => {
        this.loading = false;
      });
      if (response.status === 200) {
        const {
          id,
          primer_nombre,
          segundo_nombre,
          primer_apellido,
          segundo_apellido,
          apellido_casada,
        } = response.data;

        this.form.primer_nombre = primer_nombre;
        this.form.segundo_nombre = segundo_nombre;
        this.form.primer_apellido = primer_apellido;
        this.form.segundo_apellido = segundo_apellido;
        this.form.apellido_casada = apellido_casada;
        this.form.id_persona = id;
      }
      this.loading = false;
      this.token = null;
    },
    cambiarContador() {
      if (this.contador >= 0) {
        this.contador = Number(this.contador) - 1;
        setTimeout(() => this.cambiarContador(), 1000);
      }
    },
  },
  created() {
    this.getInstituciones();
    this.getProfesiones();
  },
};
</script>
