import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "../assets/scss/index.scss";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      md: 1320,
    },
    scrollBarWidth: 24,
  },
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    options: { customProperties: true },
    themes: {
      //Se definen colores globales para el tema claro
      light: {
        text: "#313945",
        blueMinsal: "#175EFB",
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#2D52A8",
        tableAccent: "#404A7A",

        bgMinsal: "#F4F7FD",
        bgWhite: "#FFFFFF",
        primary: "#313945",

        secondary: "#404A7A",
        success: "#57CC99",
        grayMinsal: "#757575",
        btnGreen: "#57CC99",
        //paleta de colores tabla convenio marco
        rechazado: "#EF5350",
        deshabilitado: "#E0E5F1",
        pendiente: "#FFEAA7",
        vigente: "#C1ECD9",
        cerrado: "#A7E0F1",
        publicado: "#FFA184",
        btnGreen: "#309C6D",
        btnRed: "#EF5350",
        btnMinsal: "#111C4E",
        subastaBlue: "#175EFB",
        redSubasta: "#EF5350",
        btnAditoria: "#F4F7FD",
        bgCardHeader: "#343a40",
      },
      dark: {
        bgMinsal: "#2f3032",
        blueMinsal: "#175EFB",
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#2D52A8",
        primary: "#175EFB",
        primarioMinsal: "#313945",
        secundarioMinsal: "#111C4E",
        fondoMinsal: "F4F7FD",
        // primary: {
        //     base: colors.purple.base,
        // }
      },
    },
  },
});
