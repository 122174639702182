<template>
  <Fragment>
    <v-row
      v-if="
        moment().isAfter(item.fecha_hora_final) &&
        moment().isAfter(item.fecha_hora_inicio) &&
        haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO']) &&
        hayGanador
      "
    >
      <v-col cols="12" md="12">
        <v-btn
          color="primary"
          @click="adjudicar(item.id)"
          v-if="item.acciones_pendientes[0]?.adjudicado === false"
        >
          <v-icon>mdi-info</v-icon>
          <span class="mx-2">Generar adjudicación</span>
        </v-btn>
        <v-btn
          color="primary"
          @click="enviarCorreos(item.id)"
          v-else-if="
            item.acciones_pendientes[0]?.adjudicado === true &&
            item.acciones_pendientes[0].notificado === false
          "
        >
          <v-icon>mdi-info</v-icon>
          <span class="mx-2">Enviar notificaciones de correo</span>
        </v-btn>
        <v-alert class="success" v-else>
          Ya se ha realizado la adjudicación y notificación por correo con
          respecto a esta subasta
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" md="6">
            <detalleSubasta :item="item" />
          </v-col>
          <v-col cols="12" md="6">
            <subastaGanador
              :id="item.id"
              :finalizada="moment().isAfter(item.fecha_hora_final)"
              @detalleGanador="cambiarSiHayGanador"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <detalleLote :id_lote="item.id_lote" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <historialPujasComponent
          :id="item.id"
          :porcentual="
            [
              'resta_porcentaje_publicidad',
              'resta_porcentaje',
              'suma_porcentaje',
            ].includes(item?.tipo)
          "
        />
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
import historialPujasComponent from "./HistorialPujasComponent.vue";
import detalleLote from "./DetalleLote.vue";
import subastaGanador from "./SubastaGanador.vue";
import detalleSubasta from "./DetalleSubasta.vue";
import detallesGanadores from "./DetallesGanadores.vue";
import { Fragment } from "vue-fragment";

export default {
  name: "AgregarAccionistaV2Component",
  components: {
    Fragment,
    historialPujasComponent,
    detalleLote,
    subastaGanador,
    detalleSubasta,
    detallesGanadores,
  },
  data: () => ({
    hayGanador: false,
  }),
  props: {
    item: {
      type: Object,
      required: true,
    },
    id_proceso: {
      type: Number,
      required: true,
    },
  },
  methods: {
    cambiarSiHayGanador(hayGanador) {
      this.hayGanador = hayGanador;
    },
    async adjudicar(id_subasta) {
      const { status, data } = await this.services.Paac.adjudicarSubastas(
        this.id_proceso,
        id_subasta
      );
      if (status === 200) {
        this.$emit("update_subastas");
        this.pushAppMessage({
          show: true,
          type: "success",
          color: "info",
          message: "Se ha adjudicado la subasta.",
        });
      }
    },
    async enviarCorreos(id_subasta) {
      this.pushAppMessage({
        show: true,
        type: "info",
        color: "info",
        message: "Se procederá a notificar a los involucrados.",
      });
      const { status, data } = await this.services.Paac.enviarCorreosGanadores(
        id_subasta
      );
      if (status === 200) {
        this.$emit("update_subastas");
        this.pushAppMessage({
          show: true,
          type: "success",
          color: "info",
          message: "Se han enviado los correos.",
        });
      }
    },
  },
};
</script>
