<template>
    <v-card>
        <v-card-text>
            <ValidarEdicion
                :id_proceso="idProceso"
                :id_modificativa="10"
                @validar="validar" 
            />
    
            <template v-if="validado">
                <v-card class="mt-4">
                    <v-card-title>
                        <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Etapas de recurso de revisión</h6>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <EtapasProcesoImpugnacionesComponent
                            permitir-edicion-etapas-finalizadas
                            permitir-edicion-fecha
                            permitir-cualquier-fecha
                            validar-modificativa
                        />
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title>
                      <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Reprogramación de etapas restantes</h6>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <ContinuarProceso 
                            permitir-cualquier-fecha 
                            :mostrar-encabezado="false" 
                            :id-proceso="idProceso"
                            :listener-recarga="listenerEtapasPipGeneral"
                            no-generar-registro
                            :hito="19"
                            :id-tipo-modificativa="10"
                        >
                            <template v-slot:header>
                                <v-alert type="info" dense>Debe reprogramar todas las etapas restantes antes de continuar con el proceso.</v-alert>
                            </template>
                            <template v-slot:accion>
                                <v-btn
                                @click="continuarProceso" 
                                color="primary" 
                                :loading="continuacionProceso.isLoading"
                                dark
                                >
                                Continuar proceso
                                </v-btn>
                            </template>
                        </ContinuarProceso>
                    </v-card-text>
                </v-card>

                <DocumentosRecursoRevision 
                    class="mt-4" 
                    :id-proceso-compra="idProceso" 
                    @on-creacion-etapas="recargarEtapasPipGeneral"
                />
            </template>

            <RecursosPresentados class="mt-4" :id-proceso-compra="idProceso" />
        </v-card-text>
    </v-card>
</template>
<script>
import EtapasProcesoImpugnacionesComponent from '@/views/ProcesoCompraDoc/RecursosRevision/components/EtapasProcesoImpugnacionesComponent.vue';
import ContinuarProceso from '@/views/ProcesoCompraDoc/RecursosRevision/components/componentesUCP/ContinuarProceso.vue';
import DocumentosRecursoRevision from './DocumentosRecursoRevision.vue';
import ValidarEdicion from '../ValidarEdicion.vue';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { Listener, Observable } from '@/utils/observable';
import RecursosPresentados from './RecursosPresentados.vue';

const eventoRecargaPipGeneral = 'recargar-pip-general-herramienta-recurso-revision';
export default {
    name: 'ModificarEtapas',
    components: { EtapasProcesoImpugnacionesComponent, ValidarEdicion, DocumentosRecursoRevision, ContinuarProceso, RecursosPresentados },
    data: () => ({
        validado: false,
        continuacionProceso: createLoadable(null),
        unsubscribers: [],
        listenerEtapasPipGeneral: new Listener("ListenerPipGeneral"),
    }),
    computed: {
		idProceso() {
			return Number(this?.$route?.params?.idProceso || 0);
		},
	},
    methods: {
        validar(valor) {
			this.validado = valor;
		},
        recargarEtapasPipGeneral() {
            Observable.emit(eventoRecargaPipGeneral);
        },
        async continuarProceso() {
            toggleLoadable(this.continuacionProceso);
            const { data } = await this.services.HerramientaRecursoRevision.continuarProceso(this.idProceso);
            setLoadableResponse(this.continuacionProceso, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                Observable.emit('recargar-proceso-actual-configuracion');
            }
        },
    },
    created() {
        this.unsubscribers = [
            Observable.getInstance().subscribe(eventoRecargaPipGeneral, this.listenerEtapasPipGeneral),
        ];
    },
    beforeDestroy() {
        this.unsubscribers.forEach((unsubscriber) => unsubscriber());
    },
}
</script>