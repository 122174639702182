<template>
  <v-card>
    <v-card-title style="gap: 8px; line-height: 32px">
      <NavButton />
      <h3 class="text-h5 font-weight-bold text-uppercase">
        Reasignación de administrador de contratos en el catálogo electrónico
      </h3>
    </v-card-title>
    <v-col>
      <DataTableComponent
        :headers="headers"
        :items="administradores.data"
        class="mt-8"
        dense
        no-gutters
        @paginar="manejarPaginacionRegistros"
        :total_registros="totalRegistros"
        :show_loading="administradores.isLoading"
        v-models:pagina="paginacion.pagina"
        v-models:select="paginacion.registrosPorPagina"
      >
        <template v-slot:item.solicitud_carrito="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:item.proceso_compra="{ item }">
          {{ item.ProcesoCompra.codigo_proceso }}
        </template>
        <template v-slot:item.descripcion_carrito="{ item }">
          {{ item.descripcion }}
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="abrirModal(item)"
                v-bind="attrs"
                v-on="on"
                large
                color="primary"
              >
                mdi-account-cog
              </v-icon>
            </template>

            <span>Reasignar administrador</span>
          </v-tooltip>
        </template>
      </DataTableComponent>
    </v-col>
    <CmModalReasignarContrato
      :is-open="abrirModalReasignancion"
      :administrador-contrato="usuariosContrato"
      :solicitud-compra="solicitudCompra"
      @close="abrirModalReasignancion = false"
      @recargar-administradores="recargarAdministradores"
    />
  </v-card>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import NavButton from "@/components/utils/NavButton.vue";
import {
  createLoadable,
  createPageable,
  setLoadableResponse,
  setPageableResponse,
  toggleLoadable,
  togglePageable,
} from "@/utils/loadable";
import CmModalReasignarContrato from "./components/CmModalReasignarContrato.vue";

export default {
  name: "CMReasignacionContrato",
  components: {
    NavButton,
    DataTableComponent,
    CmModalReasignarContrato,
  },
  data: () => ({
    headers: [
      {
        text: "Solicitud del carrito",
        align: "center",
        sortable: false,
        value: "solicitud_carrito",
      },
      {
        text: "Administrador de contrato",
        align: "center",
        sortable: false,
        value: "us_c.email",
      },
      {
        text: "Proceso compra",
        align: "center",
        sortable: false,
        value: "proceso_compra",
      },

      {
        text: "Descripcion carrito",
        align: "center",
        sortable: false,
        value: "descripcion_carrito",
      },
      { text: "Acciones", align: "center", sortable: false, value: "acciones" },
    ],
    administradores: createPageable([]),
    usuariosContrato: createLoadable([]),
    solicitudCompra: null,
    paginacion: {
      pagina: 1,
      registrosPorPagina: 10,
    },
    abrirModalReasignancion: false,
  }),
  computed: {
    totalRegistros() {
      return this.administradores.pagination.total_rows;
    },
  },
  methods: {
    manejarPaginacionRegistros(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.paginaActual = pagina;
      this.registrosPorPagina = cantidad_por_pagina;
      this.obtenerAdministradoresContrato();
    },
    abrirModal(data) {
      this.abrirModalReasignancion = true;
      this.solicitudCompra = data;
    },
    async obtenerAdministradoresContrato() {
      togglePageable(this.administradores);
      const { data, headers } =
        await this.services.CmHerramienta.obtenerAdministradorContratoCM();
      setPageableResponse(this.administradores, data, headers);
    },
    async cargarAdministradoresContrato() {
      toggleLoadable(this.usuariosContrato);
      const { data } = await this.services.Usuarios.obtenerUnidadesUsuarios();
      setLoadableResponse(this.usuariosContrato, data);
    },
    recargarAdministradores() {
      this.obtenerAdministradoresContrato();
    },
  },
  created() {
    this.obtenerAdministradoresContrato();
    this.cargarAdministradoresContrato();
  },
};
</script>
