<template>
  <section>
    <DashboardPerfil v-if="isProvider"></DashboardPerfil>
    <v-row v-else>
      <v-col cols="12" class="bgCardHeader rounded-lg">
        <v-img contain class="mt-8 mb-10 mx-auto" src="@/assets/img/comprasal_oficial.png" max-width="350" />
      </v-col>
      <v-col cols="12" class="text-center color-dark">
        <h1>Bienvenido a tu portal comprasal</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <adminActions  />
      </v-col>
    </v-row>

    <!-- v-cards de estadísticas, notificaciones y acciones rápidas -->
    <v-row>
      <!-- <v-col cols="12" md="12">
      <v-btn block class="primary" :loading="ordenesCompra.isLoading"  @click="generarOrden">GENERAR ORDENES DE COMPRA</v-btn>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4"
        v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS', 'ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_OFICIAL_CUMPLIMIENTO','ROLE_AUDITORIA_GLOBAL'])">
        <v-card class="rounded-lg">
          <v-card-title class="bgCardHeader rounded-top-lg">
            <h3 class="text-center white--text">Reporte de PAC</h3>
          </v-card-title>
          <v-card-text>
            <v-autocomplete v-model="pacFilters.year" :items="anios" label="Año" outlined dense item-value="anio"
              item-text="anio" hide-details clearable class="mt-4"></v-autocomplete>
            <v-autocomplete v-if="
              haveRoles([
                'ROLE_DIRECCION_COMPRAS',
                'ROLE_TECNICO_DIRECCION_COMPRAS',
                'ROLE_UACI',
                'ROLE_AUDITORIA_GLOBAL',
              ])
            " v-model="pacFilters.institucion" :items="instituciones" item-text="nombre" item-value="id"
              label="Institución" outlined dense hide-details return-object clearable class="mt-4"></v-autocomplete>

            <v-checkbox v-model="pacFilters.publico" label="Reporte público" class="my-4" color="primary"
              hide-details></v-checkbox>

            <v-btn :disabled="!activarBotonDescargarPac" color="primary" class="mt-4 mb-2" @click="downloadPac()">
              Generar reporte
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

       <v-col cols="12" sm="6" md="4" 
          v-if="haveRoles(['ROLE_UACI'])" >
          <CardComponent @showTable="cargar" @limpiarData="limpiarDatos" @showAttachment="showAttachment"/>
       </v-col>
       
       <v-col>
        <!-- Tabla -->
        <DataTableComponent v-if="documentos.data && documentos.data.length > 0" :headers="headers"
          :items="documentos.data" :show_loading="documentos.isLoading" :tiene_paginacion="false">
          <template v-slot:item.estado="{ item }">
            <v-chip :color="obtenerEstadoDocumento(item).color" class="white--text py-2 mx-auto"
              style="max-width: 250px;" label>
              <div class="d-flex align-center" style="gap: 8px;">
                <v-icon color="white" size="20px !important">{{ obtenerEstadoDocumento(item).icono
                  }}</v-icon>
                <span>{{ obtenerEstadoDocumento(item).texto }}</span>
              </div>
            </v-chip>
          </template>
          <template v-slot:item.numero_proceso="{ item }">
            {{ item.documento?.numero_proceso ?? '-' }}
          </template>
          <template v-slot:item.avance="{ item }">
            {{
              item.documento?.numero_proceso > 0
                ? `${item.procesos_pac} (${mostrarPorcentaje(item.porcentaje_avance)})`
                : '-'
            }}
          </template>
          <template v-slot:item.aprobacion="{ item }">
            <div v-if="item.documento?.created_at || item.documento?.aprobado_en || item.documento?.fecha_aprobacion"
              class="py-4">
              <v-tooltip v-if="item.documento?.created_at" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" class="d-flex align-center justify-center" style="gap: 8px;">
                    <v-icon size="20px !important">mdi-file-upload</v-icon>
                    {{ formatDate(item.documento.created_at) }}
                  </div>
                </template>
                <span>Fecha de carga</span>
              </v-tooltip>

              <v-tooltip v-if="item.documento?.aprobado_en" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" class="d-flex align-center justify-center mt-4" style="gap: 8px;">
                    <v-icon size="20px !important">mdi-laptop</v-icon>
                    {{ formatDate(item.documento.aprobado_en) }}
                  </div>
                </template>
                <span>Aprobación en sistema</span>
              </v-tooltip>

              <v-tooltip v-if="item.documento?.fecha_aprobacion" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" class="d-flex align-center justify-center mt-4" style="gap: 8px;">
                    <v-icon size="20px !important">mdi-account-check</v-icon>
                    {{ formatDate(item.documento.fecha_aprobacion) }}
                  </div>
                </template>
                <span>Fecha de aprobación</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.acciones="{ item }">
            <div class="d-flex align-center justify-center" style="gap: 16px;">
              <v-btn :disabled="!item.documento" @click.stop="showAttachment(item)" icon>
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </div>
          </template>
        </DataTableComponent>
        <PdfModal :is-open.sync="isPdfModalOpen" :source-loadable="pdfLoadable" filename="documento" />
      </v-col>

      <v-col cols="12" sm="6" md="4" v-if="haveRoles(['ROLE_HERRAMIENTA_ELIMINAR_ETAPAS_CONTRATO','ROLE_CM_HERRAMIENTA_CATALOGO_ELECTRONICO']) && !haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_DIRECCION_COMPRAS'])">
        <ObtenerProcesoContrato />
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="haveRoles(['ROLE_CM_HERRAMIENTA_CATALOGO_ELECTRONICO']) && !haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_DIRECCION_COMPRAS'])">
        <CMDetalleSolicitudCompra />
      </v-col>
    </v-row>
    <ConfirmationDialog :show="modalConfirmacionOrden" title="¿DESEAS GENERAR LAS ORDENES DE COMPRA?"
      message="Se crearan todas las ordenes de compra pendientes." btnConfirmar="CONFIRMAR"
      @close="modalConfirmacionOrden = false" @confirm="crearOrdenCompra" :showLoading="ordenesCompra.isLoading" />
  </section>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import adminActions from '@/views/AdminActions/index.vue';
import DashboardPerfil from "./PerfilProveedor/perfilProveedorView.vue";
import store from "../store";
import { mapState, mapMutations } from "vuex";
import { createLoadable, setLoadableResponse, toggleLoadable, createPageable } from "@/utils/loadable";
import ObtenerProcesoContrato from '@/views/DashboardComponents/ObtenerProcesoContrato.vue';
import CMDetalleSolicitudCompra from "./DashboardComponents/CMDetalleSolicitudCompra.vue";
import DataTableComponent from '@/components/DataTableComponent.vue';
import { formatDate } from '@/utils/datetime';
import PdfModal from '@/components/PdfModal.vue';
import { mostrarPorcentaje } from '@/utils/data';

export default {
  name: "dashboardView",
  components: {
    DashboardPerfil,
    ConfirmationDialog,
    adminActions,
    ObtenerProcesoContrato,
    CMDetalleSolicitudCompra,
    CardComponent,
    DataTableComponent,
    PdfModal
  },
  data: () => ({
    isPdfModalOpen: false,
    pdfLoadable: createLoadable(null),
    confirmacion_modal: false,
    modalConfirmacionOrden: false,
    ordenesCompra: createLoadable([]),
    isProvider: false,
    anios: [],
    tour: null,
    pacFilters: {
      year: new Date().getFullYear(),
      institucion: null,
    },
    instituciones: [],
    headers: [
      { text: 'Institución', value: 'nombre', align: 'center', sortable: false },
      { text: 'Estado', value: 'estado', align: 'center', sortable: false },
      { text: 'Número de procesos', value: 'numero_proceso', align: 'center', sortable: false },
      { text: '% Avance', value: 'avance', align: 'center', sortable: false },
      { text: 'Aprobación', value: 'aprobacion', align: 'center', sortable: false },
      { text: 'Documento', value: 'acciones', align: 'center', sortable: false },
    ],
    documentos: [],
  }),
  methods: {
    formatDate,
    ...mapMutations("agregarInsumo", ["setGetTour"]),
    mostrarPorcentaje,
    async getTours() {
      const response = await this.services.TourService.getTour();
      this.tour = response.data;
      this.setGetTour(this.tour);
    },
    async getInstitituciones() {
      const response =
        await this.services.Instituciones.getCatalogoInstituciones({
          pagination: false,
        });
      this.instituciones = response.data;
    },
    async downloadPac() {

      const response = await this.services.PacServices.descargarReportePac(
        this.pacFilters.institucion?.id ||
        this.selectedUnidad.instituciones.id,
        this.pacFilters.publico,
        {
          anio_calendario: this.pacFilters.year,
        }
      );

      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const file = new File([blob], this.pacFilters.institucion?.nombre, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = `ReportePAC-${this.pacFilters?.year}.xlsx`;
      link.click();

    },
    generarOrden() {
      this.modalConfirmacionOrden = true;
    },
    async crearOrdenCompra() {
      toggleLoadable(this.ordenesCompra)
      const { data } = await this.services.cmAprobacionSolicitudCompra.generarOrdenesPendientes();
      setLoadableResponse(this.ordenesCompra, data);
      this.modalConfirmacionOrden = false;
    },
    cargar (item) {
      this.documentos = item;
    },
    obtenerEstadoDocumento(institucion) {
      const estados = {
        'pendiente': { texto: 'Documento pendiente', icono: 'mdi-file-document-alert', color: "info" },
        'cargado': { texto: 'Documento cargado', icono: 'mdi-file-upload', color: "primary" },
        'aprobado': { texto: 'Documento aprobado', icono: 'mdi-file-document-check', color: "success" },
      }

      const documento = institucion.documento;

      if (!documento) return estados.pendiente;

      return documento.aprobado ? estados.aprobado : estados.cargado;
    },
    limpiarDatos() {
      this.documentos = createPageable([], 10);
      this.pacFilters.year = new Date().getFullYear();
      this.pacFilters.institucion = null;
    },
    async showAttachment(item) {
      this.isPdfModalOpen = true;
      toggleLoadable(this.pdfLoadable);
      const { data } = await this.services.ReporteriaUCP.visualizarDocumento(item.documento.id)
      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
  },
  computed: {
    ...mapState(["selectedUnidad"]),
    async years() {
      const { data } = await this.services.Auth.obtenerAniosFiscales();

      // const currentYear = new Date().getFullYear();
      // const years = [];
      // for (let i = currentYear; i <= currentYear + 5; i++) {
      //   years.push(i);
      // }
      return data;
    },
    activarBotonDescargarPac() {
      if (this.haveRoles(["ROLE_DIRECCION_COMPRAS", "ROLE_TECNICO_DIRECCION_COMPRAS", "ROLE_UACI", "ROLE_AUDITORIA_GLOBAL"])) {
        return (
          this.pacFilters.institucion?.id && this.pacFilters.year
        );
      } else if (this.haveRoles(["ROLE_UACI", "ROLE_UACI_TECNICO", 'ROLE_OFICIAL_CUMPLIMIENTO'])) {
        return this.pacFilters.year;
      }
    },
  },
  mounted() {
    this.getTours();
    this.isProvider = store.state.userInfo.roles.includes(
      "ROLE_FRONT_PROFILE_PROVIDER"
    );
  },
  async created() {
    const { data } = await this.services.Auth.obtenerAniosFiscales();
    this.anios = data;
    if (this.haveRoles(["ROLE_DIRECCION_COMPRAS", "ROLE_TECNICO_DIRECCION_COMPRAS", "ROLE_UACI",, "ROLE_AUDITORIA_GLOBAL"])) {
      await this.getInstitituciones();
    }
  },
};
</script>
