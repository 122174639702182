<template>
  <v-dialog v-model="abrirModal" persistent max-width="1100">
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center mb-4">
        <v-icon class="mr-2">mdi-information-slab-circle</v-icon>
        Información complementaria de la etapa
      </v-card-title>
      <v-divider class="mb-5" />
      <v-card-text>
        <div v-if="hasDataEtapa">
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Etapa</p>
              <p>{{ dataDetalle?.etapa ?? "-" }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Cantidad a pagar</p>
              <p>
                $
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(dataDetalle?.cantidad_pagar)
                }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Fecha posible de recepción</p>
              <p>
                {{
                  dataDetalle?.fecha_inicio
                    ? moment(dataDetalle?.fecha_inicio).format(
                        "DD/MM/YYYY hh:mm a"
                      )
                    : "-"
                }}
              </p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Fecha recepción</p>
              <p>
                {{
                  dataDetalle?.fecha_fin
                    ? moment(dataDetalle?.fecha_fin).format(
                        "DD/MM/YYYY hh:mm a"
                      )
                    : "-"
                }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Estado</p>
              <p>
                {{
                  dataDetalle?.completado === true ? "Entregado" : "Pendiente"
                }}
              </p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Usuario que registra</p>
              <p>{{ dataDetalle?.usuario?.email ?? "-" }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Observación</p>
              <p>{{ dataDetalle?.descripcion ?? "-" }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Descripción de la entrega</p>
              <p>{{ dataDetalle?.descripcion_entrega ?? "-" }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Ponderación de calificación</p>
              <p>{{ dataDetalle?.calificacion?.ponderacion ?? "-" }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">
                Fecha que registra calificación
              </p>
              <p>
                {{
                  dataDetalle?.calificacion?.fecha_hora
                    ? moment(dataDetalle?.calificacion.fecha_hora).format(
                        "DD/MM/YYYY hh:mm a"
                      )
                    : "-"
                }}
              </p>
            </v-col>
          </v-row>

          <v-card-title class="d-flex justify-center mb-4">
            <v-icon class="mr-2">mdi-cash</v-icon>
            Pagos
          </v-card-title>
          <v-divider class="mb-5" />

          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="movimientos"
                item-value="correlativo"
                no-data-text="No hay datos para mostrar"
                :item-class="getRowClass"
                :loading="etapasPorContrato.isLoading"
              >
                <template v-slot:item.numero_pago="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.fecha_creacion="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YY hh:mm A") }}
                </template>
                <template v-slot:item.tipo_movimiento="{ item }">
                  {{ item.tipo_movimiento }}
                </template>
                <template v-slot:item.desembolso="{ item }">
                  {{ item.desembolso ? item.desembolso : "-" }}
                </template>
                <template v-slot:item.amortizacion_anticipo="{ item }">
                  {{
                    item.amortizacion_anticipo
                      ? item.amortizacion_anticipo
                      : "-"
                  }}
                </template>
                <template v-slot:item.monto="{ item }">
                  ${{ Number(item.monto).toFixed(2) }}
                </template>
                <template v-slot:item.acciones="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :disabled="!archivoSubido || !!item.deleted_at"
                        v-on="on"
                        color="red"
                        @click.stop="abrirModalConfirmacionEliminar(item)"
                      >
                        mdi-delete-outline
                      </v-icon>
                    </template>
                    Eliminar pago
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center mt-6">
        <v-btn class="primary mr-4" @click.stop="$emit('cerrar')">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationModalComponent
      :isOpen="modalConfirmacionEliminar"
      description="¿Desea continuar con la acción?"
      @confirm="confirmarEliminarCertificacion"
      @cancel="modalConfirmacionEliminar = false"
      :isLoading="eliminadoPago.isLoading"
    />
  </v-dialog>
</template>
<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";

export default {
  name: "modalComplementoEtapa",
  components: {
    DataTableComponent,
    ConfirmationModalComponent,
  },

  props: {
    abrirModal: {
      type: Boolean,
      default: false,
    },
    dataDetalle: {
      type: [Object, Array], // Acepta tanto un objeto como un arreglo
      default: () => ({}),
    },
    archivoSubido: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    etapasPorContrato: createLoadable(null),
    eliminadoPago: createLoadable([]),
    modalConfirmacionEliminar: false,
    id_movimiento: null,
    headers: [
      {
        text: "Numero de pago",
        value: "numero_pago",
        align: "center",
      },
      {
        text: "Fecha de creacion",
        value: "fecha_creacion",
        align: "center",
      },
      {
        text: "Tipo de movimiento",
        value: "tipo_movimiento",
        align: "center",
      },
      {
        text: "Desembolso",
        value: "desembolso",
        align: "center",
      },
      {
        text: "Armotización Anticipo",
        value: "amortizacion_anticipo",
        align: "center",
      },
      {
        text: "Monto",
        value: "monto",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
  }),
  computed: {
    hasDataEtapa() {
      return this.dataDetalle && Object.keys(this.dataDetalle).length > 0;
    },
    movimientos() {
      return this.etapasPorContrato.data ?? [];
    },
    idProceso() {
      return this?.$route?.params?.idProceso;
    },
  },
  methods: {
    abrirModalConfirmacionEliminar(movimiento) {
      this.modalConfirmacionEliminar = true;
      this.id_movimiento = movimiento.id;
    },

    async informacionPagos() {
      toggleLoadable(this.etapasPorContrato);
      const { data } =
        await this.services.EliminarEtapasContratoServices.obtenerPagosPorEtapa(
          this.dataDetalle.id
        );
      setLoadableResponse(this.etapasPorContrato, data);
    },
    async confirmarEliminarCertificacion() {
      toggleLoadable(this.eliminadoPago);
      const { data } =
        await this.services.EliminarEtapasContratoServices.eliminarPago(
          this.id_movimiento,
          { id_proceso_compra: this.idProceso, id_etapa: this.dataDetalle.id }
        );
      setLoadableResponse(this.eliminadoPago, data);
      this.modalConfirmacionEliminar = false;
      this.informacionPagos();
    },
    getColor(status_name) {
      const status_color = status_name === true ? "Entregada" : "Pendiente";
      switch (status_color) {
        case "Pendiente":
          return "pendiente";
        case "Entregada":
          return "vigente";
      }
    },
    getRowClass(item) {
      if (item.deleted_at) {
        return "highlight-row";
      }
      return "";
    },
  },
  watch: {
    abrirModal(value) {
      if (value) {
        this.informacionPagos();
      }
    },
  },
};
</script>
<style>
.highlight-row {
  background-color: #ecb36d;
}
</style>
