<template>
  <div class="mx-8" no-gutters v-if="listadoLotes.length > 0">
    <span
      class="text-sm-h5 mt-8"
      v-text="'Seleccione los lotes a los que aplica'"
      v-if="haveRole('ROLE_PROVEEDOR')"
    />
    <v-expansion-panels focusable accordion flat class="mt-8">
      <v-expansion-panel
        v-for="(lote, i) in listadoLotes"
        :key="i"
        class="panel"
      >
        <v-expansion-panel-header class="panel">
          <div class="d-flex flex-row align-center">
            <span class="mt-2 mr-6" v-text="lote.LotesProceso?.nombre ? lote.LotesProceso?.nombre : lote?.lote.nombre" />
            <!-- <span class="mt-2 mr-6" v-text="lote.LotesProceso?.nombre" /> -->

            <v-btn
              :dark="!lote.estado"
              :outlined="lote.estado"
              class="text-no-style btn-extra"
              color="secondary"
              @click.native.stop="fillCantidadAdjudicada(lote)"
            >
              {{
                lote.estado
                  ? "Eliminar la asignación de lote"
                  : "Asignar todos los lotes"
              }}
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-5">
          <data-table-component
            :headers="headersObs"
            :items="lote.obs"
            mobile_breakpoint="725"
            :tiene_paginacion="false"
            :custom_item_per_page="lote.obs.length"
          >
            <template v-slot:[`item.cantidad_adjudicada`]="{ item }">
              <v-text-field
                dense
                @input="validateMonto(item)"
                class="mt-2"
                outlined
                type="number"
                @keypress="validateIntegerInput"
                v-model="item.cantidad_adjudicada"
              />
            </template>
            <template v-slot:[`item.monto`]="{ item }">
              <v-text-field
                :disabled="
                  disableMonto[item.id] === undefined ||
                  disableMonto[item.id] === true
                "
                dense
                v-mask="currencyMask"
                class="mt-2"
                outlined
                v-model="item.monto"
              />
            </template>
          </data-table-component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row no-gutters class="my-8">
      <v-btn
        text
        class="mt-5 secondary--text text-no-style mx-3"
        v-text="'Volver'"
        @click="$router.back()"
      />
      <v-btn
        v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
        class="mt-5 text-no-style mx-3 white--text"
        color="secondary"
        :disabled="validarGuardar"
        v-text="'Guardar'"
        @click="saveOferta"
      />
    </v-row>
  </div>
</template>
<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import DataTableComponent from "../../../components/DataTableComponent.vue";
import { mapState } from "vuex";

export default {
  name: "lotesOfertaFinancieraComponent",
  props: {
    proveedorOferta: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DataTableComponent,
  },
  data: () => ({
    headersObs: [
      {
        text: "OBS",
        align: "start",
        value: "nombre",
      },
      {
        text: "Unidad",
        value: "unidad",
      },
      {
        text: "Cantidad sin adjudicar",
        value: "cantidad_sin_adjudicar",
        align: "center",
        sortable: false,
      },
      {
        text: "Cantidad adjudicada",
        value: "cantidad_adjudicada",
        sortable: false,
      },
      {
        text: "Monto",
        value: "monto",
      },
    ],
    disableMonto: [],
    timer: null,
  }),
  methods: {
    validateMonto(item) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        let tempCantidadSinAdjudicar = this.$route.params.id_oferta
          ? Number(item.total_adjudicado_inicial) +
            Number(item.total_sin_adjudicar_inicial)
          : Number(item.total_sin_adjudicar_inicial);

        if (Number(item.cantidad_adjudicada) > tempCantidadSinAdjudicar) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message:
              "Cantidad adjudicada no debe ser mayor a cantidad sin adjudicar",
          });
          this.disableMonto[item.id] = true;
          if (this.$route.params.id_oferta)
            item.cantidad_sin_adjudicar =
              Number(item.total_adjudicado_inicial) +
              Number(item.total_sin_adjudicar_inicial);
          else
            item.cantidad_sin_adjudicar = Number(
              item.total_sin_adjudicar_inicial
            );
          item.monto = null;
        } else if (
          Number(item.cantidad_adjudicada) == 0 ||
          Number(item.cantidad_adjudicada) == ""
        ) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message: "Cantidad adjudicada no puede ir vacía o igual a cero",
          });
          if (this.$route.params.id_oferta)
            item.cantidad_sin_adjudicar =
              Number(item.total_adjudicado_inicial) +
              Number(item.total_sin_adjudicar_inicial);
          else
            item.cantidad_sin_adjudicar = Number(
              item.total_sin_adjudicar_inicial
            );
          this.disableMonto[item.id] = true;
          item.monto = null;
        } else if (Number(item.cantidad_adjudicada) < 0) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message: "Cantidad adjudicada no debe tener valores negativos",
          });
          if (this.$route.params.id_oferta)
            item.cantidad_sin_adjudicar =
              Number(item.total_adjudicado_inicial) +
              Number(item.total_sin_adjudicar_inicial);
          else
            item.cantidad_sin_adjudicar = Number(
              item.total_sin_adjudicar_inicial
            );
          this.disableMonto[item.id] = true;
          item.monto = null;
        } else {
          if (this.$route.params.id_oferta) {
            item.cantidad_sin_adjudicar =
              Number(item.total_adjudicado_inicial) +
              Number(item.total_sin_adjudicar_inicial) -
              Number(item.cantidad_adjudicada);
          } else {
            item.cantidad_sin_adjudicar =
              Number(item.total_sin_adjudicar_inicial) -
              Number(item.cantidad_adjudicada);
          }
          this.disableMonto[item.id] = false;
        }
      }, 500);
    },
    validateIntegerInput(event) {
      const char = String.fromCharCode(event.keyCode);
      // Permitir solo números enteros
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    calcularMonto(item) {
      if (item.total_sin_adjudicar_inicial == 0) {
        item.cantidad_sin_adjudicar = item.total_adjudicado_inicial;
      } else {
        item.cantidad_sin_adjudicar =
          Number(item.total_sin_adjudicar_inicial) -
          Number(item.total_adjudicado_inicial);
      }

      if (
        Number(item.cantidad_adjudicada) > Number(item.cantidad_sin_adjudicar)
      ) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message:
            "No puede utilizar una cantidad mayor a la cantidad sin adjudicar",
        });
        item.cantidad_sin_adjudicar = Number(item.total_sin_adjudicar_inicial);
        return (item.monto = 0);
      } else {
        let precio_uni = item.monto_sub_proceso / item.cantidad_sin_adjudicar;
        if (precio_uni === Infinity || precio_uni === -Infinity) {
          precio_uni = 0;
        }

        let cantidad = item.cantidad_adjudicada * precio_uni;
        item.cantidad_sin_adjudicar =
          Number(item.total_sin_adjudicar_inicial) -
          Number(item.cantidad_adjudicada) +
          Number(item.total_adjudicado_inicial);

        return (item.monto = Intl.NumberFormat("en-US", {
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(cantidad));
      }
    },
    fillCantidadAdjudicada(item) {
      item.estado = !item.estado;
      let index = this.listadoLotes.indexOf(item);
      if (item.estado) {
        this.listadoLotes[index].obs.forEach((element) => {
          this.disableMonto[element.id] = false;
          if (this.$route.params.id_oferta) {
            element.cantidad_sin_adjudicar = 0;

            element.cantidad_adjudicada =
              Number(element.total_adjudicado_inicial) +
              Number(element.total_sin_adjudicar_inicial);
          } else {
            element.cantidad_sin_adjudicar = 0;
            element.cantidad_adjudicada = Number(
              element.total_sin_adjudicar_inicial
            );
          }
        });
      } else {
        this.listadoLotes[index].obs.forEach((element) => {
          element.cantidad_adjudicada = "";
          element.monto = "";
          this.disableMonto[element.id] = true;
          if (this.$route.params.id_oferta) {
            element.cantidad_sin_adjudicar =
              Number(element.total_adjudicado_inicial) +
              Number(element.total_sin_adjudicar_inicial);
          } else {
            element.cantidad_sin_adjudicar = Number(
              element.total_sin_adjudicar_inicial
            );
          }
        });
      }
    },
    async saveOferta() {

      let body = {};

      let response = null;

      if (this.$route.params.id_oferta) {

        body = {
          id_proveedor:
            this.proveedorOferta.ParticipacionProveedor?.id_proveedor,
          id_proveedor_no_registrado: this.proveedorOferta.ParticipacionProveedor?.id_proveedor_no_registrado,
          lotes: this.listadoLotes.map((col) => ({
            id_lote: col.id_lote,
            items: col.obs.map((col2) => ({
              id_sub_proceso_obs: col2.id,
              cantidad: col2.cantidad_adjudicada,
              monto: Number(col2.monto.toString().replaceAll(',', '')),
              id_ganador_obs: col2.id_ganador_obs,
            })),
          })),
        };
        response = await this.services.PeoServices.updateGanadoresProcesos(
          this.$route.params.id_proceso,
          body
        )
      } else {
        body = {
          id_proveedor:
            this.proveedorOferta.ParticipacionProveedor?.id_proveedor,
          id_proveedor_no_registrado: this.proveedorOferta.ParticipacionProveedor?.id_proveedor_no_registrado,
          lotes: this.listadoLotes.map((col) => ({
            id_lote: col.id_lote,
            items: col.obs
              .filter(
                (target) =>
                  Number(target.cantidad_adjudicada) !== 0 &&
                  Number(target.monto) !== 0
              )
              .map((col2) => ({
                id_sub_proceso_obs: col2.id,
                cantidad: col2.cantidad_adjudicada,
                monto: Number(col2.monto.toString().replaceAll(',', '')),
                id_ganador_obs: col2.id_ganador_obs,
              })),
          })),
        };
        response = await this.services.PeoServices.saveGanadoresProcesos(
          this.$route.params.id_proceso,
          body
        )
      }

      if (response.status == 200 || response.status == 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se ha adjudicado al ganador correctamente",
        });

        this.$router.back();
      }


    },
  },
  computed: {
    ...mapState("peoStore", ["listadoLotes", "ofertaInfo"]),
    validarGuardar() {
      if (!this.$route.params.id_oferta) {
        let itemsConCantidadAdjudicada = [];
        let itemsConMonto = [];

        this.listadoLotes.forEach((lote) => {
          lote.obs.forEach((item) => {
            if (![0, '0', "", null].includes(item.cantidad_adjudicada)) {
              itemsConCantidadAdjudicada.push(item);
            }

            if (![0, '0', "", null].includes(item.monto)) {
              itemsConMonto.push(item);
            }
          });
        });

        return (
          itemsConCantidadAdjudicada.length != itemsConMonto.length ||
          (itemsConCantidadAdjudicada.length == 0 && itemsConMonto.length == 0)
        );
      } else return false;
    },
    currencyMask() {
      return createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        decimalLimit: 8,
      });
    },
    numberMask() {
      return createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: false,
        allowNegative: false,
        decimalLimit: 8,
      });
    },
  },
};
</script>
