<template>
  <section v-if="permisos.leer">
    <v-row>
      <v-col
        cols="12"
        sm="3"
        v-if="
          etapa.id_tipo_etapa === 6 &&
          haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])
        "
      >
        <v-btn
          block
          v-if="false"
          color="secondary"
          @click="addGanadorModal = true"
          >Añadir ganador</v-btn
        >
      </v-col>
    </v-row>
    <p>Adjudicación realizada</p>
    <v-data-table
      class="mt-4"
      v-if="!esSubasta()"
      :headers="headersGanadores"
      :items="ganadores"
    >
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          style="display: block"
          label
          :color="Number(item?.contratos) > 0 ? 'success' : 'info'"
          class="bgWhite--text"
        >
          {{ Number(item?.contratos) > 0 ? "Contratado" : "Pendiente" }}
        </v-chip>
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            style: "currency",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item?.monto || 0)
        }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <!-- <template
          v-if="Number(item?.contratos) > 0 && haveRoles(['ROLE_UACI'])"
        >
          <v-btn
            @click="(modalGarantia = true), (id_contrato = item?.contratos)"
            >Adjuntar Garantía</v-btn
          >
        </template> -->
        <div v-if="etapa.id_tipo_etapa === 2">
          <v-btn
            v-if="!(Number(item.contratos) > 0)"
            color="secondary"
            @click="generarContrato(item)"
            >Generar contrato</v-btn
          >
          <v-btn
            v-if="
              Number(item.contratos) > 0 &&
              !haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])
            "
            color="secondary"
            @click="generarContrato(item)"
            >Seguimiento</v-btn
          >
          <v-btn
            v-if="!tieneDocumentoEnContrato(item)"
            color="secondary"
            @click="abrirModal(item)"
            >Cargar documento</v-btn
          >
        </div>
      </template>
    </v-data-table>

    <v-data-table
      class="mt-4"
      v-if="esSubasta()"
      :headers="headersGanadoresSubasta"
      :items="ganadores"
    >
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          style="display: block"
          label
          :color="Number(item?.contratos) > 0 ? 'success' : 'info'"
          class="bgWhite--text"
        >
          {{ Number(item?.contratos) > 0 ? "Contratado" : "Pendiente" }}
        </v-chip>
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        $
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item?.monto || 0)
        }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">

        <!-- <template
          v-if="Number(item?.contratos) > 0 && haveRoles(['ROLE_UACI'])"
        >
          <v-btn
            @click="(modalGarantia = true), (id_contrato = item?.contratos)"
            >Adjuntar Garantía</v-btn
          >
        </template> -->
        <template v-if="Number(item?.contratos) > 0"> </template>
        <template v-if="etapa.id_tipo_etapa === 2">
          <v-btn
            v-if="!(Number(item.contratos) > 0)"
            color="secondary"
            @click="generarContrato(item)"
            >Generar contrato</v-btn
          >
          <v-btn
            v-if="
              Number(item.contratos) > 0 &&
              !haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])
            "
            color="secondary"
            @click="generarContrato(item)"
            >Seguimiento</v-btn
          >
          <v-btn
            v-if="!tieneDocumentoEnContrato(item)"
            color="secondary"
            @click="abrirModal(item)"
            >Cargar documento</v-btn
          >
        </template>
      </template>
    </v-data-table>

    <v-dialog persistent v-model="addGanadorModal" max-width="600">
      <v-card class="px-4 py-4">
        <v-card-text>
          <h5 class="text-h5 secondary--text mb-4">Agregar ganador</h5>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.proveedor"
                :items="listProveedores"
                @keyup="isLoading = true"
                item-text="nombre"
                item-value="id"
                outlined
                :loading="isLoading"
                label="Proveedor *"
                :error-messages="proveedorErrors"
                @input="$v.form.proveedor.$touch()"
                @blur="$v.form.proveedor.$touch()"
                @update:search-input="getProveedores"
                @click:clear="form.proveedor = null"
                clearable
                hide-no-data
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <money-text-field-component
                ref="monto"
                label="Monto a adjudicar"
                v-model="form.monto"
                outlined
                :error-messages="montoErrors"
                @input="$v.form.monto.$touch()"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="display-flex justify-end">
            <v-col cols="12" sm="3">
              <v-btn
                block
                outlined
                color="secondary"
                @click="addGanadorModal = false"
                >Volver</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn block color="secondary" @click="guardarGanador"
                >Guardar ganador</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ModalDocumentoContrato
      :is-open="modalDocumento"
      @close="modalDocumento = false"
      @on-save="guardarDocumentoContrato"
      :tipo-contrato="tipoContrato"
      :is-loading="guardadoDocumento.isLoading"
    />
  </section>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import { convertToFormData } from "@/utils/data";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import ModalDocumentoContrato from "./ModalDocumentoContrato.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";

export default {
  name: "ContratacionComponent",
  components: {
    MoneyTextFieldComponent,
    ModalDocumentoContrato,
  },
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    proceso: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    modalGarantia: false,
    id_contrato: null,
    formG: {
      documento: null,
      nombre_documento: null,
      vigencia_desde: null,
      vigencia_hasta: null,
    },
    config_calendar: {
      visibility: "click",
    },
    headersGanadores: [
      {
        text: "Proveedor",
        value: "nombre",
      },
      {
        text: "Monto",
        value: "monto",
        align: "right",
      },
      {
        text: "Estado contrato",
        value: "estado",
        align: "center",
        width: "20%",
      },
    ],
    headersGanadoresSubasta: [
      {
        text: "Proveedor",
        value: "nombre",
      },
      {
        text: "Monto",
        value: "monto",
        align: "right",
      },
      {
        text: "Estado contrato",
        value: "estado",
        align: "center",
        width: "20%",
      },
    ],
    ganadores: [],
    fondoProyectoTrue: false,
    esFondoCertificado: false,
    addGanadorModal: false,
    proveedor: null,
    listProveedores: [],
    isLoading: false,
    form: {
      proveedor: null,
      monto: null,
    },
    timer: null,
    fondosCertificados: false,
    modalDocumento: false,
    contratoSeleccionado: null,
    tipoContrato: createLoadable([]),
    guardadoDocumento: createLoadable([]),
    verificar: createLoadable([]),
  }),
  validations: {
    form: {
      proveedor: {
        required,
      },
      monto: {
        required,
      },
    },
  },
  computed: {
    montoErrors() {
      const errors = [];
      if (!this.$v.form.monto.$dirty) return errors;
      !this.$v.form.monto.required &&
        errors.push("El campo monto a adjudicar es obligatorio");
      return errors;
    },
    proveedorErrors() {
      const errors = [];
      if (!this.$v.form.proveedor.$dirty) return errors;
      !this.$v.form.proveedor.required &&
        errors.push("El campo proveedor es obligatorio");
      return errors;
    },
  },
  methods: {
    tieneDocumentoEnContrato(item) {
      const contratos = this.verificar.data.contratos.find(documento => documento.idContrato === item.contratos);
      
      return contratos ? contratos.tieneDocumentoCargado : true;
    },
    resetFormG() {
      this.formG = {
        documento: null,
        nombre_documento: null,
        vigencia_desde: null,
        vigencia_hasta: null,
      };
      this.modalGarantia = false;
    },
    calcularFecha(fecha) {
      if (!fecha) return "Aún no seleccionada";
      return moment(fecha).format("DD/MM/YYYY");
    },
    ...mapMutations("agregarInsumo", ["setCurrentProvider", "setProviderInfo"]),
    async getProveedores(e) {
      if (this.timer) {
        data;
        clearTimeout(this.timer);
      }
      if (this.form.proveedor > 0) {
        return;
      }

      this.timer = setTimeout(async () => {
        let params = {
          busqueda: e,
        };

        const { data } =
          await this.services.PacProcesos.getProveedoresParaProceso(params);
        const elements = [];
        data.forEach((proveedor) => {
          elements.push({
            id: proveedor.id,
            nombre: proveedor.nombre,
          });
        });
        this.listProveedores = elements;
        this.isLoading = false;
      }, 500);
    },
    async guardarGarantia() {
      if (!this.formG.nombre_documento)
        return this.temporalAlert({
          show: true,
          type: "error",
          message: "El nombre del documento es requerido",
        });
      if (!this.formG.documento)
        return this.temporalAlert({
          show: true,
          type: "error",
          message: "El archivo es requerido",
        });

      await this.services.ContratoService.agregarGarantia(
        this.id_contrato,
        convertToFormData({ ...this.formG, retorno: "false" })
      );
      this.resetFormG();
    },
    async guardarGanador() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const response = await this.services.PacProcesos.saveGanadorProceso(
          this.$route.params.idProceso,
          {
            id_proveedor: this.form.proveedor,
            monto: this.form.monto,
          }
        );

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Se ha guardado el ganador con éxito",
          });
          this.addGanadorModal = false;
          this.form.proveedor = null;
          this.form.monto = null;
          this.$refs.monto.updateValue(this.form.monto);
          await this.obtenerGanadores();
        }
      }
    },
    async obtenerGanadores() {
      if (!this.permisos.leer || this.haveRole("ROLE_PROVEEDOR")) return;

      if (!this.esSubasta()) {
        const response =
          await this.services.PacProcesos.getParticipantesGanadores(
            this.$route.params.idProceso
          );

        if (response.status === 200) {
          response.data.ganadores.forEach((ganador) => {
            const dataGanador = {};

            dataGanador.id_ganador = ganador.id_ganador_obs;
            dataGanador.contratos = ganador?.id_contrato;
            dataGanador.nombre = ganador?.nombre_comercial;
            dataGanador.monto = ganador.monto_adjudicado;
            dataGanador.id_usuario = ganador?.id_usuario;
            dataGanador.is_disabled = ganador?.is_disabled;
            dataGanador.nombre_contrato = ganador?.nombre_contrato;
            this.fondoProyectoTrue = response.data.esFondoProyecto;
            this.esFondoCertificado = response.data.notieneFondosCertificados;
            this.ganadores.push(dataGanador);
            this.$store.commit("procesoCompraDoc/setGanadores", this.ganadores);
          });
        }
      } else {
        const response = await this.services.PacProcesos.getGanadorSubasta(
          this.$route.params.idProceso
        );

        if (response.status === 200) {
          response.data.listGanadores.forEach((ganadorSubasta) => {
            const dataGanadorSubasta = {};

            dataGanadorSubasta.id_ganador = ganadorSubasta?.id_ganador_obs;
            dataGanadorSubasta.contratos = ganadorSubasta?.id_contrato;
            dataGanadorSubasta.nombre = ganadorSubasta?.nombre_comercial;
            dataGanadorSubasta.monto = ganadorSubasta?.monto;
            dataGanadorSubasta.id_usuario = ganadorSubasta?.id_usuario;
            dataGanadorSubasta.is_disabled = ganadorSubasta?.is_disabled;
            dataGanadorSubasta.nombre_contrato =
              ganadorSubasta?.nombre_contrato;
            this.fondoProyectoTrue = response.data.esFondoProyecto;
            this.esFondoCertificado = response.data.notieneFondosCertificados;

            this.ganadores.push(dataGanadorSubasta);
            this.$store.commit("procesoCompraDoc/setGanadores", this.ganadores);
          });
        }
      }
    },
    async generarContrato(item) {
      // if (this.esFondoCertificado) {
      //   this.temporalAlert({
      //     show: true,
      //     type: "warning",
      //     message:
      //       "Debe realizar la certificación de fondos para continuar la generación del contrato",
      //   });
      //   return;
      // } else if (this.fondoProyectoTrue) {
      //   this.temporalAlert({
      //     show: true,
      //     type: "warning",
      //     message: "No se puede generar contratos con fondos de tipo proyecto",
      //   });
      //   return;
      // } else if (item.is_disabled) {
      //   this.temporalAlert({
      //     show: true,
      //     type: "warning",
      //     message: "Usuario deshabilitado no se puede contratar",
      //   });
      //   return;
      // }

      if (!(Number(item.contratos) > 0)) {
        let response =
          await this.services.ContratoService.getGanadorProveedorInfo(
            item.id_ganador
          ).catch(() => {});
        if (response?.status == 200) {
          response.data.id_ganador = item.id_ganador;
          this.setCurrentProvider(item.id_proveedor);
          this.setProviderInfo(response?.data);

          const { status, data } =
            await this.services.ContratoService.getContratoGanador(
              item.id_ganador
            ).catch(() => {});
          if (status === 200 && data) {
            if (item.id_estado === 1) {
              this.setContratoExistente(data);
            }
          }

          this.$router.push({
            name: "crear-contrato-compra",
            params: { tipo: 3 },
            query: { proceso: this.$route.params.idProceso },
          });
        }
      } else {
        this.$router.push({
          name: "seguimiento-orden-compra",
          params: { idContrato: item.contratos },
        });
      }
    },
    async verificarAsignacionCdf() {
      if (!this.permisos.leer || this.haveRole("ROLE_PROVEEDOR")) return;

      const response = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        1000
      );

      if (response?.status === 200)
        this.fondosCertificados = response.data.length > 0;
    },
    esSubasta() {
      // this.proceso?.EtapaPorProcesos[9]?.id_tipo_etapa === 9
      const etapaSubasta = this.proceso?.EtapaPorProcesos.filter(
        (item) => item.id_tipo_etapa === 9
      );
      return etapaSubasta.length > 0;
    },
    async listarTipoContrato() {
      toggleLoadable(this.tipoContrato);
      const { data } =
        await this.services.CertificacionFondo.obtenerTipoDocumento();
      setLoadableResponse(this.tipoContrato, data);
    },
    async verificarDocumentosContrato() {
      toggleLoadable(this.verificar);
      const { data } =
        await this.services.CertificacionFondo.verificarContratosProcesoCompra(
          this.$route.params.idProceso
        );
      setLoadableResponse(this.verificar, data);
    },
    abrirModal(data) {
      this.contratoSeleccionado = data.contratos;
      this.modalDocumento = true;
      this.listarTipoContrato();
    },
    async guardarDocumentoContrato(item) {
      const formData = convertToFormData(item);
      toggleLoadable(this.guardadoDocumento);
      const { data } = await this.services.CertificacionFondo.guardarDocumento(
        this.contratoSeleccionado,
        formData
      );
      setLoadableResponse(this.guardadoDocumento, data, {
        showAlertOnSuccess: true,
      });
      this.verificarDocumentosContrato(this.$route.params.idProceso);
      this.modalDocumento = false;
    },
  },
  async created() {
    await this.verificarAsignacionCdf();
    await this.obtenerGanadores();
    await this.verificarDocumentosContrato();
    if (this.permisos.escribir) {
      this.headersGanadores?.push({
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      });
      this.headersGanadoresSubasta?.push({
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      });
    }
  },
};
</script>
