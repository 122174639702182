<template>
    <v-card>
        <v-card-text>
            <ValidarEdicion
                :id_proceso="idProceso"
                :id_modificativa="11"
                @validar="validar" 
            />

            <div v-if="validado" class="mt-4">
                <PipProcesoComponent 
                    :etapas="etapas"
                    @actualizarEtapa="getProceso"
                    :cargando="cargandoEtapas"
                    permiteEdicion
                    permitirCualquierFecha
                    noGenerarRegistro
                    :id-tipo-modificativa="11"
                />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import PipProcesoComponent from '../../PipProcesoComponent.vue';
import ValidarEdicion from '../ValidarEdicion.vue';

export default {
    name: 'ModificarEtapasPipGeneral',
    components: { ValidarEdicion, PipProcesoComponent },
    data: () => ({
        validado: false,
        cargandoEtapas: false,
        proceso: null,
    }),
    computed: {
		idProceso() {
			return Number(this?.$route?.params?.idProceso || 0);
		},
        etapas() {
            return this.proceso?.EtapaPorProcesos ?? [];
        },
	},
    methods: {
        validar(valor) {
			this.validado = valor;
		},

        // DATA
        async getProceso() {
            this.cargandoEtapas = true;
            try {
                const response = await this.services.PacProcesos.getProceso(this.idProceso);
                this.proceso = response.data;
            } finally {
                this.cargandoEtapas = false;
            }
        },
    },
    created() {
        this.getProceso();
    },
}
</script>