<template>
    <v-card>
        <v-card-title>
            <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Documentos</h6>
        </v-card-title>
        <v-divider />
        <v-card-text>
            <div v-if="categorias.isLoading" class="d-flex" style="gap: 8px;">
                <v-progress-circular :size="18" :width="2" indeterminate />
                <span class="font-weight-bold text-uppercase text-body-2">Cargando documentos...</span>
            </div>

            <v-expansion-panels v-else v-model="categoriasAbiertas" accordion>
                <v-expansion-panel v-for="categoria in categorias.data" :key="categoria.id" :value="categoria.id">
                    <v-expansion-panel-header>
                        <div class="d-flex align-center flex-wrap" style="gap: 8px;">
                            <v-icon>mdi-file-tree</v-icon>
                            <span class="font-weight-bold">{{ categoria.nombre }}</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-divider />
                    <v-expansion-panel-content class="px-4 py-4">
                        
                        <!-- sub tipos -->
                        <v-expansion-panels v-model="subcategoriasAbiertas" accordion>
                            <v-expansion-panel v-for="subCategoria in categoria.subtipos" :key="subCategoria.id" :value="subCategoria.id">
                                <v-expansion-panel-header>
                                    <div class="d-flex align-center justify-space-between" style="gap: 16px;">
                                        <span>{{ subCategoria.nombre }}</span>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                    v-if="subCategoria.DocumentoProcesoCompras.length > 0" 
                                                    color="success"
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    mdi-file-document-check
                                                </v-icon>
                                            </template>
                                            <span>Tiene documentos cargados (*)</span>
                                        </v-tooltip>
                                    </div>
                                </v-expansion-panel-header>
                                <v-divider />
                                <v-expansion-panel-content class="px-4 py-4">
                                    <v-btn color="primary" @click.stop="abrirModalSubirDocumento(subCategoria)">
                                        Subir documento
                                    </v-btn>
                                    <DataTableComponent 
                                        :items="subCategoria.DocumentoProcesoCompras"
                                        :tiene_paginacion="false"
                                        :headers="headers"
                                        class="mt-4"
                                    >
                                        <template v-slot:item.nombre="{ item }">
                                            {{ item.nombre_documento ?? '-' }}
                                        </template>
                                        <template v-slot:item.created_at="{ item }">
                                            {{ formatDate(item.created_at) }}
                                        </template>
                                        <template v-slot:item.acciones="{ item }">
                                            <div class="d-flex justify-center align-center" style="gap: 8px;">
                                                <v-btn @click.stop="visualizarDocumento(item)" icon>
                                                    <v-icon color="secondary">mdi-eye</v-icon>
                                                </v-btn>
                                                <v-btn @click.stop="confirmarEliminacion(item)" icon>
                                                    <v-icon color="secondary">mdi-trash-can</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                    </DataTableComponent>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        <!-- sub tipos -->

                    </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <PdfModal
                :is-open.sync="modalVerDocumentoAbierta" 
                :source-loadable="visualizacionDocumento" 
                filename="documento-proceso-recurso"
            />

            <ModalSubirDocumento 
                :is-open.sync="modalSubirDocumentoAbierta" 
                :id-proceso-compra="idProcesoCompra"
                :tipo-documento="tipoDocumentoActivo"
                @on-documento-guardado="manejarCreacionDocumento"
            />

            <ConfirmationModalComponent
                :isOpen="modalConfirmacionEliminadoAbierta" 
                description="¿Desea continuar con la acción?" 
                @confirm="eliminarDocumento" 
                :is-loading="eliminadoDocumento.isLoading"
                @cancel="modalConfirmacionEliminadoAbierta = false" 
            />
        </v-card-text>
    </v-card>
</template>
<script>
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import ModalSubirDocumento from './ModalSubirDocumento.vue';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'DocumentosRecurso',
    components: { DataTableComponent, PdfModal, ModalSubirDocumento, ConfirmationModalComponent },
    emits: ['on-creacion-etapas'],
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        // UI
        modalVerDocumentoAbierta: false,
        modalConfirmacionEliminadoAbierta: false,
        visualizacionDocumento: createLoadable(null),
        modalSubirDocumentoAbierta: false,
        tipoDocumentoActivo: null,
        documentoActivo: null,
        
        // Panels
        categoriasAbiertas: null,
        subcategoriasAbiertas: null,

        // DATA
        categorias: createLoadable([]),
        eliminadoDocumento: createLoadable(null),
    }),
    computed: {
        headers() {
            return [
                { text: "Nombre", value: "nombre", sortable: false, align: 'center' },
                { text: "Fecha de carga", value: "created_at", sortable: false, align: 'center' },
                { text: "Acciones", value: "acciones", sortable: false, align: 'center' },
            ];
        },
    },
    methods: {
        // UI
        formatDate,
        abrirModalSubirDocumento(subCategoria) {
            this.tipoDocumentoActivo = { ...subCategoria };
            this.modalSubirDocumentoAbierta = true;
        },
        confirmarEliminacion(documento) {
            this.documentoActivo = { ...documento };
            this.modalConfirmacionEliminadoAbierta = true;
        },
        manejarCreacionDocumento(nuevasEtapasCreadas) {
            this.cargarDocumentos();

            if (nuevasEtapasCreadas) this.$emit('on-creacion-etapas');
        },
        // DATA
        async cargarDocumentos() {
            toggleLoadable(this.categorias);
            const { data } = await this.services.HerramientaRecursoRevision.cargarDocumentosProceso(this.idProcesoCompra);
            setLoadableResponse(this.categorias, data);
        },
        async visualizarDocumento(documento) {
            this.modalVerDocumentoAbierta = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.HerramientaRecursoRevision.visualizarDocumentoProceso(documento.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        async eliminarDocumento() {
            toggleLoadable(this.eliminadoDocumento);
            const { data } = await this.services.HerramientaRecursoRevision.eliminarDocumentoProceso(this.documentoActivo.id);
            setLoadableResponse(this.eliminadoDocumento, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            this.modalConfirmacionEliminadoAbierta = false;

            if (isResponseSuccesful(data)) {
                this.cargarDocumentos();
            }
        },
    },
    created() {
        this.cargarDocumentos();
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0 !important;
}
</style>