<template>
  <v-dialog v-model="isOpen" max-width="1200px" persistent>
    <v-card>
      <v-card-title class="justify-space-between align-center">
        <v-btn
          style="
            min-width: unset !important;
            min-height: unset !important;
            width: 32px !important;
            height: 32px !important;
          "
          class="pt-0"
          @click.stop="cerrarModal"
          text
          rounded
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-card-title class="d-flex align-center">
        <v-icon class="mr-2">mdi-file-sign</v-icon>
        <span class="font-weight-bold mr-2">Creación de contrato</span>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
              :items="tipoContratos"
              outlined
              item-value="id"
              item-text="nombre"
              label="Tipo de compra"
              v-model="formulario.id_tipo_contrato"
              :error-messages="tipoContratoErrors"
              @blur="$v.formulario.id_tipo_contrato.$touch()"
              @change="validarNumeroContrato"
            ></v-select>
            <v-text-field
              outlined
              readonly
              :label="formLabel('numero')"
              v-model="numeroContrato"
              :error-messages="numeroErrors"
              @input="$v.formulario.numero_contrato.$touch()"
              @blur="$v.formulario.numero_contrato.$touch()"
            />
            <v-text-field
              outlined
              :label="formLabel('numero_interno')"
              v-model="formulario.numero_contrato_interno"
              :error-messages="numeroInternoErrors"
              @input="$v.formulario.numero_contrato_interno.$touch()"
              @blur="$v.formulario.numero_contrato_interno.$touch()"
            />
            <v-text-field
              outlined
              label="Nombre del contrato *"
              counter="100"
              maxLength="100"
              v-model="formulario.nombre_contrato"
              :error-messages="nombreErrors"
              @input="$v.formulario.nombre_contrato.$touch()"
            />
          </v-col>
          <v-col cols="6">
            <vc-date-picker
              v-model="formulario.fecha_hora_contratacion"
              mode="dateTime"
              locale="es"
              :popover="config_calendar"
            >
              <template v-slot="{ inputEvents }">
                <v-text-field
                  class="py-1 border roundend focus:outline-none focus:border-blue-300"
                  :label="formLabel('fecha')"
                  readonly
                  :value="getFechaFormat"
                  v-on="inputEvents"
                  outlined
                  :error-messages="fechaErrors"
                  @input="$v.formulario.fecha_hora_contratacion.$touch()"
                  @blur="$v.formulario.fecha_hora_contratacion.$touch()"
                />
              </template>
            </vc-date-picker>
            <v-text-field
              outlined
              readonly
              label="Monto adjudicado"
              prepend-inner-icon="mdi-currency-usd"
              placeholder="00.00"
              v-model="montoFinalAdjudicado"
              :error-messages="montoErrors"
              @input="$v.formulario.monto_adjudicado.$touch()"
              @blur="$v.formulario.monto_adjudicado.$touch()"
            />
            <v-row class="d-flex align-items-center justify-content-between">
              <v-col cols="4" sm="2" md="6">
                <v-text-field
                  :suffix="
                    isSwitchActive
                      ? transformaUnidades('Meses')
                      : transformaUnidades('Días')
                  "
                  outlined
                  label="Plazo contractual *"
                  v-mask="'###'"
                  v-model="formulario.plazo_contractual"
                  :error-messages="plazoErrors"
                  @input="$v.formulario.plazo_contractual.$touch()"
                  @blur="$v.formulario.plazo_contractual.$touch()"
                />
              </v-col>

              <v-col cols="4" sm="1" md="2">
                <v-switch
                  color="secondary"
                  inset
                  v-model="isSwitchActive"
                ></v-switch>
              </v-col>

              <v-col cols="4" sm="3" md="4">
                <span v-if="isSwitchActive">{{
                  "Equivalente a: " + this.diasEquivalentes + " días"
                }}</span>
              </v-col>
            </v-row>

            <template>
              <v-file-input
                outlined
                prepend-icon=""
                placeholder="Contrato"
                label="Archivo"
                accept="application/pdf"
                prepend-inner-icon="mdi-paperclip"
                v-model="formulario.contrato"
              />
            </template>

            <v-dialog
              ref="dialog"
              v-model="modalReloj"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-if="modalReloj"
                  v-model="formulario.horaVigente"
                  label="Hora"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modalReloj"
                v-model="formulario.horaVigente"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalReloj = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="modalReloj = false">
                  Establecer
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <template v-if="formulario.id_tipo_contrato == 2">
              <v-select
                :items="copiasA"
                chips
                multiple
                outlined
                label="Copias a emitir"
                v-model="formulario.copias"
              ></v-select>
            </template>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        <div
          class="d-flex justify-center align-center"
          style="position: sticky; bottom: 0; background: white; gap: 16px"
        >
          <v-btn
            class="flex-grow-1 flex-shrink-1 mt-4"
            color="secondary"
            large
            @click.stop="cerrarModal"
          >
            Cerrar
          </v-btn>
          <v-btn
            class="flex-grow-1 flex-shrink-1 mt-4"
            color="primary"
            large
            @click.stop="abrirModalConfirmar"
          >
            Guardar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <ConfirmationModalComponent
      :isOpen="modalConfirmarContrato"
      description="¿Desea continuar con la acción?"
      @confirm="guardarContrato"
      @cancel="modalConfirmarContrato = false"
      :isLoading="isLoading"
    />
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation.js";
import moment from "moment";
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";

export default {
  name: "modalGenerarContrato",
  components: { ConfirmationModalComponent },
  props: {
    isOpen: { type: Boolean },
    adjudicacion: { type: Object, default: {} },
    id_proceso_compra: { type: Number },
  },
  emits: ["cerrar", "recargar-adjudicacion"],
  validations() {
    return {
      formulario: {
        numero_contrato: { required },
        numero_contrato_interno: { required },
        fecha_hora_contratacion: { required },
        monto_adjudicado: { required },
        nombre_contrato: { required },
        plazo_contractual: { required },
        id_tipo_contrato: { required },
        aniosFiscalesSwitch: { required },
      },
    };
  },
  data: () => ({
    tipoContratos: [],
    contrato: [],
    formulario: {
      numero_contrato: "",
      numero_contrato_interno: "",
      nombre_contrato: "",
      fecha_hora_contratacion: "",
      monto_adjudicado: "",
      plazo_contractual: "",
      id_ganador: null,
      id_tipo_contrato: null,
      aniosFiscalesSwitch: false,
      id_proceso_compra: null,
    },
    modalReloj: false,
    isSwitchActive: false,
    diasEquivalentes: 0,
    copiasA: ["UCP", "Contratista", "Finanzas"],
    config_calendar: {
      visibility: "click",
    },
    modalConfirmarContrato: false,
    isLoading: false,
  }),
  computed: {
    numeroErrors() {
      return new Validator(this.$v.formulario.numero_contrato)
        .detect()
        .getResult();
    },
    numeroInternoErrors() {
      return new Validator(this.$v.formulario.numero_contrato_interno)
        .detect()
        .getResult();
    },
    nombreErrors() {
      return new Validator(this.$v.formulario.nombre_contrato)
        .detect()
        .getResult();
    },
    fechaErrors() {
      return new Validator(this.$v.formulario.fecha_hora_contratacion)
        .detect()
        .getResult();
    },
    plazoErrors() {
      return new Validator(this.$v.formulario.plazo_contractual)
        .detect()
        .getResult();
    },
    tipoContratoErrors() {
      return new Validator(this.$v.formulario.id_tipo_contrato)
        .detect()
        .getResult();
    },
    montoErrors() {
      return new Validator(this.$v.formulario.monto_adjudicado)
        .detect()
        .getResult();
    },
    montoFinalAdjudicado() {
      if (this.montoAdjudicado > 0) {
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }).format(this.montoAdjudicado);
      } else {
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }).format(this.formulario.monto_adjudicado);
      }
    },
    numeroContrato() {
      return this.formulario.numero_contrato;
    },
    getFechaFormat() {
      if (this.formulario.fecha_hora_contratacion) {
        return moment(this.formulario.fecha_hora_contratacion).format(
          "DD/MM/YYYY hh:mm a"
        );
      } else return "";
    },
  },
  methods: {
    abrirModalConfirmar() {
      this.$v.formulario.$touch();
      if (this.$v.$invalid) return;
      this.modalConfirmarContrato = true;
    },
    transformaUnidades(unidad) {
      if (unidad === "Meses" && this.isSwitchActive) {
        const meses = this.formulario.plazo_contractual || 0;
        this.diasEquivalentes = Math.floor(meses * 30.417);
      } else if (unidad === "Días" && !this.isSwitchActive) {
        this.diasEquivalentes = this.formulario.plazo_contractual;
      }

      return unidad;
    },
    async validarNumeroContrato() {
      const idGanador = this.adjudicacion.gobs.id;
      const { data } =
        await this.services.ContratoService.obtenerGanadorInformacion(
          idGanador,
          {
            tipo_contrato: this.formulario.id_tipo_contrato,
            id_proceso_compra: this.$route.params.idProceso,
          }
        );
      this.formulario.numero_contrato = data.numero_orden_compra;
      this.formulario.monto_adjudicado = Number(data?.total_sub_procesos);
    },
    async getTiposContrato() {
      const { data, status } =
        await this.services.ContratoService.getTipoContrato();
      if (status == 200) {
        this.tipoContratos = data;
      }
    },
    async guardarContrato() {
      this.formulario.fecha_hora_contratacion = moment(
        this.formulario.fecha_hora_contratacion
      ).format("YYYY/MM/DD hh:mm");

      this.formulario.id_ganador = this.adjudicacion.gobs.id;
      this.formulario.plazo_contractual = this.diasEquivalentes;
      this.formulario.id_proceso_compra = this.$route.params.idProceso;

      let formData = new FormData();

      Object.keys(this.formulario).forEach((key) => {
        formData.append(key, this.formulario[key]);
      });

      this.isLoading = true;
      const { data } = await this.services.ContratoService.generarContrato(
        formData
      ).finally(() => (this.isLoading = false));

      await this.services.ContratoService.verificarAnioFiscal(
        this.$route.params.idProceso
      );

      this.changeEstadoContrato(data.id);
      this.recargarInformacion();

      this.cerrarModal();
    },
    formLabel(key) {
      let contratoLabels = {
        numero: "Número de contrato",
        numero_interno: "Número de contrato interno *",
        fecha: "Fecha y hora de contratación *",
      };
      let ordenCompraLabels = {
        numero: "Número de orden de compra",
        numero_interno: "Número interno de orden de compra *",
        fecha: "Vigencia a partir de *",
      };

      switch (this.formulario.id_tipo_contrato) {
        case 1:
          return contratoLabels[key];
        case 2:
          return ordenCompraLabels[key];
        default:
          return contratoLabels[key];
      }
    },
    async changeEstadoContrato(idOrdenCompra) {
      await this.services.ContratoService.updateEstadoContrato(idOrdenCompra, {
        id_estado_contrato: 2,
      });
    },
    limpiarFormulario() {
      this.formulario = {
        numero_contrato: "",
        numero_contrato_interno: "",
        nombre_contrato: "",
        fecha_hora_contratacion: "",
        monto_adjudicado: "",
        plazo_contractual: "",
        id_ganador: null,
        id_tipo_contrato: null,
        aniosFiscalesSwitch: false,
      };
      this.$v.$reset();
    },
    recargarInformacion() {
      this.$emit("recargar-adjudicacion");
    },
    cerrarModal() {
      this.$emit("cerrar");
      this.limpiarFormulario();
    },
  },
  created() {
    this.getTiposContrato();
  },
};
</script>
