<template>
  <section>
    <v-card class="px-4 py-4 pb-4">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-icon class="mr-2"
            >mdi mdi-clock-edit</v-icon
          >
          <span class="font-weight-bold mr-2">Prórroga de contrato u orden de compra</span>
        </div>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="4"
            v-for="(item, index) in tiposDocumentos"
            :key="index"
          >
            <v-file-input
            v-model="form.documentos[item.id]"
              outlined
              prepend-icon="mdi mdi-paperclip"
              :placeholder="item.nombre"
              :label="item.nombre"
              prepend-inner-icon="mdi-asterisk"
              :error-messages="documentosErrors[item.id]"
              @blur="$v.form.documentos[item.id]?.$touch()" 
              accept="application/pdf"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-card elevation="0">
              <v-card-title class="d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                  <span class="font-weight-bold mr-2 text-h6">Seleccione los lotes o items a modificar</span>
                </div>
                <div class="d-flex align-center">
                  <v-icon class="mr-2"
                  >mdi-cash-plus</v-icon
                >
                  <span class="font-weight-bold mr-2">Monto a refinanciar</span>
                  ${{ total }}
                </div>
              </v-card-title>
              <v-card-text>
                <v-expansion-panels multiple accordion>
                  <v-expansion-panel
                    v-for="(lote, index) in lotes"
                    :key="index"
                  >
                    <v-expansion-panel-header>
                      {{ lote.nombre }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="headers_lotes"
                        :items="lote.sub_procesos"
                        disable-pagination
                        hide-default-footer
                      >
                      <template v-slot:[`item.seleccionado`]="{ item }">
                          <div class="d-flex justify-center">
                            <v-checkbox
                              v-model="item.seleccionado"
                              :value="item.seleccionado"
                              @change="loteSeleccionado(item)"
                              :readonly="item.contrato_liquidado"
                            />
                          </div>
                      </template>
                      <template v-slot:[`item.alter_cantidad`]="{ item }">
                        <div class="d-flex align-center">
                          <!-- Campo de entrada numérica -->
                          <v-text-field
                            type="number"
                            v-model="item.aumento"
                            @input="actualizarCantidad(item)"
                            :disabled="item.contrato_liquidado || !item.seleccionado"
                            outlined
                            hide-details
                            class="mb-2 mt-2"
                            style="min-width: 100px;"
                          />
                        </div>
                      </template>
                        <template v-slot:[`item.monto_modificado`]="{ item }">
                          {{
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.monto_modificado ?? 0)
                          }}
                        </template>
                        <template
                          v-slot:[`item.monto_inicial_contratado`]="{ item }"
                        >
                          {{
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.monto_inicial_contratado)
                          }}
                        </template>
                        <template v-slot:[`item.cantidad_actual`]="{ item }"
                        >
                          {{ item?.cantidad_actual ? item?.cantidad_actual : 0 }}
                        </template>
                        <template v-slot:[`item.monto_actual`]="{ item }"
                        >
                          {{ item?.monto_actual ? item?.monto_actual : 0}}
                        </template>
                        <template
                          v-slot:[`item.monto_contratado`]="{ item }"
                        >
                          {{
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.monto_contratado ?? 0)
                          }}
                        </template>
                        <template
                          v-slot:[`item.nuevo_monto_pagar`]="{ item }"
                        >
                          {{
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.nuevo_monto_pagar ?? 0)
                          }}
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-col cols="12" class="d-flex justify-end">
            <v-btn color="primary" class="mt-2" @click="modalHistorial = true">
                <v-icon color="white" class="mr-1">mdi-history</v-icon>
                Historial de Prórrogas
            </v-btn>
        </v-col>
        
        <v-row>
          <v-col cols="12" md="12">
            <v-card elevation="0">
              <span class="font-weight-bold mr-2 text-h6">Modificación de plazo contractual</span>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      label="Plazo actual"
                      type="number"
                      v-model="form.plazo_actual"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-row>
                      <v-col cols="4" md="4">
                        <v-switch label="Meses" v-model="form.tipo" />
                      </v-col>
                      <v-col cols="8" md="8">
                        <v-text-field
                          outlined
                          label="Días o meses a agregar"
                          type="number"
                          v-model="form.tiempo"
                          @change="calcularNuevoPlazo"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="form.nuevo_plazo"
                      readonly
                      outlined
                      label="Nuevo plazo"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-textarea 
          v-model="form.comentario" 
          label="Comentario" 
          placeholder="Comentario" 
          outlined 
          light
          :error-messages="comentarioErrors"
          @change="$v.form.comentario.$touch()"
          @blur="$v.form.comentario.$touch()"
        />

        <!-- REFINANCIAMIENTOS -->
        <v-row>
          <v-col cols="12">
            <v-card elevation="0">
              <span class="font-weight-bold mr-2 text-h6">Refinanciamientos</span>
              <RefinanciamientosContrato 
                :refinanciamientos="refinanciamientos"
                :monto-total="total"
                :tipo-ajuste="1"
                :esContratoLiquidado="contratoLiquidado"
                :seleccionado="seleccionado"
                class="refinanciamientos-contrato mt-4"
                ref="formulario-asignaciones"
              >
              </RefinanciamientosContrato>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-8 mb-0" justify="center">

          <v-col cols="12" md="6" lg="4">
            <v-btn 
              block 
              x-large 
              outlined 
              color="secondary" 
              @click="regresar"
            >
              regresar
            </v-btn>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-btn
              block
              x-large
              color="primary"
              :disabled="tieneMaximoProrrogas"
              @click="enviarProrroga"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ModalHistorial
      :is-open="modalHistorial"
      :historial="historial"
      @close="modalHistorial = false"
    />

  </section>
</template>
<script>
import { createLoadable, setLoadableResponse, shouldFetch, shouldRetry, toggleLoadable } from "@/utils/loadable";
import RefinanciamientosContrato from './components/RefinanciamientosContrato.vue';
import moment from "moment-timezone";
import { required, maxLength } from "vuelidate/lib/validators";
import { convertToFormData } from "@/utils/data";
import { Validator } from "@/utils/form-validation.js";
import ModalHistorial from "./components/ModalHistorial.vue";

export default {
  name: "prorrogaServicio",
  components: { RefinanciamientosContrato, ModalHistorial},
  validations() {
    const tiposDocumentosRules = {};
    this.tiposDocumentos.forEach((tipo) => {
      tiposDocumentosRules[tipo.id] = { required }
    });
    return {
      form:{
        documentos: tiposDocumentosRules,
        comentario: { required, maxLength: maxLength(500) },
      }
    };
  },
  data() {
    return {
      tiposDocumentos: [],
      form: {
        id: null,
        documentos: [],
        plazo_actual: 0,
        tipo: false,
        tiempo: null,
        asignaciones: [],
        comentario: null,
      },
      contratoLiquidado: false,
      lotesModificativa : [],
      total: 0,
      montoModificado: 0,
      seleccionado: false,
      headers_lotes: [
        { text: "Utilizar", align: "center", value: "seleccionado" },
        { text: "OBS", value: "obs", sortable: false, align: 'center' },
        { text: "Unidad", value: "unidad", sortable: false, align: 'center' },
        { text: "Cantidad contratada", value: "cantidad_total", sortable: false, align: 'center' },
        { text: "Monto", value: "alter_cantidad", sortable: false, align: 'center' },
        { text: "Monto contratado", value: "monto_contratado", sortable: false, align: 'center' },
        { text: "Monto disponible", value: "monto_inicial_contratado", sortable: false, align: 'center' },
        { text: "Monto de modificativa", value: "monto_modificado", sortable: false, align: 'center' },
        { text: "Nuevo monto por pagar", value: "nuevo_monto_pagar", sortable: false, align: 'center' },
      ],
      headers_cifrado_disponible: [
        { text: "Código presupuestario", value: "codigo_presupuestario", sortable: false, align: 'center' },
        { text: "Monto($)", value: "monto", sortable: false, align: 'center' },
        { text: "Acciones", value: "acciones", sortable: false, align: 'center' },
      ],
      
      lotes: [],
      cifrador_refinanciamiento: [],
      contrato: {},
      refinanciamientos: createLoadable([]),
      historial: createLoadable([]),
      errorCantidad: false,
      cantidad_nueva: 0,
      modalHistorial: false,

    };
  },
  computed: {
    documentosErrors() {
      const errors = {};
      this.tiposDocumentos.forEach((tipoDocumento) => { 
        errors[tipoDocumento.id] = new Validator(this.$v.form.documentos[tipoDocumento.id]).detect().getResult();
      });

      return errors;
    },
    sumatoriaMontosModificadoLotes() {
      return this.lotes.reduce((acumulado, lote) => {
        return acumulado + lote.sub_procesos.reduce((acumuladoLote, subProceso) => acumuladoLote + +subProceso.monto_modificado, 0);
      }, 0);
    },
    comentarioErrors() {
      return new Validator(this.$v.form.comentario).detect().getResult();
    },
    tieneMaximoProrrogas() {
      // Obtener los tipos de edición de los Ajustes de Contrato
      const tiposEdicion = this.historial.data.filter(h => h.AjusteContrato).map(h => h.AjusteContrato.tipo_edicion);

      const tieneDosProrrogas = tiposEdicion.length === 2;
      return tieneDosProrrogas;
    }
  },
  methods: {
    cantidadAModificar(item){
      return -item.cantidad_restante;
    },
    async obtenerTiposDocumentos() {
      const { data } =
        await this.services.edicionContrato.tiposDocumentosEdicion({
          prorroga: true,
        });
      this.tiposDocumentos = data;
    },
    async obtenerLotes() {
      const { data } = await this.services.edicionContrato.obtenerLotesEtapa(
        this.id_contrato
      );
      this.lotes = data.map((lote) => ({
        ...lote,
        sub_procesos: lote.sub_procesos.map((sub_proceso) => {
          const edicion = lote?.ajuste_orden;
          const edicionEncontrada = edicion.find((e) => e.id_lote === lote.id && e.id_sub_proceso === sub_proceso.id)

          const precioUnitario = Number(sub_proceso.gspb.monto / sub_proceso.gspb.cantidad).toFixed(2);
          const cantidadTotal = Number(sub_proceso.gspb.cantidad).toFixed(0);

          // const cantidadRecibida = lote.etapas.reduce((acc, e) => acc + +e.cantidad, 0)

          const cantidadActual = edicionEncontrada?.cantidad_actual ? edicionEncontrada?.cantidad_actual : 0
          const montoActual = edicionEncontrada?.monto_actual ? edicionEncontrada?.monto_actual : 0;
          // const cantidadModificativaActual = +edicion?.cantidad_actual ? +edicion?.cantidad_actual : 0;
          const cantidadRestante = Number(+sub_proceso.gspb.cantidad_recibida  - +cantidadTotal - +cantidadActual).toFixed(0);
          const cantidadRecibida = +sub_proceso.gspb.cantidad_recibida - +cantidadActual

          const montoContratado = +precioUnitario * Math.abs(cantidadRestante) 
          this.contratoLiquidado = lote?.contrato_orden.id_estado_contrato === 3;

          return {
            id_sub_proceso: sub_proceso.id,
            id_lote: lote.id,
            monto_modificado: 0,
            nueva_cantidad: 0,
            cantidad_nueva: Math.abs(cantidadRestante) + +cantidadActual,
            aumento: 0,
            monto_inicial_contratado: cantidadTotal * precioUnitario + +montoActual,
            cantidad_restante: Math.abs(cantidadRestante),
            // precio_unitario: precioUnitario,
            cantidad_total: cantidadTotal,
            unidad: sub_proceso.Presentacion.nombre,
            obs: sub_proceso.especificacion,
            cantidad_actual: cantidadActual,
            monto_contratado: montoContratado,
            cantidad_recibida: cantidadRecibida ?? 0,
            contrato_liquidado: this.contratoLiquidado,
          };
        }),
      }));
    },
    async enviarProrroga() {
      this.$v.form.$touch();
      const envioAsignaciones = this.$refs['formulario-asignaciones'].activarEnvioFormulario();

      if(!envioAsignaciones.asignaciones.length > 0){
        return this.pushAppMessage({
          message: "Es necesario realizar un refinanciamiento para efectuar una modificativa o prórroga para guardar.",
          type: "warning",
        });
      }
      
      if (this.$v.form.$invalid || !envioAsignaciones.valido) return;
      
      if(this.errorCantidad && this.cantidad_nueva < 0){
        return this.pushAppMessage({
          message: "No se permite valores menores a la cantidad restante",
          type: "error",
        });
      }

      const { documentos, ...restForm } = this.form;
      const documentos_filtrados = [];
      for (const key in documentos) {
        if (documentos[key]) {
          documentos_filtrados.push({
            id_tipo_documento: key,
            documento: documentos[key],
          });
        }
      }

      const tieneMonto = envioAsignaciones.asignaciones.some(asignacion => !!asignacion.monto);
      const formData = convertToFormData({ 
        ...restForm, documentos: documentos_filtrados, 
        asignaciones: tieneMonto ? envioAsignaciones.asignaciones : null,
        lotes: this.lotesModificativa.filter(lote => lote.seleccionado)
      });
      await this.services.edicionContrato.enviarEdicionContrato(this.id_contrato, 2, formData)
      
      this.regresar();
    },
    async obtenerContrato() {
      const { data } = await this.services.edicionContrato.obtenerContrato(
        this.id_contrato
      );

      const edicion = this.lotes.map((lote) => lote?.ajuste_orden?.filter((ajuste) => ajuste.activo) || []).flat();
      const nuevoPlazo = edicion[0]?.plazo_actual; 

      this.form.plazo_actual = nuevoPlazo ? nuevoPlazo : +data.plazo_contractual;
      this.contrato = data;
    },
    calcularNuevoPlazo() {
      const { tipo, tiempo } = this.form;
      const type = tipo ? "months" : "days";
      if (!tiempo) return (this.form.nuevo_plazo = this.form.plazo_actual);
      const fechaAumentada = moment().add(tiempo, type);
      const daysFromNow = fechaAumentada.diff(moment(), "days");
      this.form.nuevo_plazo = daysFromNow + this.form.plazo_actual;
    },
    actualizarCantidad(item) {
      const { aumento } = item;
      const aumentoNumerico = isNaN(Number(aumento)) ? 0 : Number(aumento);
      item.aumento = aumentoNumerico;
      item.nueva_cantidad = aumentoNumerico;

      const monto = item.nueva_cantidad * item.cantidad_total
      this.montoModificado = item.monto_modificado = monto;

      if(item.cantidad_nueva < 0 ){
        this.errorCantidad = true;
        return this.pushAppMessage({
          message: "No se permite valores menores a la cantidad restante",
          type: "error",
        });
      }

      item.nuevo_monto_pagar = +item.monto_inicial_contratado + this.montoModificado

      const existingLote = this.lotesModificativa.find((lote) =>
        lote.id_lote === item.id_lote &&
        lote.id_sub_proceso === item.id_sub_proceso
      );

      if (existingLote) {
        // Si el lote ya existe, actualiza su cantidad y monto
        existingLote.cantidad = item.nueva_cantidad;
        existingLote.monto = Number((+monto).toFixed(2));
      } else {
        // Si el lote no existe, agrega un nuevo objeto al array
        this.lotesModificativa.push({
          id_lote: item.id_lote,
          cantidad: item.nueva_cantidad,
          id_sub_proceso: item.id_sub_proceso,
          seleccionado: item.seleccionado,
          monto: Number((+monto).toFixed(2)),
        });
      }
      this.total = Number(this.lotesModificativa.reduce((acc, lote) => acc + lote.monto, 0).toFixed(2));

      if(item.nueva_cantidad <= 0 ){
        this.errorCantidad = true;
        return this.pushAppMessage({
          message: "No se permite valores menores o iguales a 0",
          type: "error",
        });
      }
    },
    regresar() {
      this.$router.replace({
        path: `/contratos-asignados`,
      });
    },
      // Refinanciamientos
    async cargarRefinanciamientos() {
      if (!shouldFetch(this.refinanciamientos) && !shouldRetry(this.refinanciamientos)) {
        return;
      }
      toggleLoadable(this.refinanciamientos);
      const { data } = await this.services.RefinanciamientoCompras.cargarRefinanciamientosPorContrato(this.id_contrato);
      setLoadableResponse(this.refinanciamientos, data);
    },
    async obtenerHistorial(){
      toggleLoadable(this.historial)
      const { data } = await this.services.edicionContrato.obtenerHistorial(this.id_contrato, 2);
      setLoadableResponse(this.historial, data)
    },

    loteSeleccionado(item) {
      this.seleccionado = item.seleccionado;
      if (!item.seleccionado) {
        this.lotesModificativa.forEach((lote) => {
          if (lote.id_sub_proceso === item.id_sub_proceso && lote.id_lote === item.id_lote) {
            lote.seleccionado = false;
          }
        });

        item.aumento = 0;
        this.total = 0;
        item.nueva_cantidad = 0;

      } else {
        // Si se marca, ajustamos el valor de seleccionado a true
        this.lotesModificativa.forEach((lote) => {
          if (lote.id_sub_proceso === item.id_sub_proceso && lote.id_lote === item.id_lote) {
            lote.seleccionado = true;
          }
        });
      }
    }
  },
  watch: {
    "form.tiempo": {
      handler: function () {
        this.calcularNuevoPlazo();
        if(this.form.tiempo === ''){
          this.form.nuevo_plazo = null;
        }
      },
      deep: true,
    },
    "form.tipo": {
      handler: function () {
        this.calcularNuevoPlazo();
      },
      deep: true,
    },
  },
  async created() {
    this.id_contrato = this.$route.params.id_contrato;
    await this.obtenerContrato();
    await this.cargarRefinanciamientos();
    await this.obtenerTiposDocumentos();
    await this.obtenerLotes();
    await this.obtenerHistorial();
  },
};
</script>
<style>
.border {
  border: 1px solid rgb(0, 0, 0);
}
</style>
