import { http_client_slim } from "@/plugins/http_client";

const client = http_client_slim("/api/v1/reporteria-centros-escolares");

const obtenerCompras = async (data = {}) =>
  client("/compras", { data, method: "get" });

const obtenerRegistros = async (data = {}) =>
  client("/registros", { data, method: "get" });

const obtenerTotalCompras = async (data = {}) =>
  client("/registros/total", { data, method: "get" });

const obtenerFondos = async (data = {}) =>
  client("/fondos", { data, method: "get" });

const descargarExcelCompras = async (data = {}) =>
  client("/compras/excel", { data, method: "get", responseType: "blob" });

const descargarExcelRegistros = async (data = {}) =>
  client("/registros/excel", { data, method: "get", responseType: "blob" });

const descargarExcelFondos = async (data = {}) =>
  client("/fondos/excel", { data, method: "get", responseType: "blob" });

export default {
  obtenerCompras,
  obtenerRegistros,
  obtenerTotalCompras,
  obtenerFondos,
  descargarExcelCompras,
  descargarExcelRegistros,
  descargarExcelFondos,
};
