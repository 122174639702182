<template>
  <section fluid>
    <p class="text-h6 font-museo-sans mb-0 secondary--text">
      Solicitudes de necesidades
    </p>
    <p class="text-body-2 font-museo-sans primary--text">
      Ejercicio {{ anioFiscal }}
    </p>
    <v-row class="my-4">
      <v-col cols="12" sm="3" md="3">
        <v-btn
          v-if="!haveRoles(['ROLE_AUDITORIA_GLOBAL'])"
          to="/agregar-solicitudes-necesidades"
          color="secondary white--text"
          block
          >Agregar solicitud</v-btn
        >
      </v-col>
    </v-row>
    <div>
      <v-row class="mb-4">
        <v-col cols="3">
          <v-text-field
            v-model="searchByNumber"
            append-icon="mdi-magnify"
            label="Buscar por correlativo"
            single-line
            outlined
            hide-details
            @keyup="filterByNumber()"
            @click:clear="(searchByNumber = ''), getSolicitudes()"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="searchByObs"
            :items="obsList"
            item-text="nombre"
            item-value="id"
            label="Buscar por OBS"
            outlined
            hide-details
            @change="getSolicitudes()"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" v-if="haveRoles(['ROLE_AUDITORIA_GLOBAL'])">
          <v-autocomplete
            label="Institución"
            :items="instituciones"
            item-value="id"
            item-text="nombre_completo"
            outlined
            hide-details
            clearable
            v-model="filters.id_institucion"
          />
        </v-col>
        <v-col cols="3" v-if="haveRoles(['ROLE_AUDITORIA_GLOBAL'])">
          <v-btn
            dark
            color="secondary"
            class="flex-shrink-1 flex-grow-1"
            style="width: 100%;"
            @click="(page = 1), getSolicitudes()"
            x-large
          >
            Busqueda por institución
          </v-btn>
        </v-col>
      </v-row>

      <p class="text-body-2 font-museo-sans primary--text">
        Listado de solicitudes
      </p>
      <data-table-component
        v-models:select="perPage"
        v-models:pagina="page"
        :headers="headers"
        :items="items"
        @paginar="paginate"
        :total_registros="totalRows"
      >
        <template v-slot:[`item.estado_solicitud`]="{ item }">
          <v-chip
            label
            :dark="item.estado_solicitud.includes('Descartado')"
            :color="item.color"
          >
            {{ item.estado_solicitud || "" }}
          </v-chip>
        </template>
        <template v-slot:[`item.monto_asignado`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.fondos_ajuste ?? item.monto_asignado)
          }}
        </template>
        <template v-slot:[`item.codigo_proceso`]="{ item }">
          {{ item.codigo_proceso ?? '-' }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip v-if="!haveRoles(['ROLE_AUDITORIA_GLOBAL'])" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="[1].includes(item.id_estado_solicitud)"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="
                  $router.push({
                    name: 'agregar-solicitudes-necesidades',
                    params: { idSolicitudNecesidad: item.id },
                  })
                "
              >
                <v-icon color="secondary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span> Editar </span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="[2,4,6,7].includes(item.id_estado_solicitud)"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="
                  $router.push({
                    name: 'detalle-solicitud-necesidad',
                    params: { id_solicitud: item.id },
                  })
                "
              >
                <v-icon color="secondary">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span> Ver</span>
          </v-tooltip>
          
          <v-tooltip right left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.id_usuario === userInfo.user.id && item.id_estado_solicitud === 1"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="
                  (deleteDialog = true), (solicitudSeleccionada = item.id)
                "
              >
                <v-icon color="secondary">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span> Eliminar </span>
          </v-tooltip>
        </template>
      </data-table-component>
    </div>
    <dialogoConfirmacion
      :show="deleteDialog"
      title="¿Desea eliminar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="deleteDialog = false"
      @confirm="deleteSolicitud"
    />
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import dialogoConfirmacion from "../../components/ConfirmationDialogComponent.vue";

import { mapState } from "vuex";
import { capitalize } from "lodash";

export default {
  name: "SolicitudesNecesidadesView",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },
  data: () => ({
    headers: [
      {
        text: "Correlativo",
        value: "correlativo",
        width: "15%",
      },
      {
        text: "OBS",
        value: "obs",
        width: "15%",
      },
      {
        text: "Información unidad",
        value: "informacion_adicional",
      },
      {
        text: "Monto($)",
        value: "monto_asignado",
        align: "right",
      },
      {
        text: "No. Proceso",
        value: "codigo_proceso",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado_solicitud",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    deleteDialog: false,
    solicitudSeleccionada: null,
    searchByNumber: null,
    searchByObs: null,
    obsList: [],
    timer: null,
    ctlInstituciones: [],
    filters: {
      id_institucion: null,
    },
  }),
  computed: {
    ...mapState(["userInfo", "anioFiscal", "selectedUnidad"]),
    instituciones() {
      return this.ctlInstituciones.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }))
    },
  },
  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getSolicitudes();
    },
    async filterByNumber() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.getSolicitudes();
      }, 500);
    },
    async getSolicitudes() {

      const { status, data, headers } =
        await this.services.PacServices.getSolicitudesPAC({
          page: this.page,
          per_page: this.perPage,
          correlativo: this.searchByNumber,
          id_obs: this.searchByObs,
          id_institucion: this.filters.id_institucion,
        })

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);

      }
    },
    async deleteSolicitud() {
      const { status } = await this.services.PacServices.deleteSolicitudesPAC(
        this.solicitudSeleccionada
      );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Solicitud eliminada con éxito`,
          type: "success",
        });

        this.solicitudSeleccionada = null;
        this.deleteDialog = false;
        this.getSolicitudes();
      }
    },
    async getOBSSegmentos() {
      const { status, data } = await this.services.PacServices.getOBSSegmentos({
        pagination: false,
      });

      if (status == 200) {
        this.obsList = data.map((col) => ({
          id: col.id,
          nombre: `${col.codigo} - ${capitalize(col.nombre)}`,
        }));
      }
    },
    async fetchInstituciones() {
      const response =
          await this.services.Instituciones.getCatalogoInstituciones({
            pagination: false,
          });
      if (response) {
        this.ctlInstituciones = response.data;
      }
    },
  },
  async created() {
    await this.fetchInstituciones();
    await this.getSolicitudes();
    await this.getOBSSegmentos();

  },
};
</script>
