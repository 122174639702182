<template>
  <v-card class="bgMinsal" elevation="0">
    <v-row>
      <v-col
        cols="12"
        sm="2"
        class="d-flex justify-sm-start justify-center mb-4 mb-md-6"
      >
        <v-btn
          class="button-extra text-capitalize ma-2"
          color="secondary"
          dark
          block
          @click="$router.push({ name: 'crear-usuario' })"
          v-if="showBtnCmp"
        >
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row class="d-flex">
      <v-col cols="12" md="2" class="d-flex align-center"
        v-if="ShowBotonReporte"
      >
        <v-btn
            v-if="reporte"
            dark
            block
            color="secondary"
            @click="(page = 1), obtenerReporte()"
          >Reporte
        </v-btn>
      </v-col>

      <v-col cols="12" md="2">
        <v-autocomplete
          label="Estado"
          :items="item_estado"
          item-text="text"
          item-value="value"
          v-model="optionFilter.is_suspended"
          outlined
          clearable
          hide-details
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          label="Rol o Perfil"
          :items="listaRoles"
          item-text="nombre"
          item-value="id"
          v-model="optionFilter.id_rol"
          outlined
          clearable
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
        v-if="
          haveRoles([
            'ROLE_TITULAR_ESTABLECIMIENTO',
            'ROLE_DIRECCION_COMPRAS',
            'ROLE_TECNICO_DIRECCION_COMPRAS',
            'ROLE_CALLCENTER',
            'ROLE_TACOP'
          ])
        "
      >
        <v-autocomplete
          label="Institución"
          :items="listaInstituciones"
          item-text="nombre_completo"
          item-value="id"
          v-model="optionFilter.id_institucion"
          outlined
          clearable
          hide-details
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          outlined
          label="Nombre/Correo electrónico"
          hide-details
          clearable
          @input="debouncedFilterByName"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          outlined
          label="Documento"
          @input="debouncedFilterByDocument"
          hide-details
          clearable
        />
      </v-col>
    </v-row>
    <v-card-subtitle class="px-0">
      <span> Listado de usuarios</span>
    </v-card-subtitle>
    <v-row>
      <v-col>
        <v-data-table
          :items="dataUsuarios"
          :items-per-page="pagination.per_page"
          :headers="headerCmpt"
          :loading="loadingUsers"
          hide-default-footer
          loading-text="Cargando usuarios . . ."
          mobile-breakpoint="625"
          no-data-text="Sin usuarios"
        >

        <template v-slot:[`item.perfiles`]="{ item }">
          <span class="primary--text font-weight-bold">
            {{ obtenerNombrePerfil(item) }}
          </span>
        </template>
          <template v-slot:item.institucion="{ item }">
            <p class="mb-0">
              <template v-if="item.codigo_institucion">{{ item.codigo_institucion }} - </template>
              {{ item.institucion }}
            </p>
          </template>
          <template v-slot:[`item.unidades`]="{ item }">
            <span>{{ item.unidades }}</span> - <span class="primary--text font-weight-bold"> {{ item.institucion }}</span>
          </template>
          <template v-slot:[`item.fecha_creacion`]="{ item }">
            {{ item.fecha_creacion | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip label :color="colorEstado(item)">
              {{ textoEstado(item) }}
            </v-chip>
          </template>
          <!-- acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
          <app-menu-acciones
            :menu="menu(item)"
            titulo="Acciones"
            :elemento="item"
          ></app-menu-acciones>
          </template>

        </v-data-table>
        <AppPaginationComponent
          :totalElements="totalPaginas"
          @cantidadPage="paginacion"
          @changePage="paginacion"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="modalReporte" max-width="600">
      <v-card class="pa-4">
        <v-card-text>
          <p class="text-center text-h6 secondary--text my-4">
            El reporte iniciará su ejecución en:
          </p>
          <app-countdown 
            v-if="contador"
            color="info--text"
            :date-now="contador"
            :nextDay="finalizacionTimer"
            @status="modalReporte = false"
          />
          <p class="text-center text-h6 secondary--text my-4">
            Se le notificará al correo cuando el reporte haya finalizado. Si
            después de 15 minutos no le ha llegado el correo vuelva a ejecutar
            el proceso.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dark color="secondary" @click="modalReporte = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-user-detail
      :showDialogDetailValue.sync="showDialogDetail"
      :userDetail="userSelected"
      @closeDialogDetail="cleanDataDialog"
    />

    <ConfirmationDialogComponent
      :show="showDeleteUserDialog"
      btnConfirmar="Continuar"
      :title="`¿Desea eliminar de manera definitiva el usuario: ${userSelectedToAction?.empleado} ?`"
      message="Esta acción solo se puede realizar si el usuario no ha realizado ningún procedimiento dentro del sistema y será totalmente irreversible"
      @close="showDeleteUserDialog = false"
      @confirm="deleteUserForever()"
    />

    <ConfirmationDialogComponent
      :show="modalResetPassword"
      btnConfirmar="Restablecer"
      :title="`¿Desea restablecer la contraseña del usuario: ${userSelectedToAction?.empleado} ?`"
      message="Esta acción restablecerá la contraseña a una aleatoria y enviará un correo con la misma al usuario"
      @close="modalResetPassword = false"
      @confirm="restablecerPassword()"
    />
   
    <ConfirmationDialogComponent
      :show="modalObservacion"
      btnConfirmar="Remover inactividad"
      :title="`¿Desea remover la inactividad de: ${userSelectedToAction?.empleado} ?`"
      @close="modalObservacion = false"
      @confirm="removerObservacionUsuario()"
    />

    <ConfirmationDialogComponent
      :show="modalEstadoUsuario"
      btnConfirmar="Aceptar"
      :title="`¿Está seguro de ${userSelectedToAction?.estado === 'Habilitado' ? 'deshabilitar' : 'habilitar'} al usuario?`"
      :message="`Esta acción ${userSelectedToAction?.estado === 'Habilitado' ? 'deshabilitará' : 'habilitará'} al usuario`"
      @close="modalEstadoUsuario = false"
      @confirm="cambiarEstado()"
    />

    <ModalSuspenderOficial
        :is-open="modalSuspensionOdcAbierto"
        @on-visibility-change="cerrarModalSuspensionOdc"
        @on-save="confirmarSuspensionOdc"
    />

    <ConfirmationDialogComponent
      :show="modalConfirmarSuspension"
      :show-loading="suspensionOdc.isLoading"
      btnConfirmar="Confirmar"
      :title="`¿Desea crear una solicitud para ${tipoSolicitudOdc} al usuario?`"
      :message="`Esta acción creará una solicitud para ${tipoSolicitudOdc} al oficial de cumplimiento`"
      @close="modalConfirmarSuspension = false"
      @confirm="crearSolicitudSuspension"
    />

    <editarUsuarioComponent :creado="creado" :refreshData="loadUsers" />

    <PdfModal
      :is-open="modalVerJustificacionSuspensionAbierta"
      :filename="`justificacion-suspension(${userSelectedToAction?.empleado})`"
      :source-loadable="visualizacionJustificacion"
      @on-visibility-change="cerrarVisualizacionJustificacionSuspension"
    />

    <ModalCambioPerfil 
      :is-open.sync="modalCambioPerfilAbierta" 
      :solo-perfiles-cumplimiento="soloPerfilesCumplimiento"
      :id-usuario="userSelectedToAction?.id"
      @on-save="manejarSolicitudCambioPerfilExitosa"
    />

    <ConfirmationModalComponent
      :is-open="modalDescartarSolicitudCambioPerfilAbierta"
      description="¿Desea descartar la solicitud de cambio de perfil?"
      :is-loading="eliminadoSolicitudCambioPerfil.isLoading"
      @cancel="modalDescartarSolicitudCambioPerfilAbierta = false"
      @confirm="descartarSolicitudCambioPerfil"
    />

     <ModalconfiguracionUsuario
      :show="modalConfiguracion"
      :id_usuario="id_usuario"
      @cerrar="modalConfiguracion = false, id_usuario = null"
    />
  </v-card>
</template>

<script>
import ModalSuspenderOficial from '@/views/PanelAdministracionODC/components/verificacion/ModalSuspenderOficial.vue';
import ModalconfiguracionUsuario from "@/views/Rupes/components/ModalconfiguracionUsuario.vue";
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import editarUsuarioComponent from "./editarUsuarioComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import ModalCambioPerfil from './ModalCambioPerfil.vue';
import DialogUserDetail from "./DialogUserDetail.vue";
import { convertToFormData } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { debounce } from 'lodash';
import moment from "moment";

export default {
  components: {
    DialogUserDetail,
    ConfirmationDialogComponent,
    editarUsuarioComponent,
    ModalSuspenderOficial,
    PdfModal,
    ModalCambioPerfil,
    ConfirmationModalComponent,
    ModalconfiguracionUsuario
},
  props: {
    creado: {
      type: Boolean,
      default: false,
    },
    reporte:{
      type: Boolean,
      default: false,
    }
  },
  name: "TableUsuariosComponent",
  data: () => ({
    id_usuario: null,
    modalConfiguracion: false,
    modalObservacion: false,
    listaRoles: [],
    showDialogDetail: false,
    userSelected: null,
    headers: [],
    showDeleteUserDialog: false,
    userSelectedToAction: null,
    modalResetPassword: false,
    modalEstadoUsuario: false,
    timer: null,
    modalReporte: false,
    contador: null,
    finalizacionTimer: null,
    // Filtros
    filtroCreadoPor: 0,
    item_estado: [
      {
        text: "Habilitado",
        value: false,
      },
      {
        text: "Deshabilitado",
        value: true,
      },
    ],
    creadoListOptions: [
      { id: 0, value: 'Todos' },
      { id: 1, value: 'Creados por mi' },
    ],
    optionFilter: {
      id_rol: null, // Filtro de rol/perfil
      is_suspended: null, // Filtro de habilitado/deshabilitado
      // creado: false, // Filtro de 'creado por'
      nombre: null, // Filtro de nombre/correo
      documentoBuscar: null, // Filtro de documento a buscar
    },
    pagination: {
      per_page: 10,
      page: 1,
    },
    // Suspender ODC
    modalSuspensionOdcAbierto: false,
    modalConfirmarSuspension: false,
    justificacionSuspension: null,
    suspensionOdc: createLoadable(null),
    // Habilitar ODC
    modalConfirmarHabilitacionOdc: false,
    habilitacionOdc: createLoadable(null),
    tipoSolicitudOdc: 'suspender',
    // Ver motivo de suspensión
    modalVerJustificacionSuspensionAbierta: false,
    visualizacionJustificacion: createLoadable(null),
    // Cambio de perfil
    modalCambioPerfilAbierta: false,
    soloPerfilesCumplimiento: false,
    eliminadoSolicitudCambioPerfil: createLoadable(null),
    modalDescartarSolicitudCambioPerfilAbierta: false,
  }),
  computed: {
    ...mapState("usuariosModule", [
      "dataUsuarios",
      "totalPaginas",
      "loadingUsers",
      "instituciones",
    ]),

    listaInstituciones() {
      return this.instituciones.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }));
    },

    ShowBotonReporte(){
      return (this.haveRole("ROLE_USUARIO_REPORTE_VIEW"))
    },

    showBtnCmp() {
      const validRol = this.haveRoles([
        "ROLE_TITULAR_ESTABLECIMIENTO",
        "ROLE_DIRECCION_COMPRAS",
        "ROLE_TECNICO_DIRECCION_COMPRAS",
        "ROLE_USER_ADMIN",
      ]);
      return validRol && this.$props.creado;
    },

    headerCmpt() {
      let headers = [
        {
          text: "N°",
          align: "center",
          value: "correlativo",
        },
      ];

      if (
        this.haveRole("ROLE_DIRECCION_COMPRAS") ||
        this.haveRole("ROLE_TITULAR_ESTABLECIMIENTO")
        || this.haveRole("ROLE_TECNICO_DIRECCION_COMPRAS") || 
        this.haveRole("ROLE_FRONT_LECTURA_CALLCENTER")
      ) {
        headers = [
          ...headers,
          {
            text: "Institución",
            align:
              this.haveRole("ROLE_DIRECCION_COMPRAS") ||
              this.haveRole("ROLE_TITULAR_ESTABLECIMIENTO") || this.haveRole("ROLE_TECNICO_DIRECCION_COMPRAS") || this.haveRole("ROLE_FRONT_LECTURA_CALLCENTER")
                ? "start"
                : " d-none",
            value: "institucion",
          },
        ];
      }

      headers = [
        ...headers,
        {
          text: "Unidad solicitante",
          align: "left",
          value: "unidades",
        },
        {
          text: "Empleado",
          align: "left",
          value: "empleado",
        },
        {
          text: "Rol o perfil solicitado",
          align: "left",
          value: "perfiles",
        },
        {
          text: "Fecha de creación",
          align: "center",
          value: "fecha_creacion",
        },
        {
          text: "Estado",
          align: "center",
          value: "estado",
        },
        {
          text: "Acciones",
          align: "start",
          value: "acciones",
        },
      ];

      return headers;
    },
  },

  methods: {
    menu(usuario) {
      const acciones = [
        {
          nombre: "Editar usuario",
          icono: "mdi-pencil",
          callback2: this.editarUsuario,
          disabled: !(
                      this.haveRoles([
                        'ROLE_DIRECCION_COMPRAS',
                        'ROLE_TITULAR_ESTABLECIMIENTO',
                        'ROLE_USERS_EDIT',
                      ]) && usuario.id !== 1
                    )
        },
        {
          nombre: "Restablecer contraseña",
          icono: "mdi-key",
          callback1: ()=>{(this.userSelectedToAction = usuario), (this.modalResetPassword = true)},
          disabled: !(
                      this.haveRoles([
                        'ROLE_DIRECCION_COMPRAS',
                        'ROLE_TITULAR_ESTABLECIMIENTO',
                      ]) && usuario.id !== 1
                    )
        },
        {
          nombre: "Ver",
          icono: "mdi-eye",
          callback2: this.obtainIdUsuario,
          disabled: !(
                      this.haveRole('ROLE_DIRECCION_COMPRAS') &&
                      ![1, 80].includes(usuario.id) || this.haveRole('ROLE_TECNICO_DIRECCION_COMPRAS') 
                      ||
                      this.haveRole('ROLE_FRONT_LECTURA_CALLCENTER')
                      ||
                      this.haveRole('ROLE_UACI')
                      ||
                      this.haveRole('ROLE_JEFE_UNIDAD')
                      || this.haveRole('ROLE_TACOP')
                    )
        },
        {
          nombre: "Eliminar",
          icono: "mdi-delete",
          callback1:()=>{(this.showDeleteUserDialog = true), (this.userSelectedToAction = usuario)},
          disabled: !(
                      this.haveRole('ROLE_DIRECCION_COMPRAS') &&
                      ![1, 80].includes(usuario.id)
                    )
        },
        {
          nombre: "Habilitar usuario",
          icono: "mdi-lock-open-outline",
          callback1: this.habilitarUsuario,
          disabled: (
                      !this.haveRoles([
                        'ROLE_TITULAR_ESTABLECIMIENTO',
                        'ROLE_DIRECCION_COMPRAS',
                        // 'ROLE_TECNICO_DIRECCION_COMPRAS',
                      ])
                      || usuario.id === 1
                      || (usuario.perfiles.includes('OFICIAL DE CUMPLIMIENTO') ? !usuario.es_oficial_de_cumplimiento_suspendido : usuario.estado === 'Habilitado')
                      || usuario.estado === 'Observado por inactividad'
                    )
        },
        {
          nombre: "Deshabilitar usuario",
          icono: "mdi-lock-outline",
          callback1: this.deshabilitarUsuario,
          disabled: (
                      !this.haveRoles([
                        'ROLE_TITULAR_ESTABLECIMIENTO',
                        'ROLE_DIRECCION_COMPRAS',
                        // 'ROLE_TECNICO_DIRECCION_COMPRAS',
                      ])
                      || usuario.id === 1
                      || (usuario.perfiles.includes('OFICIAL DE CUMPLIMIENTO') ? usuario.es_oficial_de_cumplimiento_suspendido : usuario.estado === 'Deshabilitado')
                      || usuario.estado === 'Observado por inactividad'
                    )
        },
        {
          nombre: "Verificar Configuración del usuario",
          icono: "mdi-account-card",
          callback2: (index) => {
            this.modalConfiguracion = true;
            this.id_usuario = usuario.id
          },
          disabled: !this.haveRoles(["ROLE_ADMINISTRAR_ACCESOS_CORREO_USUARIO", "ROLE_SUPER_ADMIN"]),
        },
      ];

      const esUsuarioDeCumplimiento = usuario.usuario_perfiles.some((perfil) => [20, 34].includes(perfil.id));
      const solicitudCambioPerfilPendiente = Boolean(usuario.cambio_perfil && !usuario.cambio_perfil.aprobado_por && !usuario.cambio_perfil.rechazado_por);

      if (usuario.suspension_odc && esUsuarioDeCumplimiento) {
        acciones.push({
          nombre: `Ver motivo de ${ usuario.suspension_odc.id_tipo_solicitud === 1 ? 'suspensión' : 'habilitación' }`,
          icono: "mdi-file-eye-outline",
          callback1: this.visualizarJustificacionSuspension,
          disabled: (
              !this.haveRoles([
                'ROLE_TITULAR_ESTABLECIMIENTO',
                'ROLE_DIRECCION_COMPRAS',
                'ROLE_TECNICO_DIRECCION_COMPRAS',
              ]) &&
              usuario.suspension_odc &&
              (usuario.suspension_odc.aprobado_por || !usuario.suspension_odc.rechazado_por ) && usuario.suspension_odc.adjunto
            ),
        })
      }

      if (esUsuarioDeCumplimiento) {
        acciones.push({
          nombre: 'Cambiar de perfil',
          icono: 'mdi-account-switch',
          callback1: () => this.abrirModalCambioPerfil(usuario),
          disabled: !this.haveRoles(['ROLE_TITULAR_ESTABLECIMIENTO', 'ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS']) || solicitudCambioPerfilPendiente,
        });
      } else {
        acciones.push({
          nombre: 'Convertir en usuario de cumplimiento',
          icono: 'mdi-account-switch',
          callback1: () => this.abrirModalConvertirEnUsuarioCumplimiento(usuario),
          disabled: !this.haveRoles(['ROLE_TITULAR_ESTABLECIMIENTO', 'ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS']) || solicitudCambioPerfilPendiente,
        });
      }

      if (solicitudCambioPerfilPendiente) {
        acciones.push({
          nombre: 'Descartar solicitud de cambio de perfil',
          icono: 'mdi-note-remove',
          callback1: () => this.confirmarEliminadoSolicitudCambioPerfil(usuario),
          disabled: !this.haveRoles(['ROLE_TITULAR_ESTABLECIMIENTO', 'ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS']),
        });
      }

      acciones.push({
        nombre: "Remover inactividad del usuario",
        icono: "mdi-check",
        callback1: ()=>{(this.userSelectedToAction = usuario), (this.modalObservacion = true)},
        disabled: !(
          usuario.estado === 'Inactividad' &&
          (
            this.haveRoles(['ROLE_TECNICO_DIRECCION_COMPRAS_REMOVER_OBSERVACION', 'ROLE_TITULAR_ESTABLECIMIENTO'])
          )
        )
      });


      return acciones;
    },
    ...mapActions("usuariosModule", [
      "consultarUsuariosCreados",
      "getListaRoles",
    ]),
    ...mapMutations("usuariosModule", [
      "setShowEditarUsuario",
      "setDataUsuario",
      "setProfesiones",
      "setPerfiles",
      "setInstituciones",
    ]),
    handleFilterByName(value) {
      this.optionFilter.nombre = value;
    },
    handleFilterByDocument(value) {
      this.optionFilter.documentoBuscar = value;
    },
    async obtainIdUsuario(id) {
      if (id) {
        try {
          const response = await this.services.Usuarios.getUsuarios({
            id_usuario: id,
            buscar: true,
          });
          if (response) {
            this.userSelected = { ...response.data[0] };
            this.showDialogDetail = true;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    cleanDataDialog() {
      this.showDialogDetail = false;
      this.userSelected = null;
    },
    async paginacion(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagination = {
        page: pagina,
        per_page: cantidad_por_pagina,
      }
    },
    colorEstado(item) {
      const colores = {
        habilitado: '#C1EDD8',
        deshabilitado: '#FBD9D8',
      };

      const esOficialDeCumplimiento = item.perfiles.includes('OFICIAL DE CUMPLIMIENTO');

      if (!esOficialDeCumplimiento) return colores[item.estado?.toLowerCase()];

      return item.es_oficial_de_cumplimiento_suspendido ? colores.deshabilitado : colores.habilitado;
    },
    textoEstado(item) {
      const esOficialDeCumplimiento = item.perfiles.includes('OFICIAL DE CUMPLIMIENTO');

      if (!esOficialDeCumplimiento) return item.estado;

      return item.es_oficial_de_cumplimiento_suspendido ? 'Deshabilitado' : 'Habilitado';
    },
    async deleteUserForever() {

      const response = await this.services.Usuarios.deleteUserForever(
        this.userSelectedToAction.id
      ).catch(() => {
        this.temporalAlert({
          show: true,
          type: "warning",
          message:
            "El usuario no puede ser eliminado ya que ha realizado otros registros dentro del sistema",
        });

      });
      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Usuario eliminado con éxito",
        });
        await this.loadUsers();
        this.showDeleteUserDialog = false;
        this.userSelectedToAction = null;
      }

    },
    async initCreated() {
      this.fetchRolesPerfiles();
      this.FetchCtlInstituciones();
      this.loadUsers();
    },
    async removerObservacionUsuario(){
        await this.services.Usuarios.removerObservacionUsuario(this.userSelectedToAction.id)
        this.modalObservacion = false;
        await this.loadUsers();
    },
    async getProfesiones() {
      this.loading = true;
      const { status, data } =
        await this.services.Usuarios.getProfesiones().catch(() => {
          this.$route.replace({ name: "login" });
        });
      if (status == 200) this.setProfesiones(data);
      this.loading = false;
    },
    async fetchRolesPerfiles() {

      const response =
        await this.services.Usuarios.getPerfilRolDependencia()
      if (response) {
        this.listaRoles = response?.data;
        this.setPerfiles(response?.data);
      }

    },
    async editarUsuario(idUsuario) {

      const response = await this.services.Usuarios.getUsuarios({
        id_usuario: idUsuario,
        buscar: true,
      }).catch(() => {

      });

      if (response.status === 200 && response?.data?.length > 0) {
        this.getProfesiones();
        this.fetchRolesPerfiles();
        this.setShowEditarUsuario(true);
        this.setDataUsuario(response.data[0]);
      }


    },
    async restablecerPassword() {

      const usuario = this.userSelectedToAction;
      const responseUser = await this.services.Usuarios.getUsuarios({
        id_usuario: usuario.id,
        buscar: true,
      }).catch(() => {

      });

      if (responseUser.status === 200 && responseUser?.data?.length > 0) {
        const user = responseUser?.data[0];
        const response =
          await this.services.SolicitudesUsuarios.restablecerPasswordUsuario({
            id_usuario: usuario.id,
            numero_documento: user.DocumentoPersonas[0].numero_documento,
            id_tipo_documento: user.DocumentoPersonas[0].TipoDocumento.id,
          });

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message:
              "La contraseña del usuario se ha restablecido a través de un correo",
          });
          this.userSelectedToAction = null;
          this.modalResetPassword = false;
        }
      }

    },
    async cambiarEstado() {
      // Flujo para 

      // Flujo normal
      const response = await this.services.Usuarios.cambiarEstadoUsuario(
        this.userSelectedToAction?.id
      ).catch(() => {

      });
      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data.message,
        });
      }
      this.modalEstadoUsuario = false;
      this.userSelectedToAction = null;
      await this.loadUsers();

    },
    async FetchCtlInstituciones() {
      const response = await this.services.Usuarios.getInstituciones();
      if (response) {
        this.setInstituciones(response.data);
      }
    },
    loadUsers() {
      return this.consultarUsuariosCreados({ 
        ...this.pagination, 
        ...this.optionFilter,
        creado: this.$props.creado, 
      });
    },
    async obtenerReporte() {
      this.showLoader();
      const {status} = await this.services.Usuarios.generarReporteUsuario({
        ...this.optionFilter,
      });
      if (status == 200) {
        this.modalReporte = true;
      }
      this.hideLoader();
    },

    // Deshabilitar usuarios
    deshabilitarUsuario(item) {
      this.userSelectedToAction = item;

      if (!item.perfiles.includes('OFICIAL DE CUMPLIMIENTO')) {
        this.modalEstadoUsuario = true;
        return
      }
      
      // Flujo ODC
      if (item.suspension_odc && !item.suspension_odc.aprobado_por && !item.suspension_odc.rechazado_por) {
        this.pushAppMessage({ type: 'info', message: 'El oficial de cumplimiento ya tiene una solicitud pendiente de revisión.' });
        return;
      }

      this.tipoSolicitudOdc = 'suspender';
      this.modalSuspensionOdcAbierto = true;
    },
    habilitarUsuario(item) {
      this.userSelectedToAction = item;

      if (!item.perfiles.includes('OFICIAL DE CUMPLIMIENTO')) {
        this.modalEstadoUsuario = true;
        return;
      }

      // Flujo ODC
      if (item.suspension_odc && !item.suspension_odc.aprobado_por && !item.suspension_odc.rechazado_por) {
        this.pushAppMessage({ type: 'info', message: 'El oficial de cumplimiento ya tiene una solicitud pendiente de revisión.' });
        return;
      }

      if (item.suspension_odc && item.suspension_odc.id_tipo_solicitud === 1 && !item.suspension_odc.aprobado_por) {
        this.pushAppMessage({ type: 'info', message: 'El oficial de cumplimiento no se encuentra suspendido.' });
        return;
      }

      this.tipoSolicitudOdc = 'habilitar';
      this.modalSuspensionOdcAbierto = true;
      // this.modalConfirmarHabilitacionOdc = true;
    },
    cerrarModalSuspensionOdc(visible) {
      this.modalSuspensionOdcAbierto = visible;
    },
    confirmarSuspensionOdc(justificacion) {
      this.justificacionSuspension = justificacion;
      this.modalSuspensionOdcAbierto = false;
      this.modalConfirmarSuspension = true;
    },
    async crearSolicitudSuspension() {
      this.showLoader();
      const formData = convertToFormData(this.justificacionSuspension);

      const accion = this.tipoSolicitudOdc === 'suspender'
      ? this.services.VerificacionDocumentosOdc.suspenderOficialCumplimiento
      : this.services.VerificacionDocumentosOdc.habilitarOficialCumplimiento;
      
      toggleLoadable(this.suspensionOdc);
      const { data } = await accion(this.userSelectedToAction.id, formData);
      this.modalConfirmarSuspension = false;
      setLoadableResponse(this.suspensionOdc, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      
      this.hideLoader();
      if (isResponseSuccesful(data)) this.loadUsers();
    },
    async visualizarJustificacionSuspension(usuario) {
      this.userSelectedToAction = { ...usuario };
        this.modalVerJustificacionSuspensionAbierta = true;
        toggleLoadable(this.visualizacionJustificacion);
        const { data } = await this.services.VerificacionDocumentosOdc.visualizarJustificacionSuspension(usuario.id);
        setLoadableResponse(this.visualizacionJustificacion, data, { isFile: true });
    },
    cerrarVisualizacionJustificacionSuspension(visible) {
      this.modalVerJustificacionSuspensionAbierta = visible;
    },

    // Cambio de perfil
    abrirModalCambioPerfil(usuario) {
      this.userSelectedToAction = { ...usuario };
      this.modalCambioPerfilAbierta = true;
      this.soloPerfilesCumplimiento = false;
    },
    abrirModalConvertirEnUsuarioCumplimiento(usuario) {
      this.userSelectedToAction = { ...usuario };
      this.modalCambioPerfilAbierta = true;
      this.soloPerfilesCumplimiento = true;
    },
    manejarSolicitudCambioPerfilExitosa() {
      this.loadUsers();
    },
    confirmarEliminadoSolicitudCambioPerfil(usuario) {
      this.userSelectedToAction = { ...usuario };
      this.modalDescartarSolicitudCambioPerfilAbierta = true;
    },
    async descartarSolicitudCambioPerfil() {
      toggleLoadable(this.eliminadoSolicitudCambioPerfil);
      const { data } = await this.services.VerificacionDocumentosOdc.descartarSolicitudCambioPerfil(this.userSelectedToAction.id);
      this.modalDescartarSolicitudCambioPerfilAbierta = false;
      setLoadableResponse(this.eliminadoSolicitudCambioPerfil, data, { skipOnSuccess: true, showAlertOnSuccess: true });

      if (isResponseSuccesful(data)) this.loadUsers();
    },
    obtenerNombrePerfil(usuario) {
      const esUFI = usuario.usuario_perfiles.some(perfil => /ufi/i.test(perfil.nombre));
      const sufijo = esUFI 
        ? usuario.es_delegado ? '- (Administrador Subsistema SAFI)' : '' 
        : usuario.es_delegado ? '- (Delegado)' : '';
        
      return `${usuario.perfiles} ${sufijo}`;
    }
  },
  watch: {
    optionFilter: {
      handler() {
        this.loadUsers()
      },
      deep: true,
    },
    pagination: {
     handler() {
      this.loadUsers();
     },
     deep: true,
    },
    modalReporte(value){
      if (value) {
        this.contador = moment();
        this.finalizacionTimer = moment().add(5, 'seconds');
      }	else {	
        this.contador = null;
        this.finalizacionTimer = null;
      }
    },
  },
  created() {
    this.initCreated();
    this.debouncedFilterByName = debounce(this.handleFilterByName, 400);
    this.debouncedFilterByDocument = debounce(this.handleFilterByDocument, 400);
  },
};
</script>

<style lang="scss">
.table-align {
  .v-data-table__wrapper table thead.v-data-table-header tr th {
    text-align: center !important;
  }
}
</style>
